 
<template>
	<div>
		<div class="d-flex align-items-center">
			<b-avatar-group
				:size="`${likedByProfiles.length == 1 ? '' : '32'}`"
				class="mr-1"
			>
				<Avatar
					v-for="(item, index) in likedByProfiles"
					:key="index"
					classes="pull-up"
					:src="getMediaPath(item.Media)"
				/>
			</b-avatar-group>
			<h6
				v-if="likedByProfiles.length"
				class="font-weight-bolder view-all-text mb-0"
				v-b-modal="id"
				@click="onClick"
			>
				{{ onViewClickText }}
			</h6>
			<h6 v-else class="font-weight-bolder black-text">
				{{ noDataText }}
			</h6>
		</div>
	</div>
</template>

<script>
import { BAvatarGroup, BAvatar, VBModal, BModal } from 'bootstrap-vue'

import BasicModal from './BasicModal.vue'
import AllViewsUsers from './AllViewsUsers.vue'
import { getMediaPath } from '@/utils/commonUtils'
import UserInfo from './UserInfo.vue'
import Avatar from './Avatar.vue'

export default {
	name: 'GroupedAvatar',
	components: {
		BAvatarGroup,
		BAvatar,
		BModal,
		VBModal,
		BasicModal,
		AllViewsUsers,
		UserInfo,
		Avatar,
	},
	props: {
		onViewClickText: {
			default: 'View All',
			type: String,
		},
		noDataText: {
			default: 'No Likes',
			type: String,
		},
		id: {
			type: String,
			default: '',
		},
		likedByProfiles: {
			type: Array,
			default: [
				require('@/assets/images/portrait/small/avatar-s-5.jpg'),
				require('@/assets/images/portrait/small/avatar-s-5.jpg'),
				require('@/assets/images/portrait/small/avatar-s-7.jpg'),
			],
		},
		onClick: {
			type: Function,
		},
	},
	data() {
		return {
			getMediaPath,
		}
	},
}
</script>

<style scoped>
.view-all-text {
	color: black;
	text-decoration: underline;
	cursor: pointer;
}
.black-text {
	color: black;
	margin-left: none !important;
}
</style>

 