<template>
	<div class="table-container">
		<b-card body-class="p-0">
			<search-header
				searchInputClasses="w-25"
				:searchText="searchText"
				:onSearchChange="handleSearchChange"
				:pageLimitValue="pageLimit"
				:onPageLimitChange="handlePageLimit"
				:showStatusFilter="false"
			>
				<template #right-header-content>
					<Button
						buttonText="+ Create New Template"
						buttonVariant="primary"
						size="md"
						classes="btn-outline-grey ml-2"
						:clickHandler="() => $bvModal.show('email-name-modal')"
					/>
				</template>
			</search-header>
			<data-table
				:perPageLimit="pageLimit"
				entriesName="Email templates"
				:perPage="data.length"
				:isLoading="isFetching"
				:showPagination="true"
				:config="config"
				:data="data"
				:selectedPageValue="page"
				:onHandlePageChange="handlePageClick"
				:onSortClick="handleSortChange"
				:totalRecords="total"
			>
				<!--#ID-->
				<template #cell(Id)="data">
					<span class="font-weight-bolder"> {{ data.value }} </span>
				</template>
				<!--#TEMPLATE NAME-->
				<template #cell(Name)="data">
					<span class="text-nowrap">
						<span class="font-weight-bolder">
							{{ data.value || '-' }}
						</span>
					</span>
				</template>

				<!--#DATE CREATED-->
				<template #cell(CreatedAt)="data">
					<span class="text-nowrap">
						{{
							convertDateTime({
								date: data.value,
								customFormat: dateTimeFormat.appDateFormat,
								dateOnly: true,
							})
						}}
					</span>
				</template>

				<!--#STATUS-->
				<template #cell(TimesUsed)="data">
					<b-badge
						pill
						:class="`font-weight-normal status-common ${
							data.item.TimesUsed == 1
								? 'sent-one-time'
								: data.item.TimesUsed > 1
								? 'sent-many-times'
								: data.item.TimesUsed <= 1
								? 'not-sent-yet'
								: null
						}`"
					>
						{{
							data.item.TimesUsed == 1
								? 'Sent 1 time'
								: data.item.TimesUsed >= 1
								? `Sent ${data.item.TimesUsed} times`
								: data.item.TimesUsed <= 1
								? 'Not sent yet / Draft'
								: ''
						}}
					</b-badge>
				</template>

				<!-- ACTIONS -->
				<template #cell(Actions)="data">
					<div class="text-nowrap">
						<!-- View -->
						<feather-icon
							:id="`email-template-${data.item.Id}-view`"
							icon="EyeIcon"
							size="16"
							class="view-icon cursor-pointer"
							@click="handleViewEmailTemplateDetails(data.item.Id)"
						/>
						<b-tooltip
							title="View"
							:target="`email-template-${data.item.Id}-view`"
						/>
						<!-- Edit -->
						<feather-icon
							:id="`email-template-${data.item.Id}-edit`"
							icon="EditIcon"
							size="16"
							class="mx-1 edit-icon cursor-pointer"
							@click="handleEditEmailTemplate(data.item.Id)"
						/>
						<b-tooltip
							title="Edit"
							:target="`email-template-${data.item.Id}-edit`"
						/>
						<!-- Delete -->
						<feather-icon
							v-b-modal="`delete-template-modal`"
							:id="`email-template-${data.item.Id}-delete`"
							icon="Trash2Icon"
							size="16"
							class="trash-icon cursor-pointer"
							@click="handleDeleteEmailTemplate(data.item.Id)"
						/>
						<b-tooltip
							title="Delete"
							class="cursor-pointer"
							:target="`email-template-${data.item.Id}-delete`"
						/>
					</div>
				</template>
			</data-table>

			<EmailNameModal
				:templateName="newEmailTemplateName"
				:handleChange="handleEmailTemplateNameChange"
				:onSubmit="handleSaveNewEmailTemplateName"
			/>
			<DeleteModal
				:id="`delete-template-modal`"
				:onYesPress="handleDelete"
				:isLoading="isDeleting"
				:isSuccess="deleteSuccess"
				:onOkayPress="handleOkayPress"
				messageText="Are you sure you want to delete this email template ?"
				successText="Email template deleted successfully !"
			/>
		</b-card>
	</div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import DeleteModal from '../../components/ConfirmModal.vue'
import DataTable from '../../components/data-table/DataTable.vue'
import SearchHeader from '../../components/SearchHeader.vue'
import Button from '../../components/Button.vue'
import EmailNameModal from './EmailNameModal.vue'
import showToast from '../../hooks/useToast'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import { useLocalPagination } from '../../hooks/useLocalPagination'
import { APP_ROUTES, getRoute } from '../../helpers/routeHelpers'
import { SortOrder, EmailTemplateSentStatus } from '../../constants'
import { getEmailTemplates, deleteEmailTemplate } from '../../api/api.service'

let config = [
	{
		label: 'ID',
		key: 'Id',
	},
	{
		label: 'TEMPLATE NAME',
		key: 'Name',
	},

	{
		label: 'DATE CREATED',
		key: 'CreatedAt',
		isSortable: true,
	},

	{
		label: 'STATUS',
		key: 'TimesUsed',
	},
	{
		label: 'ACTIONS',
		key: 'Actions',
	},
]

export default {
	name: 'EmailTemplateListing',
	components: {
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		DataTable,
		SearchHeader,
		DeleteModal,
		Button,
		EmailNameModal,
	},
	setup(_, context) {
		const newEmailTemplateName = ref('')
		const selectedEmailTemplateId = ref('')
		const isCreateNewTemplate = ref(false)
		const isEditEmailTemplate = ref(false)
		const isDeleting = ref(false)
		const deleteSuccess = ref(false)

		const {
			data,
			isFetching,
			total,
			page,
			searchText,
			pageLimit,
			sortOrder,
			sortColumn,
			handleSortChange,
			handleSearchChange,
			handlePageClick,
			handlePageLimit,
			request,
		} = useLocalPagination(
			getEmailTemplates,
			() => ({
				Direction: SortOrder.DESC,
				Column: 'CreatedAt',
			}),
			[],
			'Q',
			'EmailTemplates',
			'TotalEmailTemplates'
		)

		function handleViewEmailTemplateDetails(id) {
			this.$router.push(
				getRoute(APP_ROUTES.VIEW_EMAIL_TEMPLATE_DETAILS, { templateId: id })
			)
		}

		function handleEditEmailTemplate(id) {
			let getTemplateName = data?.value?.find((item) => item.Id == id)?.Name
			let strWithoutSpaces = getTemplateName.replaceAll(' ', '-')

			this.$router.push(
				getRoute(APP_ROUTES.EDIT_EMAIL_TEMPLATE, {
					templateId: id,
					templateName: strWithoutSpaces,
				})
			)
		}

		function handleOkayPress() {
			deleteSuccess.value = false
			selectedEmailTemplateId.value = null
		}

		function handleDeleteEmailTemplate(id) {
			selectedEmailTemplateId.value = id
		}

		function handleEmailTemplateNameChange(evt) {
			newEmailTemplateName.value = evt
		}

		const handleDelete = async () => {
			try {
				isDeleting.value = true
				let res = await deleteEmailTemplate(selectedEmailTemplateId.value)
				data.value = data.value.filter(
					(item) => item.Id !== selectedEmailTemplateId.value
				)

				total.value = total.value - 1

				if (total.value && !Boolean(data.value.length)) {
					page.value = page.value - 1
				}
				deleteSuccess.value = true

				showToast(context.root, {
					Title: 'Deleted Successfully',
					Icon: 'CheckCircleIcon',
					Text: res.Message,
					Variant: 'success',
				})
			} catch (err) {
				showToast(context.root, {
					Title: 'Error',
					Variant: 'danger',
					Icon: 'XCircleIcon',
					Text: err.Message,
				})
			} finally {
				isDeleting.value = false
			}
		}

		function handleSaveNewEmailTemplateName() {
			if (isEditEmailTemplate.value) {
				// on edit code here
			} else if (newEmailTemplateName.value) {
				let strWithoutSpaces = newEmailTemplateName.value?.replaceAll(' ', '-')
				this.$router.push(
					getRoute(APP_ROUTES.CREATE_EMAIL_TEMPLATE, {
						templateName: strWithoutSpaces,
					})
				)
			}
		}

		return {
			data,
			page,
			total,
			config,
			sortOrder,
			sortColumn,
			isDeleting,
			deleteSuccess,
			searchText,
			pageLimit,
			isFetching,
			dateTimeFormat,
			isCreateNewTemplate,
			newEmailTemplateName,
			EmailTemplateSentStatus,
			handleDelete,
			convertDateTime,
			handlePageClick,
			handlePageLimit,
			handleOkayPress,
			handleSortChange,
			handleSearchChange,
			handleDeleteEmailTemplate,
			handleEmailTemplateNameChange,
			handleViewEmailTemplateDetails,
			handleSaveNewEmailTemplateName,
			handleEditEmailTemplate,
		}
	},
}
</script>

<style scoped>
.view-icon {
	color: #888888;
}
.edit-icon {
	color: #3599cd;
}
.trash-icon {
	color: #f44336;
}

.sent-one-time {
	background-color: #28c76f1f;
}

.sent-many-times {
	background: #28c76f1f 0% 0% no-repeat;
}

.not-sent-yet {
	background-color: #f8f4e5;
}

.status-common {
	color: black;
	width: 184px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.table-container {
	margin-top: -10px;
}
</style>
