<template>
	<div class="blog-detail-wrapper">
		<b-row>
			<b-col cols="12">
				<template v-if="fetching">
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle"></b-spinner>
					</div>
				</template>

				<b-card class="container-card" v-if="!fetching">
					<div class="cover-image-container">
						<img
							:src="
								getMediaPath(groupData.CoverPhoto) ||
								require('../../assets/images/gallery/no-cover-image-placeholder.png')
							"
							class="cover-image"
						/>
					</div>

					<div class="p-2">
						<div>
							<b-card-title class="card-title">{{
								groupData.Name
							}}</b-card-title>

							<div
								class="
									d-flex
									align-items-center
									justify-content-start
									group-status
								"
							>
								<feather-icon
									:id="`locker-icon`"
									:icon="`${
										groupData.Visibility ==
										GroupsVisibility.PUBLIC
											? 'GlobeIcon'
											: 'LockIcon'
									}`"
									size="14"
									class="ml-0 mr-1 cursor-pointer"
								/>
								<span class="font-weight-normal">
									{{
										`${GroupsVisibility.getDisplayTextKey(
											groupData.Visibility
										)} Group`
									}}
								</span>
							</div>
						</div>

						<b-row col="12" class="pt-4 pb-4">
							<b-col sm="auto" lg="4" md="auto">
								<LabelText
									label="Admin"
									showRightSection
									textClasses="font-weight-bolder"
								>
									<UserInfo
										iconSize="md"
										:avatarImage="
											getMediaPath(
												groupData.AdminUser.Media
											)
										"
										:fullName="`${
											groupData.AdminUser.FullName || '-'
										}`"
									/> </LabelText
							></b-col>

							<b-col sm="auto" lg="4" md="auto"
								><LabelText
									label="Date Created"
									:text="
										convertDateTime({
											date: groupData.CreatedAt,
											customFormat:
												dateTimeFormat.appDateFormat,
											dateOnly: true,
										})
									"
								/>
							</b-col>

							<b-col sm="auto" lg="4" md="auto">
								<LabelText
									label="Tournament Count"
									:text="groupData.TournamentsCount || '-'"
									textClasses="font-bold"
									containerClasses="mb-8"
							/></b-col>
							<b-col sm="auto" lg="4" md="auto">
								<LabelText
									label="Members"
									textClasses="font-bold"
									showRightSection
								>
									<div
										v-if="totalGroupMembers > 0"
										class="mb-1"
									>
										<GroupedAvatar
											id="modal"
											:likedByProfiles="
												groupMembers.length < 5
													? groupMembers
													: groupMembers.slice(0, 5)
											"
											:onClick="handleGroupMembersModal"
										/>
									</div>
									<div v-else>
										<p class="font-weight-normal">
											No Members
										</p>
									</div>
								</LabelText>
							</b-col>
							<b-col sm="auto" lg="4" md="auto">
								<LabelText
									label="Total Posts"
									:text="groupData.PostsCount || '-'"
									textClasses="font-bold"
									:containerClasses="`${
										groupMembers.length > 0 && 'mt-1'
									}`"
							/></b-col>
						</b-row>
						<hr />
						<b-row col="12">
							<b-col lg="12" md="12" sm="12">
								<Title text="Group Description" />
							</b-col>
							<b-col col="12">
								<p
									v-html="
										`<p>
										${handleConvertTextLinksToTag(groupData.Description)}</p>`
									"
								></p>
							</b-col>
						</b-row>

						<b-row class="mt-4">
							<b-col lg="12" md="12" sm="12">
								<div style="width: 200px">
									<Button
										containerClasses="w-100"
										buttonText="Delete"
										buttonVariant="danger"
										classes="mt-1 mb-1 w-100"
										:clickHandler="handleDeleteClick"
									/>
								</div>
							</b-col>
						</b-row>
					</div>

					<ConfirmModal
						id="delete-group-modal"
						:isLoading="isProcessing"
						:isSuccess="deleteSuccess"
						successText="Group deleted successfully!"
						:onOkayPress="handleOkayPress"
						:onYesPress="handleYesPress"
						messageText="Are you sure you want to delete this group?"
					/>
					<BasicModal
						id="group-members-modal"
						modalTitle="Group Members"
					>
						<template #showAllViewsUsers>
							<GroupMembersModal :usersData="groupMembers" />
						</template>
					</BasicModal>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {
	BFormInput,
	BMedia,
	BAvatar,
	BMediaAside,
	BMediaBody,
	BImg,
	BLink,
	BFormGroup,
	BInputGroup,
	BInputGroupAppend,
	BCard,
	BCardText,
	BRow,
	BCol,
	BBadge,
	BDropdown,
	BDropdownItem,
	BForm,
	BFormTextarea,
	BFormCheckbox,
	BButton,
	BCardImg,
	BSpinner,
	BCarousel,
	BCarouselSlide,
} from 'bootstrap-vue'
import { textUrlToHtmlLink } from 'text-url-to-html-link'

import {
	getGroupDetails,
	getGroupMembers,
	deleteGroup,
} from '../../api/api.service'
import { getMediaPath } from '../../utils/commonUtils'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import Button from '../../components/Button.vue'
import { GroupsVisibility } from '../../constants'
import Title from '../../components/Title.vue'
import LabelText from '../../components/LabelText.vue'
import UserInfo from '../../components/UserInfo.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import BasicModal from '@/components/BasicModal.vue'
import { APP_ROUTES } from '@/helpers/routeHelpers'
import GroupedAvatar from '@/components/GroupedAvatar.vue'
import GroupMembersModal from './GroupMembersModal.vue'

export default {
	name: 'GroupDetails',
	components: {
		BFormInput,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BLink,
		BCard,
		BRow,
		BCol,
		BFormGroup,
		BInputGroup,
		BInputGroupAppend,
		BImg,
		BBadge,
		BCardText,
		BDropdown,
		BForm,
		BDropdownItem,
		BFormTextarea,
		BFormCheckbox,
		BButton,
		BCardImg,
		BSpinner,
		BCarousel,
		BCarouselSlide,
		Button,
		Title,
		LabelText,
		UserInfo,
		ConfirmModal,
		GroupedAvatar,
		BasicModal,
		GroupMembersModal,
	},

	data() {
		return {
			dateTimeFormat,
			GroupsVisibility,
			isFetching: false,
			isFetchingMembers: false,
			groupData: {},
			groupMembers: {},
			totalGroupMembers: 0,
			isProcessing: false,
			deleteSuccess: false,
		}
	},
	methods: {
		handleConvertTextLinksToTag(data = '') {
			if (!data) {
				return 'No description to show!'
			}
			let text = textUrlToHtmlLink(data)
			return text
		},
		async getGroupDetails(id) {
			try {
				this.isFetching = true
				let res = await getGroupDetails(id)
				this.groupData = { ...res }
			} catch (err) {
				if (err.Message == 'Group not found') {
					this.$router.push(APP_ROUTES.GROUPS_LISTING)
				}
			} finally {
				this.isFetching = false
			}
		},

		async getGroupMembersList(id) {
			try {
				this.isFetchingMembers = true
				let res = await getGroupMembers(id)
				this.groupMembers = res?.Users
				this.totalGroupMembers = res?.TotalUsers
			} catch (err) {
			} finally {
				this.isFetchingMembers = false
			}
		},
		handleYesPress() {
			this.isProcessing = true

			deleteGroup(this.$route.params.groupId)
				.then((res) => {
					this.deleteSuccess = true
				})
				.finally(() => {
					this.isProcessing = false
				})
		},

		handleDeleteClick() {
			this.$bvModal.show('delete-group-modal')
		},

		handleOkayPress() {
			this.deleteSuccess = false
			this.$router.replace(APP_ROUTES.GROUPS_LISTING)
		},
		handleGroupMembersModal() {
			this.$bvModal.show('group-members-modal')
		},
		getMediaPath,
		convertDateTime,
	},

	created() {
		this.getGroupDetails(this.$route.params.groupId)
		this.getGroupMembersList(this.$route.params.groupId)
	},
}
</script>



<style scoped>
.card-title {
	color: #003071;
	font-size: 18px;
	font-weight: bold !important;
}

.detail-img {
	margin-bottom: 50px;
}

.card-sub-title {
	color: #888888;
	font-size: 14px;
	margin-bottom: 10px;
}
.card-hash {
	color: #888888;
	font-size: 14px;
}

.card-bottom-text {
	font-size: 14px;
	color: black;
}

.edit-button {
	position: absolute;
	top: 50px;
	right: 45px;
	z-index: 1;
}

.img-slide-item {
	height: 394px;
}
.cover-image-container {
	height: 400px !important;
	width: 100% !important;
}
.cover-image {
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	height: 100%;
	width: 100%;
	object-fit: cover;
}
.card-body {
	padding: 0 !important;
}
.group-status {
	color: black;
	padding-top: 0.2rem;
}
</style> 

