<template>
	<div>
		<div>
			<div class="d-flex justify-content-end mb-1">
				<button class="btn btn-primary" @click="handleAddCategoryClick">
					<span class="btn-text">Add Category</span>
				</button>
			</div>

		</div>
		<b-row cols="1" v-if="!isFetching">
			<b-col xl="2" lg="3" md="3" sm="auto" v-for="item in gearCategoriesData">
				<div class="mb-2">
					<b-card class="card category-card">
						<div class="category-details-container">
							<div class="d-flex" style="justify-content: space-between; align-items: center;">
								<div class="category-title">
									{{ item.Name }}
								</div>
								<div class="d-flex">
									<img v-b-modal="'user-story-modal'" id="user-story-action-icon"
										@click="handleEditClick(item)" class="delete-icon mr-1" :src="editIcon" />
									<img v-b-modal="'user-story-modal'" id="user-story-action-icon"
										@click="handleDeleteClick(item.Id)" class="delete-icon" :src="deleteIcon" />
								</div>
							</div>
						</div>
						<div class="category-image-container">
							<img :src="getMediaPath(item.Media)" @error="replaceByDefault" class="category-image" />
						</div>
					</b-card>
				</div>
			</b-col>
			
		</b-row>
		<ConfirmModal id="delete-category-modal" :isLoading="isProcessing" :isSuccess="deleteSuccess"
			successText="Category deleted successfully!" :onOkayPress="handleOkayPress" :onYesPress="handleYesPress"
			messageText="Are you sure you want to delete this category?" />

		<BasicModal id="create-category-modal" :modalTitle="isEdit ? 'Edit Category' : 'Add New Category'">
			<template #showForm>
				<div>
					<validation-observer ref="validationRules">
						<b-form @submit.prevent="handleSubmit">

							<b-form-group label-cols="4" label="Category Name" label-for="category-name" label-size="md"
								class="mt-1">
								<validation-provider #default="{ errors }" rules="required" name="Category Name">
									<Input placeholder="Type Category Name" v-model="categoryName" id="category-name"
										size="md" classes="w-100" :errors="errors" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<b-form-group label-cols="4" label="Upload Image" label-for="input-default" label-size="md"
								class="mt-1">
								<validation-provider #default="{ errors }" name="Category Image">
									<FileInput :isRequired="false" :onChange="handleImageChange" v-model="categoryImage"
										placeholderText="Upload Image" :value="categoryImage" :errors="errors" />
									<small class="text-danger">{{ errors[0] }}</small>

								</validation-provider>
								<div v-if="(Object.keys(categoryImage).length)" class="category-image-container mt-1">
									<img :src="getImage(categoryImage)" @error="replaceByDefault" class="category-image" />
								</div>
							</b-form-group>

							<div class="d-flex justify-content-between">
								<div class="w-50">
									<Button buttonVariant="primary" :buttonText="isEdit ? 'Save Changes' : 'Submit'"
										classes="w-100" type="submit" :isLoading="isLoading" />
								</div>
							</div>
						</b-form>
					</validation-observer>
				</div>
			</template>
		</BasicModal>
	</div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BFormFile, BTooltip, BContainer, BRow, BCol, BFormInput, BModal, BForm, BFormGroup } from 'bootstrap-vue'
import { ref } from 'vue-demi'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ConfirmModal from '../../components/ConfirmModal.vue'
import { APP_ROUTES } from '../../helpers/routeHelpers'
import { getCategories, deleteGearCategory, createGearCategory, updateGearCategory } from '../../api/api.service'
import { finalizeMediaUtil, getMediaPath, getMediaStream, initializeMediaUtil, uploadOnS3 } from '../../utils/mediaUtils'
import { dateTimeFormat } from '../../utils/dateFormatUtils'
import DeleteIcon from '../../assets/images/icons/delete.svg'
import EditIcon from '../../assets/images/icons/edit.svg'
import BasicModal from "../../components/BasicModal.vue";
import Input from "../../components/Input.vue";
import FileInput from "../../components/FileInput.vue";
import Button from "../../components/Button.vue";
import { hideModal } from '@/utils/commonUtils'
import showToast from "../../hooks/useToast";

export default {
	name: 'CategoyListing',
	components: {
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		ConfirmModal,
		BContainer,
		BRow,
		BFormInput,
		BCol,
		BasicModal,
		BForm,
		BModal,
		BFormGroup,
		Input,
		ValidationObserver,
		ValidationProvider,
		Input,
		Button,
		FileInput,
		BFormFile
	},
	setup(props, context) {
		const categoryName = ref("");
		const categoryImage = ref("");
		const isImageChanged = ref(false);
		const isError = ref("");
		const URL = window.URL

		return {
			getMediaPath,
			async fetchCategories() {
				try {
					this.isFetching = true
					let res = await getCategories()
					this.gearCategoriesData = [...res.GearCategories]
				} catch (err) {
				} finally {
					this.isFetching = false
				}
			},
			handleDeleteClick: function (id) {
				this.selectedItem = id
				this.$bvModal.show('delete-category-modal')
			},
			handleYesPress() {
				this.isProcessing = true

				deleteProduct(this.$route.params.storeId)
					.then((res) => {
						this.deleteSuccess = true
					})
					.finally(() => {
						this.isProcessing = false
					})
			},
			handleOkayPress() {
				this.deleteSuccess = false
				this.$bvModal.hide('delete-category-modal')
			},
			replaceByDefault: function (event) {
				event.target.src = this.placeholderImage
			},
			handleYesPress: async function () {
				this.isProcessing = true
				try {
					await deleteGearCategory(this.selectedItem)
					this.deleteSuccess = true
					this.$bvModal.hide('delete-category-modal')
					this.fetchCategories()
				} catch (err) {
				} finally {
					this.isProcessing = false
				}
			},
			handleImageChange: function (event) {
				isImageChanged.value = true
			},
			handleAddCategoryClick: function () {
				this.isEdit = false
				this.selectedItem = null
				categoryName.value = ""
				categoryImage.value = ""
				this.$bvModal.show('create-category-modal')
			},
			handleEditClick: function (category) {
				isImageChanged.value = false
				this.isEdit = true
				this.selectedItem = category.Id
				categoryName.value = category.Name
				categoryImage.value = category.Media || ""

				this.$bvModal.show('create-category-modal')
			},
			handleSubmit: async function () {
				let canSubmit = await this.$refs.validationRules.validate();
				if (!canSubmit) {
					return;
				}
				if (this.selectedItem) {
					this.handleUpdateCategory();
				} else {
					this.handleCreateCategory();
				}
			},
			handleCreateCategory: async function () {
				if (!categoryName.value && !categoryImage.value) {
					return;
				}

				let Media = null;
				try {
					isError.value && !isError.value;
					this.isLoading = true
					Media = await this.uploadMedia(categoryImage.value);
					let bodyData = {
						Name: categoryName.value,
						MediaId: Media.Id,
					};
					let uploaded = await createGearCategory(bodyData);

					this.isSuccess = true;
					this.isLoading = false;

					hideModal("create-category-modal", context);
					this.fetchCategories()
					showToast(context.root, {
						Title: "Added Successfully",
						Icon: "CheckCircleIcon",
					});
				} catch (err) {
					isError.value = true;
					this.isLoading = false;
					showToast(context.root, {
						Title: "Error",
						Icon: "XCircleIcon",
						Text: err.Message,
						Variant: "danger",
					});
					return;
				} finally {
					this.isLoading = false;
					this.isSuccess = false;
				}
			},
			handleUpdateCategory: async function () {
				if (!categoryName.value && !categoryImage.value) {
					return;
				}

				let Media = null;
				try {
					isError.value && !isError.value;
					this.isLoading = true
					if (isImageChanged.value) {
						Media = await this.uploadMedia(categoryImage.value);
					}
					let bodyData = {
						Name: categoryName.value,
						MediaId: Media ? Media.Id : null,
					};
					await updateGearCategory(this.selectedItem, bodyData);

					this.isSuccess = true;
					this.isLoading = false;

					hideModal("create-category-modal", context);
					this.fetchCategories()
					showToast(context.root, {
						Title: "Updated Successfully",
						Icon: "CheckCircleIcon",
					});
				} catch (err) {
					isError.value = true;
					this.isLoading = false;
					showToast(context.root, {
						Title: "Error",
						Icon: "XCircleIcon",
						Text: err.Message,
						Variant: "danger",
					});
					return;
				} finally {
					this.isLoading = false;
					this.isSuccess = false;
				}
			},
			uploadMedia: async (file) => {
				let credentialsRequest = initializeMediaUtil(file);
				let mediaStreamRequest = getMediaStream(file);
				const [credentials, mediaStream] = await Promise.all([
					credentialsRequest,
					mediaStreamRequest,
				]);
				await uploadOnS3(mediaStream, credentials, (progress) => {
					progress.value = progress;
				});
				let finalizeRes = await finalizeMediaUtil(credentials?.MediaId);

				return finalizeRes?.Media;
			},
			getImage: (categoryImage) => {
				if(isImageChanged.value){
					return URL.createObjectURL(categoryImage)
				}
				return getMediaPath(categoryImage) || URL.createObjectURL(categoryImage)
			},

			dateTimeFormat,
			isFetching: false,
			storeData: {},
			isProcessing: false,
			deleteSuccess: false,
			storeProducts: [],
			deleteIcon: DeleteIcon,
			editIcon: EditIcon,
			activeTab: 1,
			emptyImage: require('@/assets/images/gallery/no-data.png'),
			placeholderImage: require('@/assets/images/gallery/placeholder-image.png'),
			gearCategoriesData: [],
			selectedItem: null,
			isEdit: false,
			isLoading: false,
			isSuccess: false,
			URL,

			categoryName,
			categoryImage

		}
	},
	created() {
		this.fetchCategories()
	},
}
</script>


<style scoped>
.card-body {
	padding: 14px !important;
}

.category-image-container {
	height: 210px;
}

.category-title {
	color: #000;
	font-size: 14px;
	font-weight: bold !important;
}


.category-image {
	width: 100%;
	height: 100%;
	border-radius: 6px;
	object-fit: cover;
	object-position: center;
}

.category-details-container {
	padding: 0px 5px 10px;
}

.category-card {
	background-color: #fff;
}

</style> 

