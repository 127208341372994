<template>
  <div>
    <b-row>
      <b-col lg="6" sm="12" md="6" cols="12" xl="6">
        <b-card no-body>
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              h-100
              w-100
              p-2
            "
          >
            <Title text="Location Ratings" classes="mb-0" />

            <div class="d-flex flex-row align-items-center">
              <p class="mr-1 select-text mb-0">Select Month</p>
              <!-- <DropdownButton
                buttonText="Select Month"
                variant="outline-primary"
              >
                <b-dropdown-item> January </b-dropdown-item>
                <b-dropdown-item> February </b-dropdown-item>
              </DropdownButton> -->
              <InputSelect
                placeholder="Select Month"
                :options="monthSelectOptions"
                classes="filter-select"
                :value="selectedLocationMonth"
                :onChange="locaitonGraphRatingHandler"
              />
            </div>
          </div>

          <b-card-body>
            <BarChart
              :height="250"
              :chartData="{
                labels: labels,
                datasets: [{ ...colorConfig, data: [...locationRatingPoints] }],
              }"
              :options="options"
            />
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="6" sm="12" md="6" cols="12" xl="6">
        <b-card no-body>
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              h-100
              w-100
              p-2
            "
          >
            <Title text="Tour Guide Ratings" classes="mb-0" />
            <div class="d-flex flex-row align-items-center">
              <p class="mr-1 select-text mb-0">Select Month</p>
              <!-- <DropdownButton
                buttonText="Select Month"
                variant="outline-primary"
              >
                <b-dropdown-item> January </b-dropdown-item>
                <b-dropdown-item> February </b-dropdown-item>
              </DropdownButton> -->
              <InputSelect
                placeholder="Select Month"
                :options="monthSelectOptions"
                classes="filter-select"
                :value="selectedGuideMonth"
                :onChange="guideGraphRatingHandler"
              />
            </div>
          </div>

          <b-card-body>
            <BarChart
              :height="250"
              :chartData="{
                labels: labels,
                datasets: [{ ...colorConfig, data: [...tripRatingPoints] }],
              }"
              :options="options"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <RatingTable />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BRow,
  BCol,
  BDropdownItem,
} from "bootstrap-vue";
import moment from "moment";

import BarChart from "../../components/chart/BarChart.vue";
import Title from "../../components/Title.vue";
import DropdownButton from "../../components/DropdownButton.vue";
import RatingTable from "./RatingTable.vue";
import InputSelect from "../../components/InputSelect.vue";
import { getRatingsGraphPoints } from "../../api/api.service";
import { getMonthDates } from "../../utils/commonUtils";
import { options, monthSelectOptions } from "./rating-config";

export default {
  name: "RatingReviews",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BDropdownItem,
    DropdownButton,
    BarChart,
    Title,
    RatingTable,
    InputSelect,
  },
  data() {
    return {
      options,
      monthSelectOptions,
      getMonthDates,
      defaultMonth: null,
      selectedLocationMonth: null,
      selectedGuideMonth: null,
      locationRatingPoints: [],
      tripRatingPoints: [],
      labels: ["1", "2", "3", "4", "5"],
      colorConfig: {
        backgroundColor: "#3599CD",
        borderColor: "transparent",
      },
    };
  },
  mounted() {
    this.locaitonGraphRatingHandler(this.defaultMonth);
    this.guideGraphRatingHandler(this.defaultMonth);
  },
  methods: {
    locaitonGraphRatingHandler(month) {
      this.selectedLocationMonth = month;
      let getMonthD = this.getMonthDates(
        month ? month : moment().format("MMMM")
      );
      let paramData = {
        ...getMonthD,
        EntityType: 5,
      };
      getRatingsGraphPoints(paramData)
        .then((res) => {
          let { ReviewGraphData } = res;
          this.locationRatingPoints = ReviewGraphData.map((item) => item.Count);
        })
        .catch((err) => {});
    },
    guideGraphRatingHandler(month) {
      this.selectedGuideMonth = month;
      let getMonthD = this.getMonthDates(
        month ? month : moment().format("MMMM")
      );

      let paramData = {
        ...getMonthD,
        EntityType: 6,
      };
      getRatingsGraphPoints(paramData)
        .then((res) => {
          let { ReviewGraphData } = res;
          this.tripRatingPoints = ReviewGraphData.map((item) => item.Count);
        })
        .catch((err) => {});
    },

    setMonth(value) {
      this.selectedLocationMonth = value;
    },
  },
  created() {
    // Setting current month
    this.defaultMonth = moment().format("MMMM");
  },
};
</script>

<style scoped>
.label-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.label-container > p {
  margin-top: 16px;
  font-size: 10px;
  font-weight: bold;
  color: #000;
}

.select-text {
  color: #888888;
  font-size: 14px;
}
::v-deep .filter-select {
  min-width: 190px;
  border: 1px solid green !important;
}
</style>
