<template>
  <div class="mt-2">
    <!-- <b-card body-class="card-body-style p-0">
      <div class="p-1">
        <search-header :showSearchField="false" :showStatusFilter="false" :pageLimitValue="pageLimit"
          :onPageLimitChange="handlePageLimitChange" :selectedPageValue="page" :perPage="pageLimit"
          :onHandlePageChange="handlePageChange" classes="margin-bottom-only">
          <template #left-header-content>
            <span class="search-label mr-1">Search</span>
            <b-form-input class="d-inline-block w-25" @input="handleSearchChange" :value="searchText" />
          </template>
          <template #right-header-content>
            <Button buttonVariant="outline-primary" buttonText="+ Add New Category" :clickHandler="handleAddClick" />
          </template>
        </search-header>
      </div>
      <data-table :perPageLimit="pageLimit" entriesName="Gear Categories" :showPagination="true" :config="config"
        :data="data" :totalRecords="total" :isLoading="isFetching" :selectedPageValue="page" :perPage="data.length"
        :onHandlePageChange="handlePageChange">
        <template #cell(No)="data">
          <span class="font-weight-bolder">{{ data.item.Id }}</span>
        </template>

        <template #cell(Categories)="data">
          <span class="font-weight-bolder">{{ data.item.Name }}</span>
        </template>

        <template #cell(Actions)="data">
          <div class="text-nowrap">
            <feather-icon v-b-tooltip.hover.top="'Edit'" icon="EditIcon" size="16" class="mr-3 detail-icon cursor-pointer"
              v-on:click="handleEditClick(data)" v-b-modal="modalId" />

            <feather-icon v-b-tooltip.hover.top="'Delete'" :icon="`Trash2Icon`" class="delete-icon cursor-pointer"
              size="16" @click="handleDeleteClick(data.item.Id)" v-b-modal="'delete-modal'" />
          </div>
        </template>
      </data-table>
    </b-card> -->
    <div class="d-flex add-btn-container">
      <Button buttonVariant="outline-primary" buttonText="+ Add New Category" :clickHandler="handleAddClick"
      containerClasses="mb-2 add-category-btn" classes="add-category-btn" />
    </div>
    <BasicModal id="modal-1" :modalTitle="
      isEdit ? 'Edit Fishing Gear Category' : 'Add New Fishing Gear Category'
    ">
      <template #showForm>
        <div>
          <validation-observer ref="validationRules">
            <b-form @submit.prevent="validateForm">
              <b-form-group label-cols="4" label="Category Name" label-for="input-default" label-size="md" class="mt-2">
                <validation-provider #default="{ errors }" rules="required|min:3|max:30|regex:^[a-zA-Z0-9 ]*$"
                  name="Gear Category Name">
                  <Input placeholder="Type Category Name" id="input-default" size="md" classes="w-100"
                    v-model="categoryName" :errors="errors" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label-cols="4" label="For Sell Gears Only" label-for="input-sellable" label-size="md"
                class="mt-2 sellable-container">
                <b-form-checkbox id="input-sellable" v-model="isSellable">
                </b-form-checkbox>
              </b-form-group>
              <div class="text-right mt-3">
                <Button buttonVariant="primary" :buttonText="isEdit ? 'Submit' : 'Add Category'" type="submit"
                  classes="w-50" :isLoading="isLoading" />
              </div>
            </b-form>
          </validation-observer>
        </div>
      </template>
    </BasicModal>
    <ConfirmModal id="delete-modal" :isLoading="isLoading" :isSuccess="deleteSuccess"
      successText="Gear Category Deleted Successfully !" :onOkayPress="handleOkayPress" :onYesPress="handleYesPress"
      messageText="Are you sure you want to delete this gear category?" />

    <b-row cols="1" v-if="!isFetching" class="categories-row">

      <b-col xl="2" lg="3" md="3" sm="auto" v-for="item in data">
        <div class="mb-2">
          <b-card class="card category-card">
            <div class="category-details-container">
              <div class="d-flex" style="justify-content: space-between; align-items: center;">
                <div class="category-title">
                  {{ item.Name }}
                </div>
                <div class="text-nowrap">
              <feather-icon v-b-tooltip.hover.top="'Edit'" icon="EditIcon" size="16"
                class="mr-1 detail-icon cursor-pointer" v-on:click="handleEditClick(item)" v-b-modal="modalId" />

              <feather-icon v-b-tooltip.hover.top="'Delete'" :icon="`Trash2Icon`" class="delete-icon cursor-pointer"
                size="16" @click="handleDeleteClick(item.Id)" v-b-modal="'delete-modal'" />
            </div>
              </div>
            </div>
            <div class="category-image-container">
              <img :src="getMediaPath(item.Media)" @error="replaceByDefault" class="category-image" />
            </div>
            
          </b-card>
        </div>
      </b-col>




    </b-row>


  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  alphaNum,
  max,
  alphaSpaces,
  image,
  size,
} from "@validations";
import {
  BCard,
  BCardBody,
  BBadge,
  BLink,
  BTooltip,
  BFormInput,
  BFormGroup,
  BForm,
  VBTooltip,
  VBModal,
  BFormCheckbox,
  BRow,
  BCol
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";

import DataTable from "../../../components/data-table/DataTable.vue";
import SearchHeader from "../../../components/SearchHeader.vue";
import Button from "../../../components/Button.vue";
import BasicModal from "../../../components/BasicModal.vue";
import ConfirmModal from "../../../components/ConfirmModal.vue";
import Input from "../../../components/Input.vue";
import {
  getGearCategoryList,
  createGearCategory,
  updateGearCategory,
  deleteGearCategory,
} from "../../../api/api.service";
import { SortOrder } from "../../../constants";
import { useLocalPagination } from "../../../hooks/useLocalPagination";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getMediaPath } from "@/utils/commonUtils";
import placeholderImage from '@/assets/images/gallery/placeholder-image.png'

const config = [
  {
    label: "No",
    key: "No",
    tdClass: "table-row",
    thClass: "table-header",
  },
  {
    label: "CATEGORIES",
    key: "Categories",
    tdClass: "table-row",
    thClass: "table-header",
  },
  {
    label: "ACTIONS",
    key: "Actions",
    tdClass: "table-row",
    thClass: "table-header",
  },
];

export default {
  name: "FishingGearCategoriesList",
  components: {
    DataTable,
    BForm,
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BTooltip,
    SearchHeader,
    BRow,
    BCol,
    BFormInput,
    Button,
    BasicModal,
    BFormGroup,
    Input,
    VBTooltip,
    ValidationProvider,
    ValidationObserver,
    VBModal,
    ConfirmModal,
    BFormCheckbox,
  },

  setup(props, context) {
    const categoryName = ref("");
    const isSellable = ref(false);
    const categoryId = ref(null);
    const isEdit = ref(false);
    const isLoading = ref(false);
    const deleteSuccess = ref(false);

    const {
      data,
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      handleSearchChange,
      handlePageClick,
      handlePageLimit,
      request,
    } = useLocalPagination(
      getGearCategoryList,
      () => ({
        Direction: SortOrder.DESC,
        Column: "CreatedAt",
        IncludeSellable: true,
      }),
      [],
      "Name",
      "GearCategories",
      "TotalGearCategories"
    );

    const handlePageChange = (pageNumber) => {
      handlePageClick(pageNumber);
    };

    function handleAddClick() {
      isEdit.value = false;
      categoryName.value = "";
      categoryId.value = null;
      isSellable.value = false;
      this.$bvModal.show("modal-1");
    }

    const handlePageLimitChange = (val) => {
      page.value = 1;

      handlePageLimit(val);
    };

    const handleEditClick = (data) => {
      isEdit.value = true;
      categoryName.value = data.Name;
      isSellable.value = data.IsSellable;
      categoryId.value = data.Id;
    };

    const replaceByDefault = (event) => {
      event.target.src = placeholderImage
    }

    async function validateForm() {
      let canSubmit = await this.$refs.validationRules.validate();
      if (!canSubmit) {
        return;
      }

      isLoading.value = true;

      let payload = {
        Name: categoryName.value,
        IsSellable: isSellable.value,
      };

      let requestFunc = isEdit.value
        ? updateGearCategory(categoryId.value, payload)
        : createGearCategory(payload);

      requestFunc
        .then(() => {
          this.$bvModal.hide("modal-1");

          if (isEdit.value) {
            data.value = data.value.map((item) => {
              if (item.Id === categoryId.value) {
                return {
                  ...item,
                  ...payload,
                };
              }
              return item;
            });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Gear Updated",
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
          } else {
            if (page.value !== 1) {
              page.value = 1;
            } else {
              request();
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Gear Created Successfully",
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.Message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    const handleYesPress = () => {
      isLoading.value = true;

      deleteGearCategory(categoryId.value)
        .then((res) => {
          data.value = data.value.filter(
            (item) => item.Id !== categoryId.value
          );

          total.value = total.value - 1;
          deleteSuccess.value = true;

          if (total.value && !Boolean(data.value.length)) {
            page.value = page.value - 1;
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const handleDeleteClick = (id) => {
      categoryId.value = id;
    };

    const handleOkayPress = () => {
      deleteSuccess.value = false;
      categoryId.value = null;
    };

    return {
      categoryName,
      isSellable,
      modalId: "modal-1",
      isEdit,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
      alphaNum,
      max,
      alphaSpaces,
      image,
      size,
      config,
      data,
      placeholderImage,
      handleEditClick,
      handleAddClick,
      validateForm,
      isFetching,
      total,
      page,
      isLoading,
      deleteSuccess,
      searchText,
      handleSearchChange,
      handlePageChange,
      handlePageLimitChange,
      pageLimit,
      handleYesPress,
      handleOkayPress,
      handleDeleteClick,
      getMediaPath,
      replaceByDefault,
    };
  },

  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>

<style scoped>
.delete-icon {
  color: red;
}

.detail-icon {
  color: #3599cd;
}

.sellable-container {
  display: flex;
  align-items: center;
}

::v-deep .table-row {
  font-weight: bold;
}

::v-deep .table-header {
  width: 40%;
}

.card-body {
  padding: 14px !important;
}

.category-image-container {
  height: 210px;
}

.category-title {
  color: #000;
  font-size: 14px;
  font-weight: bold !important;
}


.category-image {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
  object-position: center;
}

.category-details-container {
  padding: 0px 5px 10px;
}

.category-card {
  background-color: #fff;
}

.add-btn-container {
  justify-content: end;
  position: absolute;
  right: 30px;
  top: 104px;
}

/* media query for mobiles */

@media only screen and (max-width: 768px) {
  .add-btn-container {
    width: calc(100vw - 64px);
    top: 178px;
  }

  .add-category-btn{
    width: 100% !important;
  }

  .categories-row{
    margin-top: 55px;
  }


}

/* container */
/* width: 100%; */

/* button first parent */
/* width: calc(100vw - 64px); */

/* button */
/* width: 100%; */

</style>
