export const downloadCSVData = (csvText) => {
    let blob = new Blob([csvText])
    let link = document.createElement('a')
    link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvText)
    link.href = window.URL.createObjectURL(blob)
    link.download = `sample_${new Date().getTime()}.xls`
    link.click()
}

export const downloadXlsData = async (response) => {
    let blob = await response.blob()
    var url = window.URL.createObjectURL(blob)
    var a = document.createElement('a')
    a.href = url
    a.download = `report_${new Date().getTime()}.xlsx`
    document.body.appendChild(a)
    a.click()
    a.remove()
}
