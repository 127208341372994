<template>
  <span>
    <label v-if="showLabel" class="font14">{{ labelText }}</label>
    <v-select
      :disabled="isDisable"
      @input="onChange"
      :value="value"
      :options="options"
      :clearable="clearable"
      :class="classes"
      :placeholder="placeholder"
      :searchable="false"
      class="primary"
    >
      <template #selected-option="{ label }">
        <span class="text-truncate overflow-hidden">
          {{ label }}
        </span>
      </template>
    </v-select>
  </span>
</template>

<script>
import vSelect from "vue-select";
export default {
  name: "InputSelect",
  components: {
    vSelect,
  },
  data() {
    return {};
  },

  props: {
    options: {
      type: Array,
    },
    // value: {
    //   type: String,
    //   default: "",
    // },
    clearable: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    onChange: Function,
    value: {
      default: "",
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.font14 {
  font-size: 1rem !important;
}
// ::v-deep .primary {
//   color: #3599cd;
// }

// ::v-deep .vs__dropdown-toggle {
//   border-color: #3599cd;
// }

// ::v-deep .text-truncate {
//   color: #3599cd;
// }
.white-bg {
  background-color: white !important;
}
</style>
