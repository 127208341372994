import { injectBaseConstantMethods } from './BaseConstants'

const TournamentStatuses = {
    NotStarted: 1,
    OnGoing: 2,
    WaitingForResult: 3,
    WaitingForSendingPrizeMoney: 4,
    Completed: 5,
}

const displayTextKeys = {
    [TournamentStatuses.Completed]: 'Completed',
    [TournamentStatuses.NotStarted]: 'Not Started',
    [TournamentStatuses.OnGoing]: 'On Going',
    [TournamentStatuses.WaitingForResult]: 'Waiting For Result',
    [TournamentStatuses.WaitingForSendingPrizeMoney]:
        'Waiting For Sending Prize Money',
}

const labelClass = {
    [TournamentStatuses.Completed]: 'tournament-completed',
    [TournamentStatuses.NotStarted]: 'tournament-not-started',
    [TournamentStatuses.OnGoing]: 'tournament-on-going',
    [TournamentStatuses.WaitingForResult]: 'tournament-waiting-for-result',
    [TournamentStatuses.WaitingForSendingPrizeMoney]:
        'tournament-waiting-for-prize-money',
}

export default injectBaseConstantMethods(
    TournamentStatuses,
    displayTextKeys,
    labelClass
)
