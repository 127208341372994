<template>
	<ul>
		<component
			:is="resolveNavItemComponent(item)"
			v-for="item in menuItems"
			:key="item.header || item.title"
			:item="item"
		/>
	</ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import {
	onMounted,
	provide,
	ref,
	computed,
	reactive,
	toRefs,
} from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import { useStore } from '@/store'

export default {
	components: {
		VerticalNavMenuHeader,
		VerticalNavMenuLink,
		VerticalNavMenuGroup,
	},
	props: {
		items: {
			type: Array,
			default: [],
			required: true,
		},
	},
	setup(props) {
		provide('openGroups', ref([]))
		const store = useStore()
		const currentUserType = computed(() => store?.state?.auth?.user?.Type)
		const state = reactive({ menuItems: [] })

		onMounted(() => {
			state.menuItems = props.items?.filter((obj) =>
				obj.permissions?.includes(currentUserType.value)
			)
		})

		return {
			...toRefs(state),
			resolveNavItemComponent,
		}
	},
}
</script>
