<template>
	<div class="table-container">
		<b-card body-class="card-body-style">
			<search-header
				searchInputClasses="w-50"
				:showStatusFilter="false"
				:onSearchChange="handleSearchChange"
				:searchText="searchText"
				:pageLimitValue="pageLimit"
				:onPageLimitChange="handlePageLimitChange"
			></search-header>
			<data-table
				:perPageLimit="pageLimit"
				entriesName="Groups"
				:showPagination="true"
				:config="config"
				:data="data"
				:selectedPageValue="page"
				:perPage="data.length"
				:onHandlePageChange="handlePageChange"
				:onSortClick="handleSortChange"
				:totalRecords="total"
				:isLoading="isFetching"
			>
				<template #cell(No)="data">
					<span class="font-weight-bolder">
						{{ data.index + 1 }}
					</span>
				</template>

				<template #cell(Name)="data">
					<span class="font-weight-bolder">
						{{ data.value || '-' }}
					</span>
				</template>

				<template #cell(HostGroupName)="data">
					<span class="font-weight-bold">
						{{ data.value }}
					</span>
				</template>

				<template #cell(Type)="data">
					<span class="font-weight-bold">
						{{ TournamentType.getDisplayTextKey(data.value) }}
					</span>
				</template>

				<template #cell(User)="data">
					<user-info
						iconSize="md"
						:fullName="data.item.User.FullName || '-'"
						:avatarImage="getMediaPath(data.item.User.Media)"
						nameClasses="font-weight-bolder"
					/>
				</template>

				<template #cell(ParticipantsCount)="data">
					<span class="purchase-date">
						{{ data.value }}
					</span>
				</template>
				<template #cell(EntryFee)="data">
					<span class="time">
						{{
							data.item.Type == TournamentType.Teenagers
								? 'No Fee'
								: `${data.item.EntityFeeCurrency} ${
										data.item.EntryFee || '-'
								  }`
						}}
					</span>
				</template>

				<template #cell(TournamentStatus)="data">
					<div
						:class="`status-common ${
							data.item.IsCanceled
								? 'tournament-canceled '
								: TournamentStatuses.getLabelClass(
										data.item.TournamentStatus
								  )
						}`"
					>
						{{
							data.item.IsCanceled
								? 'Canceled'
								: TournamentStatuses.getDisplayTextKey(
										data.item.TournamentStatus
								  ) || '-'
						}}
					</div></template
				>

				<template #cell(Actions)="data">
					<div class="text-nowrap">
						<feather-icon
							:id="`${data.item.Id}-detail-icon`"
							icon="EyeIcon"
							size="16"
							class="mx-1 detail-icon cursor-pointer"
							v-on:click="viewDetails(data.item.Id)"
						/>
						<b-tooltip
							title="Detail"
							:target="`${data.item.Id}-detail-icon`"
						/>

						<img
							v-b-modal="'delete-tournament-modal'"
							@click="handleDeleteClick(data.item.Id)"
							:id="`${data.item.Id}-action-icon`"
							class="delete-icon"
							:src="deleteIcon"
						/>

						<b-tooltip
							title="Delete"
							class="cursor-pointer"
							:target="`${data.item.Id}-action-icon`"
						/>
					</div>
				</template>
			</data-table>
		</b-card>
		<ConfirmModal
			id="delete-tournament-modal"
			:isLoading="isProcessing"
			:isSuccess="deleteSuccess"
			successText="Tournament deleted successfully!"
			:onOkayPress="handleOkayPress"
			:onYesPress="handleYesPress"
			messageText="Are you sure you want to delete this tournament?"
		/>
	</div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from 'bootstrap-vue'
import { reactive, ref } from '@vue/composition-api'

import DataTable from '../../components/data-table/DataTable.vue'
import UserInfo from '../../components/UserInfo.vue'
import SearchHeader from '../../components/SearchHeader.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import { SortOrder, TournamentStatuses, TournamentType } from '../../constants'
import { APP_ROUTES, getRoute } from '../../helpers/routeHelpers'
import { getTournamentsListing, deleteTournament } from '../../api/api.service'
import { getMediaPath } from '../../utils/mediaUtils'
import { useLocalPagination } from '../../hooks/useLocalPagination'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import DeleteIcon from '../../assets/images/icons/delete.svg'

const config = [
	{
		label: 'S.No',
		key: 'No',
	},
	{
		label: 'TOURNAMENT NAME',
		key: 'Name',
	},
	{
		label: 'GROUP NAME',
		key: 'HostGroupName',
	},
	{
		label: 'TYPE',
		key: 'Type',
	},
	{
		label: 'TOURNAMENT CREATOR',
		key: 'User',
	},
	{
		label: 'PARTICIPANTS',
		key: 'ParticipantsCount',
		// isSortable: true,
	},
	{
		label: 'FEES',
		key: 'EntryFee',
	},
	{
		label: 'STATUS',
		key: 'TournamentStatus',
		// isSortable: true,
	},
	{
		label: 'ACTIONS',
		key: 'Actions',
	},
].map((item) => {
	return { ...item, tdClass: 'table-row' }
})

export default {
	name: 'TournamentsListing',
	components: {
		DataTable,
		UserInfo,
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		SearchHeader,
		ConfirmModal,
	},
	setup(props, context) {
		const selectedId = ref(null)
		const isProcessing = ref(false)
		const deleteSuccess = ref(false)

		const {
			data,
			isFetching,
			total,
			page,
			searchText,
			pageLimit,
			sortOrder,
			sortColumn,
			handleSearchChange,
			handlePageClick,
			handlePageLimit,
			handleSortChange,
		} = useLocalPagination(
			getTournamentsListing,
			() => ({
				Direction: SortOrder.DESC,
				Column: 'CreatedAt',
			}),
			[],
			'Q',
			'Tournaments',
			'TotalTournaments'
		)

		const handlePageChange = (pageNumber) => {
			handlePageClick(pageNumber)
		}

		const handlePageLimitChange = (val) => {
			page.value = 1

			handlePageLimit(val)
		}

		const handleYesPress = () => {
			isProcessing.value = true

			deleteTournament(selectedId.value)
				.then(() => {
					data.value = data.value.filter(
						(item) => item.Id !== selectedId.value
					)
					deleteSuccess.value = true
				})
				.finally(() => {
					isProcessing.value = false
				})
		}

		const handleOkayPress = () => {
			deleteSuccess.value = false
			selectedId.value = null
		}

		const handleDeleteClick = (id) => {
			selectedId.value = id
		}

		const viewDetails = (id) => {
			context.root.$router.push(
				getRoute(APP_ROUTES.TOURNAMENT_DETAILS, { tournamentId: id })
			)
		}

		return {
			deleteIcon: DeleteIcon,
			config,
			data,
			total,
			page,
			pageLimit,
			isFetching,
			sortOrder,
			sortColumn,
			searchText,
			isProcessing,
			deleteSuccess,
			handleOkayPress,
			handleDeleteClick,
			handleYesPress,
			handleSearchChange,
			handlePageChange,
			handlePageLimit,
			handleSortChange,
			getMediaPath,
			viewDetails,
			handlePageLimitChange,
			dateTimeFormat,
			convertDateTime,
			TournamentStatuses,
			TournamentType,
		}
	},
}
</script>

<style scoped>
::v-deep .active-icon {
	color: #28c76f;
}

::v-deep .inactive-icon {
	color: #d88416;
}

::v-deep .detail-icon {
	color: #888888;
}

.delete-icon {
	width: 14px;
	height: 14px;
	cursor: pointer;
}

.time {
	font-weight: 500;
}

.card-body-style {
	padding: 0 !important;
}
::v-deep .table-row {
	font-weight: bold;
}

::v-deep .address-cordinates {
	color: #888888;
	font-size: 12px;
}
.table-container {
	margin-top: -10px;
}

.status-common {
	color: black;
	width: 150px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
	/* padding: 0.8rem; */
	font-weight: 400;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 0.9rem;
}
.tournament-completed {
	background: #28c76f1f;
}
.tournament-canceled {
	background: #ffdcdc;
}
.tournament-not-started {
	background: rgba(30, 194, 255, 0.12);
}
.tournament-waiting-for-result {
	background: #ffeedc;
}
.tournament-on-going {
	background: #2897c71f;
}
.tournament-waiting-for-prize-money {
	background: #283dc71f;
}
</style>
