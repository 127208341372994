<template>
	<b-card>
		<div class="loader-container" v-if="isLoading">
			<b-spinner class="loader" variant="primary"></b-spinner>
		</div>
		<div class="loader-container" v-if="!isLoading && !currentStory">
			<p class="msg">User Story Not Found</p>
		</div>
		<b-container fluid v-if="currentStory && !isLoading">
			<div>
				<b-row>
					<h4 class="text-secondary pl-1 mb-3 font-weight-bolder">
						User Story Details
					</h4>
				</b-row>
				<b-row>
					<b-col cols="12" md="6" lg="6" sm="12">
						<LabelText
							label="Post By"
							showRightSection
							textClasses="font-weight-bolder"
						>
							<UserInfo
								iconSize="md"
								:avatarImage="
									getMediaPath(currentStory.User.Media)
								"
								:fullName="`${
									(currentStory.User.FullName &&
										currentStory.User.FullName) ||
									'-'
								}`"
							/>
						</LabelText>
						<LabelText
							label="Date"
							:text="formatDate(currentStory.ActiveDate)"
							textClasses="font-weight-bolder"
						/>
						<LabelText
							label="Total Likes"
							:text="currentStory.UserLikeCount"
							textClasses="font-weight-bolder"
						/>
					</b-col>
					<!-- <b-col cols="12" md="6" lg="6" sm="12">
						<p class="title">
							Status:<b-badge pill class="ml-1 status-pill">{{
								submissionsStatus.getDisplayTextKey(
									currentStory.Status
								)
							}}</b-badge>
						</p>
						<LabelText
							label="Location"
							:text="
								currentStory.Location.Address
									? currentStory.Location.Address
									: '-'
							"
							textClasses="font-weight-bolder"
						/>
					</b-col> -->
				</b-row>
				<!-- Image Gallery -->
				<b-row v-if="currentStory.Media.length > 0">
					<div
						class="mt-1 ml-1 preview-image-container"
						v-b-modal="'preview-image'"
					>
						<StorySlider
							:mediaObj="currentStory"
							:isPreview="true"
						/>

						<!-- <div
							v-for="(item, index) in currentStory.Media"
							:key="index"
						>
							<b-img
								v-b-modal="'preview-image'"
								class="d-inline-block mr-1 mb-1"
								width="120"
								height="120"
								:src="getMediaPath(item)"
							/>

							<BasicModal
								id="preview-image"
								modalTitle="Preview"
								modalBodyClasses="modal-body-height"
							>
								<template #showImage>
									<b-card-body class="preview-card">
										<b-img
											:src="getMediaPath(item)"
											fluid
											class="gallery-image"
										/>
									</b-card-body>
								</template>
							</BasicModal>
						</div> -->
					</div>
				</b-row>
			</div>
			<BasicModal
				id="preview-image"
				modalTitle="Stories"
				modalBodyClasses="modal-body-height"
			>
				<StorySlider :mediaObj="currentStory" />
			</BasicModal>
		</b-container>
	</b-card>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BTableLite,
	BCardText,
	BButton,
	BAlert,
	BLink,
	BBadge,
	BAvatar,
	BContainer,
	BSpinner,
	VBModal,
	BImg,
} from 'bootstrap-vue'

import LabelText from '../../components/LabelText.vue'
import Button from '../../components/Button.vue'
import ImageGallery from '../../components/ImageGallery.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import { submissionsStatus } from '../../constants'
import { getUserStoryById } from '../../api/api.service'
import { getMediaPath } from '../../utils/mediaUtils'
import { convertDateTime } from '../../utils/dateFormatUtils'
import UserInfo from '@/components/UserInfo.vue'
import moment from 'moment'
import BasicModal from '@/components/BasicModal.vue'
import StorySlider from '@/components/MediaSlider/StorySlider.vue'

export default {
	name: 'UserStoryDetail',
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BLink,
		LabelText,
		BAvatar,
		Button,
		ImageGallery,
		BBadge,
		BContainer,
		BSpinner,
		VBModal,
		ConfirmModal,
		UserInfo,
		BImg,
		BasicModal,
		StorySlider,
	},
	beforeMount() {
		this.isLoading = true
		getUserStoryById(this.$route.params.storyId)
			.then((res) => {
				this.currentStory = res.UserStory
			})
			.finally(() => {
				this.isLoading = false
			})
	},
	methods: {
		formatDate(date) {
			date = moment(date).subtract(1, 'month')
			return convertDateTime({ date, customFormat: 'LL' })
		},
		getMediaPath,
	},
	data() {
		return {
			currentStory: null,
			submissionsStatus,
			isLoading: false,
		}
	},
}
</script>

<style lang="scss">
@import '../~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style scoped>
.additional-notes {
	line-height: 25px;
	color: black;
}
.title {
	color: #888888;
}
.status-pill {
	color: #000000;
	background-color: #28c76f1f;
	padding: 5px 10px 5px 10px;
}

.loader-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 60vh;
}

.loader {
	margin-bottom: 8px;
	width: 4rem;
	height: 4rem;
}

.msg {
	font-size: 20px;
	font-weight: 500;
}
.gallery-image {
	width: 100% !important;
	height: 100% !important;
	object-fit: contain !important;
}
.preview-card {
	height: 100% !important;
}
::v-deep .preview-image-container {
	/* width: 100px !important; */
	/* height: 100px !important; */
}
</style>
