<template>
	<div>
		<div>
			<div class="d-flex" v-if="(banners.length < 5&&!isFetching)">
				<button class="btn btn-primary" @click="handleAddClick">
					<span class="btn-text">Add New Banner</span>
				</button>
			</div>

		</div>

		<b-row class="mt-2" cols="12" v-if="!isFetching">
			<b-col xl="6" lg="6" md="12" sm="auto" v-for="(item, index) in banners">
				<div class="mb-2">
					<b-card class="card banner-card">
						<div class="banner-details-container">
							<div class="d-flex" style="justify-content: space-between; align-items: center;">
								<div class="d-flex">
									<p class="mb-0" style="font-size: 14px;font-weight: bolder;">{{ item.Title }}</p>
								</div>
								<div class="d-flex">
									<img v-b-modal="'user-story-modal'" id="user-story-action-icon"
										@click="handleEditClick(item)" class="delete-icon mr-1" :src="editIcon" />
									<img v-b-modal="'user-story-modal'" id="user-story-action-icon"
										@click="handleDeleteClick(item)" class="delete-icon" :src="deleteIcon" />
								</div>
							</div>
						</div>
						<div class="banner-image-container">
							<img :src="getMediaPath(item.Media)" @error="replaceByDefault" class="banner-image" />
						</div>
						<div>
							<p class="mb-0" style="margin-top: 10px;font-size: 14px;color: #888888;">Associate Shop: <span
									style="font-size: 14px; color: #000;font-weight: bolder;">{{ item.Vendor.CompanyName
									}}</span></p>
						</div>
					</b-card>
				</div>
			</b-col>

		</b-row>

		<ConfirmModal id="delete-banner-modal" :isLoading="isProcessing" :isSuccess="deleteSuccess"
			successText="Banner deleted successfully!" :onOkayPress="handleOkayPress" :onYesPress="handleYesPress"
			messageText="Are you sure you want to delete this banner?" />

		<BasicModal id="create-banner-modal" :modalTitle="isEdit ? 'Edit Banner' : 'Add New Banner'">
			<template #showForm>
				<div>
					<validation-observer ref="validationRules">
						<b-form @submit.prevent="handleSubmit">

							<b-form-group label-cols="4" label="Banner Title:" label-for="banner-title" label-size="md"
								class="mt-1">
								<validation-provider #default="{ errors }" rules="required" name="Banner Title">
									<Input placeholder="Type Banner Title" v-model="bannerTitle" id="banner-title" size="md"
										classes="w-100 banner-title" :errors="errors" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<b-form-group label-cols="4" label-cols-lg="4" label="Associate Shop:" label-for="store-name">
								<v-select :debounce="500" v-model="selected" id="store-name" placeholder="Select Shop"
									@search="handleStoreSearch" label="title" :options="stores" class="store-select-list"/>
							</b-form-group>

							<b-form-group label-cols="4" label="Upload Image" label-for="input-default" label-size="md"
								class="mt-1">
								<validation-provider #default="{ errors }" name="Category Image">
									<FileInput :isRequired="false" :onChange="handleImageChange" v-model="bannerImage"
										placeholderText="Upload Image" class="banner-file-input" :value="bannerImage" :errors="errors" />
									<small class="text-danger">{{ errors[0] }}</small>

								</validation-provider>
								<div v-if="(Object.keys(bannerImage).length)" class="banner-image-container mt-1">
									<img :src="getImage(bannerImage)" @error="replaceByDefault" class="banner-image" />
								</div>
							</b-form-group>

							<div class="d-flex justify-content-end">
								<div class="w-50">
									<Button buttonVariant="primary" :buttonText="isEdit ? 'Save Changes' : 'Add Banner'"
										classes="w-100" type="submit" :isLoading="isLoading" />
								</div>
							</div>
						</b-form>
					</validation-observer>
				</div>
			</template>
		</BasicModal>
	</div>
</template>
  
<script>

import { BRow, BCol, BCard, BSpinner, BModal, BForm, BFormGroup } from "bootstrap-vue";
import { ref } from 'vue-demi'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from 'vue-select'
import Nav from "../../components/Nav.vue";
import ConfirmModal from '../../components/ConfirmModal.vue'
import Button from "../../components/Button.vue";
import DeleteIcon from '../../assets/images/icons/delete.svg'
import EditIcon from '../../assets/images/icons/edit.svg'
import AddImageIcon from '../../assets/images/icons/bxs-image-add.svg'
import { createPromotion, deletePromotion, getPromotions, getStoresListing, updatePromotion } from "@/api/api.service";
import { getMediaPath, hideModal } from "@/utils/commonUtils";
import BasicModal from "../../components/BasicModal.vue";
import FileInput from "../../components/FileInput.vue";
import Input from "../../components/Input.vue";
import showToast from "../../hooks/useToast";
import { finalizeMediaUtil, getMediaStream, initializeMediaUtil, uploadOnS3 } from "@/utils/mediaUtils";

export default {
	name: "StoreBannerList",
	components: {
		BRow,
		BCol,
		BCard,
		BSpinner,
		Nav,
		Button,
		ConfirmModal,
		BModal,
		BasicModal,
		ValidationProvider,
		ValidationObserver,
		BForm,
		BFormGroup,
		Input,
		vSelect,
		FileInput
	},
	setup(props, context) {
		const bannerTitle = ref('')
		const store = ref('')
		const selected = ref('')
		const stores = ref([])
		const bannerImage = ref({})
		const isImageChanged = ref(false)
		const isError = ref(false)
		const isProcessing = ref(false)
		const selectedItem = ref(null)
		const deleteSuccess = ref(false)
		const URL = window.URL

		return {
			getMediaPath,
			getBanners: async function () {
				try {
					this.isFetching = true
					let res = await getPromotions()
					this.banners = [...res.Promotions]
				} catch (err) {
				} finally {
					this.isFetching = false
				}
			},
			handleDeleteClick: function (item) {
				selectedItem.value = item.Id
				deleteSuccess.value = false
				this.$bvModal.show('delete-banner-modal')
			},
			handleAddClick: function () {
				this.isEdit = false;
				selectedItem.value = null
				this.clearForm()
				this.handleStoreSearch(" ")
				this.$bvModal.show("create-banner-modal");
			},
			handleEditClick: function (item) {
				this.isEdit = true;
				bannerTitle.value = item.Title
				store.value = item.Vendor.CompanyName
				selected.value = { value: item.Vendor.Id, title: item.Vendor.CompanyName }
				bannerImage.value = item.Media
				selectedItem.value = item.Id
				this.handleStoreSearch(" ")
				this.$bvModal.show("create-banner-modal");
			},
			handleYesPress: function () {
				isProcessing.value = true
				deletePromotion(selectedItem.value)
					.then((res) => {
						deleteSuccess.value = true
						isProcessing.value = false
					})
					.finally(() => {
						this.$bvModal.hide('delete-banner-modal')
						this.getBanners()
					})
			},
			handleOkayPress: function () {
				selectedItem.value = null
				this.$bvModal.hide('delete-banner-modal')
			},
			handleCloseClick: function () {
				this.$bvModal.hide('create-banner-modal')
			},
			replaceByDefault: function (event) {
				event.target.src = this.placeholderImage
			},
			uploadMedia: async (file) => {
				let credentialsRequest = initializeMediaUtil(file);
				let mediaStreamRequest = getMediaStream(file);
				const [credentials, mediaStream] = await Promise.all([
					credentialsRequest,
					mediaStreamRequest,
				]);
				await uploadOnS3(mediaStream, credentials, progress => {
					progress.value = progress;
				});
				let finalizeRes = await finalizeMediaUtil(credentials?.MediaId);

				return finalizeRes?.Media;
			},
			handleStoreSearch: async (search) => {
				try {
					let result = await getStoresListing({
						...(search && { Q: search }),
						Page: 1,
						Limit: 10,
						Direction: 'DESC',
						Column: 'CreatedAt',
					})
					if (result?.Vendors?.length) {
						let optionForSelect = result.Vendors.map((item) => ({
							title: item?.CompanyName,
							value: item?.Id,
						}))
						stores.value = [...optionForSelect]
					}
				} catch (error) {
				} finally {
				}
			},
			handleImageChange: async (file) => {
				isImageChanged.value = true
			},
			getImage: (bannerImage) => {
				if (isImageChanged.value) {
					return URL.createObjectURL(bannerImage)
				}
				if (bannerImage?.Id) {
					return getMediaPath(bannerImage)
				}
				return URL.createObjectURL(bannerImage)
			},
			handleSubmit: async function () {
				let canSubmit = await this.$refs.validationRules.validate();

				if (!canSubmit) {
					return;
				}
				if (selectedItem.value) {
					this.handleUpdateBanner();
				} else {
					this.handleCreateBanner();
				}
			},
			handleCreateBanner: async function () {
				if (!bannerTitle.value && !selected.value.value && !bannerImage.value) {
					return;
				}

				let Media = null;
				try {
					isError.value && !isError.value;
					this.isLoading = true
					Media = await this.uploadMedia(bannerImage.value);
					let bodyData = {
						Title: bannerTitle.value,
						VendorId: selected.value.value,
						MediaId: Media.Id,
					};
					let uploaded = await createPromotion(bodyData);

					hideModal("create-banner-modal", context);
					this.getBanners()
					showToast(context.root, {
						Title: "Added Successfully",
						Icon: "CheckCircleIcon",
					});

					this.isSuccess = true;
					this.isLoading = false;
					this.clearForm()

				} catch (err) {
					isError.value = true;
					this.isLoading = false;
					showToast(context.root, {
						Title: "Error",
						Icon: "XCircleIcon",
						Text: err.Message,
						Variant: "danger",
					});
					return;
				} finally {
					this.isLoading = false;
					this.isSuccess = false;
				}
			},
			handleUpdateBanner: async function () {
				if (!bannerTitle.value && !selected.value.value && !bannerImage.value) {
					return;
				}

				let Media = null;
				try {
					isError.value && !isError.value;
					this.isLoading = true
					if (isImageChanged.value) {
						Media = await this.uploadMedia(bannerImage.value);
					}
					else {
						Media = bannerImage.value
					}
					let bodyData = {
						Title: bannerTitle.value,
						VendorId: selected.value.value,
						MediaId: Media.Id
					};

					await updatePromotion(selectedItem.value, bodyData);

					hideModal("create-banner-modal", context);
					this.getBanners()
					showToast(context.root, {
						Title: "Updated Successfully",
						Icon: "CheckCircleIcon",
					});

					this.isSuccess = true;
					this.isLoading = false;
					this.clearForm()

				} catch (err) {
					isError.value = true;
					this.isLoading = false;
					showToast(context.root, {
						Title: "Error",
						Icon: "XCircleIcon",
						Text: err.Message,
						Variant: "danger",
					});
					return;
				}
			},
			clearForm: function () {
				bannerTitle.value = ''
				store.value = ''
				selected.value = ''
				stores.value = ''
				bannerImage.value = {}
				isImageChanged.value = false
			},


			banners: [],
			deleteSuccess,
			deleteIcon: DeleteIcon,
			addImageIcon: AddImageIcon,
			isFetching: false,
			editIcon: EditIcon,
			isEdit: false,
			placeholderImage: require('@/assets/images/gallery/placeholder-image.png'),
			isLoading: false,
			bannerTitle,
			bannerImage,
			isError,
			store,
			selected,
			stores,
			isProcessing,
			bannerData: {
				title: "",
				store: {},
				media: {},
			},
			URL,
		}
	},
	created() {
		this.handleStoreSearch(" ")
		this.getBanners()
	},
};
</script>
	
<style scoped>
.banner-title,.banner-title::placeholder{
	color:#6e6b7b !important
}
.card-body {
	padding: 14px !important;
}

.banner-image-container {
	height: 210px;
}

.banner-image {
	width: 100%;
	height: 100%;
	border-radius: 6px;
	object-fit: cover;
	object-position: center;
}

.banner-details-container {
	padding: 0px 5px 10px;
}

.banner-card {
	background-color: #fff;
}
span.d-block.form-file-text{
	font-size: 14px !important;
}


</style>