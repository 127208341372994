export const APP_ROUTES = {
    DASHBOARD: '/dashboard',
    LOGIN: '/auth/login',
    FORGOT_PASSWORD: '/auth/forget-password',
    RESET_PASSWORD: '/auth/reset-password/:tokenUUID',
    // User Management Old
    USER_MANAGEMENT: '/users',
    USER_DETAILS: '/user/:userId',
    USER_REVIEWS: '/user/:userId/reviews',
    USER_MODERATOR: '/moderators',

    USER_FISH_SPOT_DETAILS: '/user/:userId/fish-spot/:fishSpotId',
    USER_FISH_GEAR_DETAILS: '/user/:userId/fish-gear/:gearId',
    FISHING_GUIDE: '/fishing-guide',
    FISHING_GUIDE_DETAILS: '/fishing-guide/:guideId',
    BOOKING_DETAILS: '/fishing-guide/:guideId/booking-details/:bookingId',
    BOOKING_TRIP_DETAILS:
        '/fishing-guide/:guideId/booking-details/:bookingId/trip-details/:tripId',
    TRIP_DETAILS: '/fishing-guide/:guideId/trip-details/:tripId',
    FISH_SPOT: '/fish-spot',
    FISH_SPOT_DETAILS: '/fish-spot/:fishSpotId',
    FISH_GEAR: '/fish-gear',
    FISH_GEAR_DETAILS: '/fish-gear/:gearId',
    ORDERS: '/orders',
    ORDER_DETAILS_LOCATION: '/location-order-details/:orderId',
    ORDER_DETAILS_GUIDE: '/guide-order-details/:orderId',
    // Moderation
    MODERATION: '/moderation',
    MODERATION_USER_STORIES: '/moderation/user-stories',
    MODERATION_MARKETPLACE: '/moderation/marketplace',
    // MODERATION_GROUP_POSTS: '/moderation/group-posts',
    MODERATION_GROUP_POSTS: '/moderation/groups',
    MODERATION_STORES: '/moderation/shops',
    MODERATION_TOURNAMENT: '/moderation/tournament',
    MODERATION_CHATS: '/moderation/chats',
    // Moderaiton Details
    MODERATION_USER_STORIES_DETAILS: '/moderation/user-stories/:moderationId',
    MODERATION_MARKETPLACE_DETAILS: '/moderation/marketplace/:moderationId',
    MODERATION_GROUP_POSTS_DETAILS: '/moderation/groups/:moderationId',
    MODERATION_STORE_DETAILS: '/moderation/shops/:moderationId',
    MODERATION_TOURNAMENT_DETAILS: '/moderation/tournament/:moderationId',
    // Reports
    REPORT_SUBSCRIPTION: '/report/subscription',
    REPORT_TRANSACTION: '/report/transaction',
    REPORT_SOS: '/report/sos',
    // Monthly Submission --> User Stories
    // MONTHLY_SUBMISSIONS: '/monthly-submissions',
    // MONTHLY_SUBMISSIONS_REQUESTS: '/monthly-submissions/requests',
    // MONTHLY_SUBMISSIONS_ACCEPTED: '/monthly-submissions/accepted',
    // MONTHLY_SUBMISSIONS_PREVIOUS: '/monthly-submissions/previous',
    USER_STORIES: '/user-stories',
    RATING_REVIEWS: '/rating-reviews',
    RATING_REVIEWS_DETAILS: '/rating-reviews/:reviewId',
    SETTINGS_CONFIGURATION: '/settings/configuration-settings',
    SETTINGS_FISH_GEAR: '/settings/fishing-gears',
    SETTINGS_FISH_GEAR_LIST: '/settings/fishing-gears/list',
    SETTINGS_FISH_GEAR_CATEGORIES: '/settings/fishing-gears/categories',
    SETTINGS_FISH_SPECIES: '/settings/fish-species',
    SETTINGS_FISH_SPOTS: '/settings/fishing-spots',
    SETTINGS_FISH_GUIDES: '/settings/fishing-guides',
    SETTINGS_FISH_GUIDES_LISTING_TYPE: '/settings/fishing-guides/listing-type',
    SETTINGS_FISH_GUIDES_AMENITIES: '/settings/fishing-guides/amenities',
    SETTINGS_FISH_GUIDES_FACILITIES: '/settings/fishing-guides/facilities',
    SETTINGS_FISH_GUIDES_TECHNIQUES: '/settings/fishing-guides/techniques',
    SETTINGS_FISH_GUIDES_GEARS: '/settings/fishing-guides/gears',
    SETTINGS_FISH_GUIDES_POLICIES: '/settings/fishing-guides/policies',
    COMMUNITY_POSTS: '/community-post',
    CREATE_COMMUNITY_POST: '/community-post/new',
    COMMUNITY_POST_DETAILS: '/community-post/detail/:communityPostId',
    USER_STORY_DETAILS: '/user-story/:storyId',
    REFERRALS: '/referrals',
    // Email
    CREATE_EMAIL_TEMPLATE: '/create-email-template/:templateName',
    EDIT_EMAIL_TEMPLATE: '/edit/:templateName/:templateId',
    EMAIL_TEMPLATE_LISTING: '/email-templates',
    VIEW_EMAIL_TEMPLATE_DETAILS: '/email/:templateId',
    VIEW_EMAIL_TEMPLATE_HISTORY_DETAILS: '/email-history/:historyTemplateId',
    EMAILS_HISTORY_LISTING: '/emails-history',
    // Groups
    GROUPS_LISTING: '/groups',
    GROUP_DETAILS: '/group/:groupId',
    GROUP_POST_DETAILS: '/group/post/:postId',
    //Stores
    STORES_LISTING: '/shop-management',
    STORE_CATEGORIES: '/shop-categories',
    STORE_DETAILS: '/shop-management/:storeId',
    STORE_BANNERS: '/shop-banners',
    STORE_ORDER_HISTORY: '/shop-order-history',
    STORE_ORDER_HISTORY_DETAILS: '/shop-order-history/:orderId',

    // Tournaments
    TOURNAMENTS_LISTING: '/tournaments',
    TOURNAMENT_DETAILS: '/tournament/:tournamentId',
}

export const getRoute = (route, obj = {}) => {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj)
        objectKeys.forEach((item) => {
            route = route.replace(new RegExp(/:([\d\w])+/, 'i'), (match) => {
                let formattedMatchedValue = match.slice(1)
                return obj[formattedMatchedValue]
            })
        })
        return route
    }
    return route
}
