import { UserRoles } from '../../constants'

let { Admin, Customer, Guest, Moderator, Vendor } = UserRoles

export default [
    {
        title: 'Dashboard',
        route: 'home',
        icon: 'HomeIcon',
        img: require('../../assets/images/icons/dashboard-icon.svg'),
        activeIcon: require('../../assets/images/icons/dashboard-white-icon.svg'),
        permissions: [Admin, Customer, Guest, Vendor],
    },

    {
        title: 'User Management',
        // route: 'user-management',
        icon: 'UsersIcon',
        img: require('../../assets/images/icons/users-icon.svg'),
        activeIcon: require('../../assets/images/icons/users-icon.svg'),
        children: [
            {
                title: 'Users',
                route: 'user-management',
            },
            {
                title: 'Moderator',
                route: 'moderators',
            },
        ],
        permissions: [Admin, Customer, Guest, Vendor],
    },
    {
        title: 'Fishing Guides',
        route: 'fish-guide',
        icon: 'AnchorIcon',
        img: require('../../assets/images/icons/anchor-icon.svg'),
        activeIcon: require('../../assets/images/icons/fishing-guide-white-icon.svg'),
        permissions: [Admin, Customer, Guest, Vendor],
    },
    {
        title: 'Fishing Spots',
        route: 'fish-spot',
        icon: '',
        img: require('../../assets/images/icons/boat-icon.svg'),
        activeIcon: require('../../assets/images/icons/fishing-spot-white.svg'),
        permissions: [Admin, Customer, Guest, Vendor],
    },
    {
        title: 'Fishing Gears',
        route: 'fish-gear',
        icon: '',
        img: require('../../assets/images/icons/gear-icon.svg'),
        activeIcon: require('../../assets/images/icons/fishing-gear-white.svg'),
        permissions: [Admin, Customer, Guest, Vendor],
    },
    {
        title: 'Order Listing',
        route: 'order-listing',
        icon: 'ListIcon',
        img: require('../../assets/images/icons/list-icon.svg'),
        activeIcon: require('../../assets/images/icons/list-white-icon.svg'),
        permissions: [Admin, Customer, Guest, Vendor],
    },
    // Monthly Submission -> Now User Stories
    // {
    //   title: 'Monthly Submissions',
    //   route: 'monthly-submissions',
    //   icon: 'ImageIcon',
    //   img: require('../../assets/images/icons/monthly-subscription-icon.svg'),
    //   activeIcon: require('../../assets/images/icons/monthly-subscription-white.svg'),
    // },

    {
        title: 'User Stories',
        route: 'user-stories',
        icon: 'ImageIcon',
        img: require('../../assets/images/icons/user-stories.svg'),
        activeIcon: require('../../assets/images/icons/user-stories-white.svg'),
        permissions: [Admin, Customer, Guest, Vendor],
    },
    {
        title: 'Rating & Reviews',
        route: 'rating-reviews',
        icon: 'StarIcon',
        img: require('../../assets/images/icons/rating-star-icon.svg'),
        activeIcon: require('../../assets/images/icons/star-white.svg'),
        permissions: [Admin, Customer, Guest, Vendor],
    },
    {
        title: 'Reports',
        icon: 'StarIcon',
        img: require('../../assets/images/icons/report-icon.svg'),
        activeIcon: require('../../assets/images/icons/report-icon.svg'),
        children: [
            {
                title: 'Subscription Earning',
                route: 'report-subscription',
            },
            {
                title: 'Transaction Earning',
                route: 'report-transaction',
            },
            {
                title: 'SOS',
                route: 'report-sos',
            },
        ],
        permissions: [Admin, Customer, Guest, Vendor],
    },
    // Settings
    {
        title: 'Settings',
        icon: '',
        img: require('../../assets/images/icons/settings-icon.svg'),
        activeIcon: require('../../assets/images/icons/settings-icon.svg'),
        children: [
            {
                title: 'Configuration Settings',
                route: 'configuration-settings',
            },
            {
                title: 'Fishing Gears',
                route: 'fishing-gears',
            },
            {
                title: 'Fish Species',
                route: 'fish-species',
            },

            {
                title: 'Fishing Spots',
                route: 'fishing-spots',
            },
            {
                title: 'Fishing Guides',
                route: 'fishing-guides',
            },
        ],
        permissions: [Admin, Customer, Guest, Vendor],
    },
    {
        title: 'Moderation',
        route: 'moderation',
        icon: '',
        img: require('../../assets/images/icons/moderation-icon.svg'),
        activeIcon: require('../../assets/images/icons/moderation-white.svg'),
        permissions: [Admin, Customer, Guest, Vendor],
    },
    {
        title: 'Community Posts',
        route: 'community-post',
        icon: '',
        img: require('../../assets/images/icons/posts-icon.svg'),
        activeIcon: require('../../assets/images/icons/community-post-white.svg'),
        permissions: [Admin, Customer, Guest, Vendor, Moderator],
    },

    {
        title: 'Email',
        icon: '',
        img: require('../../assets/images/icons/mail-icon.svg'),
        activeIcon: require('../../assets/images/icons/mail-icon.svg'),
        children: [
            {
                title: 'Email Templates',
                route: 'email-templates',
            },
            {
                title: 'Email History',
                route: 'emails-history',
            },
        ],
        permissions: [Admin, Customer, Guest, Vendor, Moderator],
    },

    // Referral Code Section

    {
        title: 'Generate Referrals',
        route: 'referrals',
        icon: '',
        img: require('../../assets/images/icons/referral-icon.svg'),
        activeIcon: require('../../assets/images/icons/referral-icon-white.svg'),
        permissions: [Admin, Customer, Guest, Vendor],
    },

    // Groups
    {
        title: 'Groups Management',
        route: 'groups',
        icon: '',
        img: require('../../assets/images/icons/group-icon.svg'),
        activeIcon: require('../../assets/images/icons/group-icon-white.svg'),
        permissions: [Admin, Customer, Guest, Vendor],
    },
    {
        title: 'Shops',
        icon: '',
        img: require('../../assets/images/icons/store.svg'),
        activeIcon: require('../../assets/images/icons/store.svg'),
        children: [
            {
                title: 'Shops Management',
                route: 'store-management',
            },
            {
                title: 'Categories',
                route: 'shop-categories',
            },
            {
                title: 'Banners',
                route: 'shop-banners',
            },
            {
                title: 'Order History',
                route: 'shop-order-history',
            },
        ],
        permissions: [Admin],
    },
    // Tournaments
    {
        title: 'Tournaments',
        route: 'tournaments',
        icon: '',
        img: require('../../assets/images/icons/tournaments-icon.svg'),
        activeIcon: require('../../assets/images/icons/tournaments-icon-white.svg'),
        permissions: [Admin],
    },
]
