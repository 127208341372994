import {
  getUserList,
  getUserCatalogList,
  getUserReviewList,
  updateUser,
} from "../../api/api.service";
import config from "../../config";
export default {
  namespaced: true,
  state: {
    fetching: false,
    list: [],
    totalUsers: 0,
    catalog: {
      fetching: false,
      list: [],
      totalCatalogs: 0,
    },
    review: {
      fetching: false,
      list: [],
      totalReviews: 0,
      dataAvailable: false,
    },
  },
  getters: {
    getUserById: (state) => (id) => state.list.find((user) => user.Id === id),
    getCatalogList: (state) =>
      state.catalog.list.map((item) => item.Gear || item.Location),
    getReviewList: (state) => {
      return state.review.list.map((item) => {
        let Title = null;
        let TripName = null;
        if (item.GuideTrip) {
          Title = item.GuideTrip.Guide?.BusinessName;
          TripName = item.GuideTrip.Title;
        } else {
          Title = item.Location.Title || item.Location.Address;
        }
        return { ...item, Price: `$${item.Price}`, Title, TripName };
      });
    },
  },
  actions: {
    async getUserListAction({ commit }, params = {}) {
      const { signal, ...queryParams } = params;
      commit("userListRequest");
      try {
        let userResponse = await getUserList(queryParams, signal);
        commit("userListSuccess", {
          payload: userResponse.Users,
          totalUsers: userResponse.TotalUsers,
        });
      } catch (err) {
        if (err.name !== "AbortError") {
          commit("userListFail");
        }
      }
    },
    async getUserCatalogListAction({ commit }, params = {}) {
      const { signal, ...queryParams } = params;
      commit("userCatalogListRequest");
      try {
        let catalogResponse = await getUserCatalogList(queryParams, signal);
        commit("userCatalogListSuccess", {
          payload: catalogResponse.Catalog,
          totalCatalogs: catalogResponse.TotalCatalogs,
        });
      } catch (err) {
        commit("userCatalogListFail");
      }
    },
    async getUserReviewListAction({ commit }, params = {}) {
      const { signal, ...queryParams } = params;
      commit("userReviewListRequest", { Page: params.Page });
      try {
        let reviewResponse = await getUserReviewList(queryParams, signal);
        commit("userReviewListSuccess", {
          payload: reviewResponse.Reviews,
          totalReviews: reviewResponse.TotalReview,
        });
      } catch (err) {
        commit("userReviewListFail");
      }
    },

    async updateUserStatus({ commit }, { id, status }) {
      try {
        let response = await updateUser(id, { Status: status });
        commit("userUpdateSuccess", {
          userId: id,
          status: status,
        });
      } catch (err) {
        console.log(err.Message);
      }
    },
  },
  mutations: {
    userListRequest: (state) => {
      state.fetching = true;
      state.list = [];
    },
    userListSuccess: (state, action) => {
      state.fetching = false;
      state.list = action.payload;
      state.totalUsers = action.totalUsers;
    },
    userListFail: (state, payload) => {
      state.fetching = false;
    },
    userCatalogListRequest: (state) => {
      state.catalog.fetching = true;
      state.catalog.list = [];
    },
    userCatalogListSuccess: (state, action) => {
      state.catalog.fetching = false;
      state.catalog.list = action.payload;
      state.catalog.totalCatalogs = action.totalCatalogs;
    },
    userCatalogListFail: (state, payload) => {
      state.catalog.fetching = false;
    },
    userReviewListRequest: (state, action) => {
      state.review.fetching = true;
      state.review.list = (() => {
        if (!action.Page || action.Page === 1) {
          return [];
        }
        return state.review.list;
      })();
    },
    userReviewListSuccess: (state, action) => {
      state.review.fetching = false;
      state.review.list = [...state.review.list, ...action.payload];
      state.review.totalReviews = action.totalReviews;
      state.review.dataAvailable =
        action.payload.length === Number(config.LIMIT);
    },
    userReviewListFail: (state, payload) => {
      state.review.fetching = false;
    },
    userUpdateSuccess: (state, action) => {
      state.list = state.list.map((item) => {
        if (item.Id === action.userId) {
          item.Status = action.status;
        }
        return item;
      });
    },
  },
};
