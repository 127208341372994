<template>
	<div class="earning-card">
		<span class="font-weight-bolder amount-text">{{ count }}</span>
		<span class="font-weight-bolder amount-description">{{ text }}</span>
	</div>
</template>

<script>
export default {
	name: 'PhotoSelectedText',
	props: {
		count: {
			type: Number,
			default: '-',
		},
		text: {
			type: String,
			default: 'Photos selected for this month',
		},
	},
}
</script>

<style scoped>
@media only screen and (max-width: 576px) {
	.earning-card {
		width: 100% !important;
	}
}
@media only screen and (max-width: 768px) {
	.earning-card {
		width: 100% !important;
		margin-top: 10px !important;
	}
}
.earning-card {
	padding: 6px 6px !important;
	border: 1px solid #3599cd !important;
	border-radius: 0.5rem !important;
	background-color: #f2fbff;
}

::v-deep .amount-text {
	color: #003071;
	margin-left: 10px !important;
}

.amount-description {
	margin-left: 10px !important;
	margin-right: 4px !important;
}
</style>