<template>
	<b-card class="card-container mt-2 mb-2 mr-2">
		<div v-on:click="handleClick($event, item)">
			<div class="file-icon-container">
				<feather-icon icon="FileIcon" size="52" />
			</div>
			<div class="file-meta-container">
				<p class="file-title">{{ trimLargeString("Legal Documents", 12) }}</p>
				<p class="file-size mb-0">{{ formatBytes(item.Meta.Size) }}</p>
			</div>
		</div>
	</b-card>
</template>

<script>

import { BCard, BImg, BCardBody, BCardHeader, BCardFooter } from 'bootstrap-vue'

import DeleteIcon from '../assets/images/icons/delete.svg'

import UserInfo from './UserInfo.vue'
import Button from './Button.vue'
import GroupedAvatar from './GroupedAvatar.vue'
import { formatBytes } from '@/utils/mediaUtils'
import { trimLargeString } from '@/utils//trimLargeString'

export default {
	name: 'SubmissionView',
	components: {
		BCard,
		BImg,
		UserInfo,
		Button,
		GroupedAvatar,
		BCardBody,
		BCardHeader,
		BCardFooter,
	},
	props: {
		item: {
			type: Object,
			default: {}
		},

	},
	data() {
		return {
			deleteIcon: DeleteIcon,
		}
	},
	methods: {
		trimLargeString,
		handleClick(e, item) {
			console.log(e, item)
			const fileUrl = item.BasePath + item.Path
			if (item.Meta.Extension.toLowerCase() === 'pdf' || item.Meta.Extension.toLowerCase() === 'docx') {
				window.open(fileUrl, '_blank')
			} else {
				window.open(fileUrl, '_blank')
			}
		},
		formatBytes
	}
}
</script>

<style scoped>
/* .view-card {
  width: 308px;
} */
::v-deep.thumbnail-image {
	width: 100% !important;
	height: 100% !important;
	object-fit: cover !important;
}

.sub-text {
	color: black;
}

.file-meta-container {
	padding: 0 8px
}

.file-title {
	color: black;
	font-size: 14px;
	font-weight: bold !important;
	margin: 4px 0;
}

.file-size {
	color: #888888;
	font-size: 12px
}

::v-deep.card-container {
	width: 140px !important;
	height: 170px !important;
	align-items: center;
}

.card-container>.card-body {
	padding: 0 !important;
	width: 100%;
	border: 1px solid #EBE9F1 !important;
	border-radius: 6px;
}

.file-icon-container {
	background-color: #F8F8F8;
	height: 116px !important;
	padding: 0 !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

::v-deep.shrink-height-img {
	height: 250px !important;
}

::v-deep.full-height-img {
	height: 300px !important;
}

.delete-icon {
	width: 18px;
	height: 18px;
	cursor: pointer;
	margin-top: 18px;
}
</style>