<template>
	<div>
		<b-card body-class="card-body-style">
			<SearchHeader
				selectPlaceholder="Select Category"
				:statusOptions="getStatusOptions(purchaseType)"
				:filterValue="filters.Status"
				:onFilterChange="handleFilterChange"
				:searchText="searchText"
				:onSearchChange="handleSearchChange"
				:pageLimitValue="pageLimit"
				:onPageLimitChange="handlePageLimit"
			/>

			<DataTable
				:perPageLimit="pageLimit"
				entriesName="Orders"
				:isLoading="fetching"
				:config="config"
				:data="data"
				:perPage="data.length"
				:selectedPageValue="page"
				:onHandlePageChange="handlePageClick"
				:totalRecords="totalOrders"
				:onSortClick="handleSortChange"
				:currentSortOrder="sortOrder"
				:currentSortColumn="sortColumn"
			>
				<template #cell(No)="data">
					<span class="font-weight-bolder">
						{{ data.item.Id }}
					</span>
				</template>

				<template #cell(Listing)="data">
					<span class="font-weight-bolder">
						{{
							data.item.Location
								? data.item.Location.Title || '-'
								: (data.item.GuideTrip &&
										data.item.GuideTrip.Title) ||
								  '-'
						}}
					</span>
					<br />
					<span class="light-gray">
						{{
							data.item.GuideTrip && data.item.GuideTrip.StartDate
						}}
					</span>
				</template>

				<template #cell(CustomerName)="data">
					<span class="text-nowrap">
						<user-info
							iconSize="md"
							:fullName="data.item.User.FullName || '-'"
							:avatarImage="getMediaPath(data.item.User.Media)"
							nameClasses="black"
						/>
					</span>
				</template>

				<template #cell(PurchaseDate)="data">
					<span class="purchase-date">
						{{
							convertDateTime({
								date: data.item.PurchaseDate,
								customFormat: dateTimeFormat.appDateFormat,
								dateOnly: true,
							})
						}}
					</span>
				</template>

				<template #cell(EntityType)="data">
					<span class="category">
						{{
							purchaseType.getDisplayTextKey(
								data.item.EntityType
							) || '-'
						}}
					</span>
				</template>

				<template #cell(Loc)="data">
					<div class="font-weight-bolder" v-if="data.item.Location">
						<span>
							{{
								data.item.Location.Country
									? data.item.Location.Country
									: '-'
							}}
						</span>
						<br />
						<span class="light-gray font-weight-lighter">{{
							`${data.item.Location.Location.coordinates[0]} - ${data.item.Location.Location.coordinates[1]}`
						}}</span>
					</div>
					<div
						class="font-weight-bolder"
						v-else-if="data.item.GuideTrip"
					>
						<span>
							{{
								data.item.GuideTrip
									? data.item.GuideTrip.Guide.CountryCodeAlpha
									: '-'
							}}
						</span>
						<br />
						<span class="light-gray font-weight-lighter">{{
							`${data.item.GuideTrip.Guide.Location.coordinates[0]} - ${data.item.GuideTrip.Guide.Location.coordinates[1]}`
						}}</span>
					</div>
				</template>

				<template #cell(SellerName)="data">
					<span class="text-nowrap">
						<user-info
							iconSize="md"
							:fullName="
								data.item.Location
									? data.item.Location.User.FullName || '-'
									: data.item.GuideTrip
									? data.item.GuideTrip.Guide.User.FullName ||
									  '-'
									: '-'
							"
							:avatarImage="
								data.item.Location
									? getMediaPath(
											data.item.Location.User.Media
									  ) || null
									: data.item.GuideTrip
									? getMediaPath(
											data.item.GuideTrip.Guide.User.Media
									  ) || null
									: null
							"
							:subText="
								data.item.Location
									? data.item.Location.User.PhoneNumber || '-'
									: data.item.GuideTrip
									? data.item.GuideTrip.Guide.User
											.PhoneNumber || '-'
									: '-'
							"
						/>
					</span>
				</template>

				<template #cell(NetAmount)="data">
					<span class="price"
						>$ {{ data.item.NetAmount || '-' }}
					</span>
				</template>

				<template #cell(Status)="data">
					<template>
						<b-badge
							pill
							:variant="
								TransactionStatus.getLabelClass(
									data.item.Status
								)
							"
						>
							{{
								TransactionStatus.getDisplayTextKey(
									data.item.Status
								)
							}}
						</b-badge>
					</template>
				</template>

				<template #cell(Actions)="data">
					<div class="text-nowrap">
						<feather-icon
							:id="`order-list-row-${data.item.Id}-detail-icon`"
							icon="EyeIcon"
							size="16"
							class="mx-1 detail-icon cursor-pointer"
							v-on:click="viewDetails(data.item)"
						/>
						<b-tooltip
							title="Detail"
							:target="`order-list-row-${data.item.Id}-detail-icon`"
						/>
					</div>
				</template>
			</DataTable>
		</b-card>
	</div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from 'bootstrap-vue'
import { computed, reactive } from '@vue/composition-api'

import { APP_ROUTES, getRoute } from '../../helpers/routeHelpers'
import { purchaseType, SortOrder } from '../../constants'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import { getMediaPath, getStatusOptions } from '../../utils/commonUtils'
import { useStore } from '../../store'
import { usePaginatedRequest } from '../../hooks/usePaginatedRequest'
import { changeGuideStatus } from '../../api/api.service'
import { BaseEntityType, TransactionStatus } from '../../constants'
import DataTable from '../../components/data-table/DataTable.vue'
import UserInfo from '../../components/UserInfo.vue'
import SearchHeader from '../../components/SearchHeader.vue'

export default {
	name: 'OrderListing',
	components: {
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		DataTable,
		UserInfo,
		SearchHeader,
	},
	setup(props, context) {
		const store = useStore()

		let fetching = computed(() => store.state.orderListing.fetching)
		let data = computed(() => store.state.orderListing.ordersList)
		let totalOrders = computed(() => store.state.orderListing.totalOrders)

		let config = [
			{
				label: 'No',
				key: 'No',
			},
			{
				label: 'LISTING',
				key: 'Listing',
			},
			{
				label: 'CUSTOMER NAME',
				key: 'CustomerName',
			},
			{
				label: 'PURCHASE DATE',
				key: 'PurchaseDate',
				isSortable: true,
			},
			{
				label: 'Category',
				key: 'EntityType',
				isSortable: true,
			},
			{
				label: 'Location',
				key: 'Loc',
			},
			{
				label: 'SELLER',
				key: 'SellerName',
			},
			{
				label: 'Price',
				key: 'NetAmount',
				isSortable: true,
			},
			{
				label: 'STATUS',
				key: 'Status',
			},
			{
				label: 'ACTIONS',
				key: 'Actions',
			},
		].map((item) => {
			return { ...item, tdClass: 'table-row', thClass: 'table-header' }
		})

		const filters = reactive({ Status: null })

		const {
			page,
			pageLimit,
			searchText,
			sortOrder,
			sortColumn,
			handleSearchChange,
			handlePageClick,
			handlePageLimit,
			handleSortChange,
		} = usePaginatedRequest(
			'orderListing/getOrdersListAction',
			() => ({
				Direction: SortOrder.DESC,
				Column: 'EntityType',
				...(filters.Status && { Status: filters.Status.value }),
			}),
			[filters]
		)

		function viewDetails(orderObj) {
			let route, id
			if (orderObj.EntityType && orderObj.EntityType === 5) {
				id = orderObj.Id
				route = getRoute(APP_ROUTES.ORDER_DETAILS_LOCATION, {
					orderId: id,
				})
			} else if (orderObj.EntityType && orderObj.EntityType === 6) {
				id = orderObj.Id
				route = getRoute(APP_ROUTES.ORDER_DETAILS_GUIDE, {
					orderId: id,
				})
			}

			context.root.$router.push(route)
		}

		function handleFilterChange(val) {
			filters.Status = val
		}

		return {
			config,
			dateTimeFormat,
			data,
			fetching,
			totalOrders,
			searchText,
			page,
			filters,
			sortOrder,
			sortColumn,
			purchaseType,
			pageLimit,
			handlePageLimit,
			handlePageClick,
			getStatusOptions,
			changeGuideStatus,
			handleSortChange,
			handleSortChange,
			convertDateTime,
			handleSearchChange,
			handleFilterChange,
			viewDetails,
			getMediaPath,
			TransactionStatus,
		}
	},
}
</script>

<style scoped>
::v-deep .detail-icon {
	color: #888888;
}

.card-body-style {
	padding: 0 !important;
}

.light-gray {
	color: #888888;
}

::v-deep .black {
	color: #000 !important;
}

.purchase-date {
	font-weight: 500;
}

.category {
	font-weight: bold;
}

.price {
	font-weight: bold;
}
</style>
