import store from '../store'
import { fetchUtil } from '../utils/fetchUtil'
import { appendQueryParams } from '../utils/urlUtils'

export const Get = ({
    url = '',
    params = {},
    isAuthorized = true,
    abortSignal = null,
}) => {
    let token = null
    if (isAuthorized) {
        token = store.state.auth.user.Token
    }

    return fetchUtil({
        url: appendQueryParams(url, params),
        token,
        abortSignal,
    })
        .then((res) => {
            return Promise.resolve(res)
        })
        .catch((err) => {
            return Promise.reject(err)
        })
}

export const Post = ({
    url,
    body = {},
    isAuthorized = true,
    abortSignal = null,
}) => {
    let token = null
    if (isAuthorized) {
        token = store.state.auth.user.Token
    }
    return fetchUtil({
        url,
        token,
        body: JSON.stringify(body),
        method: 'POST',
        abortSignal,
    })
        .then((res) => {
            return Promise.resolve(res)
        })
        .catch((err) => {
            return Promise.reject(err)
        })
}

export const Put = ({
    url = '',
    body = {},
    isAuthorized = true,
    abortSignal = null,
}) => {
    let token = null
    if (isAuthorized) {
        token = store.state.auth.user.Token
    }
    return fetchUtil({
        url,
        token,
        body: JSON.stringify(body),
        method: 'PUT',
        abortSignal,
    })
        .then((res) => {
            return Promise.resolve(res)
        })
        .catch((err) => {
            return Promise.reject(err)
        })
}

export const Delete = ({
    url = '',
    body = {},
    isAuthorized = true,
    abortSignal = null,
}) => {
    let token = null
    if (isAuthorized) {
        token = store.state.auth.user.Token
    }
    return fetchUtil({
        url,
        token,
        body: JSON.stringify(body),
        method: 'DELETE',
        abortSignal,
    })
        .then((res) => {
            return Promise.resolve(res)
        })
        .catch((err) => {
            return Promise.reject(err)
        })
}
