export const LOGIN = 'login'
export const SET_TOKEN = 'setToken'
export const SET_USER = 'setUser'
export const GET_ME = 'getMe'
export const ME_SUCCESS = 'meSuccess'
export const SIGN_OUT = 'signOut'

import { getMe, login } from '../../api/api.service'
import { SET_DEVICE_ID } from '../../store/deviceId'

export const initialState = {
    isAuthenticated: false,
    user: {},
}

export default {
    state: initialState,
    actions: {
        [GET_ME]: async ({ commit }) => {
            try {
                const res = await getMe()
                commit(ME_SUCCESS, res.User)
                return Promise.resolve(res)
            } catch (err) {
                return Promise.reject(err)
            }
        },
        [LOGIN]: async ({ commit }, payload) => {
            try {
                const res = await login(payload)
                commit(SET_DEVICE_ID, res.DeviceUUID)
                commit(SET_USER, res.User)
                commit(SET_TOKEN, res.Token)
                return Promise.resolve(res)
            } catch (err) {
                return Promise.reject(err)
            }
        },
    },
    getters: {
        getUserType: (state) => {
            return state.user.Type
        },
    },
    mutations: {
        [SET_TOKEN]: (state, payload) => {
            state.user.Token = payload
            state.isAuthenticated = true
            localStorage.isAuthenticated = true
        },
        [SET_USER]: (state, payload) => {
            state.user = payload
        },
        [ME_SUCCESS]: (state, payload) => {
            state.user = { ...state.user, ...payload }
        },
    },
}
