<template>
	<BasicModal
		id="add-moderator-modal"
		modalTitle="Add New Moderator"
		:onClose="handleModalClose"
	>
		<div>
			<validation-observer ref="validationRules">
				<b-form @submit.prevent="validateForm">
					<b-form-group
						label-cols="4"
						label="Name:"
						label-for="input-default"
						label-size="md"
						class="mt-2"
					>
						<validation-provider
							#default="{ errors }"
							rules="required|regex:^[a-zA-Z0-9 ]*$"
							name="Name"
						>
							<Input
								placeholder="Type  name"
								id="input-default"
								size="md"
								classes="w-100"
								v-model="moderatorName"
								:errors="errors"
							/>

							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group
						label-cols="4"
						label="Email Address:"
						label-for="input-default"
						label-size="md"
						class="mt-2"
					>
						<validation-provider
							#default="{ errors }"
							rules="required|email"
							name="Email Address"
						>
							<Input
								placeholder="Type  email"
								id="input-default"
								size="md"
								classes="w-100"
								v-model="moderatorEmail"
								:errors="errors"
							/>

							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group
						label-cols="4"
						label="Password:"
						label-for="input-default"
						label-size="md"
						class="mt-2"
					>
						<validation-provider
							#default="{ errors }"
							rules="required|min:5|max:30|"
							name="Password"
						>
							<Input
								placeholder="Type  password"
								id="input-default"
								size="md"
								classes="w-100"
								v-model="moderatorPassword"
								:errors="errors"
							/>

							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<div class="text-right mt-3">
						<Button
							buttonVariant="primary"
							buttonText="Add Moderator"
							type="submit"
							classes="w-50"
							:isLoading="isLoading"
						/>
					</div>
				</b-form>
			</validation-observer>
		</div>
	</BasicModal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, password, min, max } from '@validations'
import {
	BCard,
	BCardBody,
	BBadge,
	BLink,
	BTooltip,
	BFormInput,
	BFormGroup,
	BForm,
	VBTooltip,
	VBModal,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Button from '@/components/Button.vue'
import BasicModal from '@/components/BasicModal.vue'
import Input from '@/components/Input.vue'

export default {
	name: 'AddModerator',
	props: ['isLoading', 'onValidSubmit'],
	components: {
		BForm,
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		BFormInput,
		Button,
		BasicModal,
		BFormGroup,
		Input,
		VBTooltip,
		ValidationProvider,
		ValidationObserver,
		VBModal,
	},

	setup(props, context) {
		const moderatorName = ref('')
		const moderatorEmail = ref('')
		const moderatorPassword = ref('')

		async function validateForm() {
			let canSubmit = await this.$refs.validationRules.validate()
			if (!canSubmit) {
				return
			}
			let payload = {
				Email: moderatorEmail.value,
				Password: moderatorPassword.value,
				FullName: moderatorName.value,
			}
			this.onValidSubmit(payload)
		}

		const handleModalClose = () => {
			moderatorName.value = ''
			moderatorEmail.value = ''
			moderatorPassword.value = ''
		}

		return {
			required,
			email,
			password,
			min,
			max,
			validateForm,
			handleModalClose,
			moderatorName,
			moderatorEmail,
			moderatorPassword,
		}
	},
}
</script>
