<template>
  <div :class="`container ${containerClasses}`" v-if="imageToPreview">
    <!-- Default Badge -->
    <div
      class="default-badge-container d-flex justify-content-center"
      v-if="isDefault"
    >
      <h5 class="default-text">Default</h5>
    </div>
    <!-- Make Default Badge -->
    <button
      class="make-default-button"
      v-if="isMakeDefault"
      @click="makeDefaultClickHandler"
    >
      Make Default
    </button>
    <!-- Cross Button -->
    <img
      :class="`cross-button ${crossClasses}`"
      :src="img"
      @click="crossHandler"
    />
    <!-- Image -->
    <img
      :src="localFile ? imageBase64 : imageToPreview"
      :class="`image ${imageClasses}`"
    />
  </div>
</template>

<script>
import { toBase64 } from "@/utils/mediaUtils";
export default {
  name: "PreviewImage",
  data() {
    return {
      imageBase64: "",
      img: require("@/assets/images/icons/img-preview-cross.png"),
    };
  },
  mounted() {
    if (this.localFile) {
      this.convertToBase64();
    }
  },
  updated(){
     if (this.localFile) {
      this.convertToBase64();
    }
  },
  methods: {
    async convertToBase64() {
      this.imageBase64 = await toBase64(this.imageToPreview);
    },
  },
  props: {
    imageToPreview: {
      type: String | Object,
      default:
        "https://fishfin-dev.s3.amazonaws.com/data/2022/2/1/1643698847764764-70c28569-7391-4821-9778-841eeb9d136b.jpg",
    },

    crossHandler: {
      type: Function,
    },
    imageClasses: {
      type: String,
      default: "",
    },
    localFile: {
      type: Boolean,
      default: false,
    },
    containerClasses: {
      type: String,
      default: "",
    },

    crossClasses: {
      type: String,
      default: "",
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
    isMakeDefault: {
      type: Boolean,
      default: false,
    },
    makeDefaultClickHandler: {
      type: Function,
    },
  },
};
</script>

<style scoped>
.container {
  width: 200px;
  height: 95px;
  position: relative;
  /* right: 60px; */
  margin-top: 10px;
}

.cross-button {
  cursor: pointer;
  width: 15px;
  height: 15px;
  background-color: white;
  position: absolute;
  right: 20px;
  top: 5px;
  border-radius: 50px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.default-badge-container {
  width: 74px;
  height: 25px;
  background: #ffffff;
  position: absolute;
  bottom: 5px;
  border-radius: 5px;
  margin: 5px;
}
.default-text {
  letter-spacing: 0.24px;
  color: #3599cd;
  opacity: 1;
  font-size: 12px;
  margin-top: 5px;
}

.make-default-button {
  width: 112px;
  height: 25px;
  background: transparent;
  position: absolute;
  bottom: 5px;
  border-radius: 5px;
  border: 1px solid white;
  margin: 5px;
  letter-spacing: 0.24px;
  color: white;
  opacity: 1;
  font-size: 12px;
}

/*Note: To give image rounded borders as we have in community post*/
.rounded-img-container {
  border-radius: 6px !important;
}
</style>