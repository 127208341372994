import { Get, Put, Post, Delete } from './api.helper'

import store from '../store'
import { SortOrder } from '@/constants'
import Config from '@/config'

// Auth
export const login = (bodyData) => {
    const deviceUUID = store.state.deviceId.deviceUUID

    return Post({
        url: '/user/login/admin',
        body: { ...bodyData, ...(deviceUUID && { DeviceUUID: deviceUUID }) },
        isAuthorized: false,
    })
}

export const getMe = () => {
    return Get({
        url: '/user/me',
    })
}

export const getGuideList = (params = {}, abortSignal = null) => {
    return Get({
        url: '/guide',
        params,
        abortSignal,
    })
}

export const getUserList = (params = {}, abortSignal = null) => {
    return Get({
        url: '/user/list',
        params,
        abortSignal,
    })
}

export const getUserDetail = (id) => {
    return Get({
        url: `/user/detail/${id}`,
    })
}

export const getUserCatalogList = (params = {}, abortSignal = null) => {
    return Get({
        url: '/catalog',
        params,
        abortSignal,
    })
}

export const getUserReviewList = (params = {}, abortSignal = null) => {
    return Get({
        url: '/review',
        params,
        abortSignal,
    })
}

export const getAGuideDetail = (id) => {
    return Get({
        url: `/guide/${id}`,
    })
}

export const verifyAGuide = (id, bodyData) => {
    return Put({
        url: `/guide/${id}`,
        body: bodyData,
    })
}

export const changeGuideStatus = (id, bodyData) => {
    return Put({
        url: `/guide/${id}`,
        body: bodyData,
    })
}

export const updateUser = (id, bodyData) => {
    return Put({
        url: `/user/customer/${id}`,
        body: bodyData,
    })
}

export const getAGuideTripsList = (params = {}, abortSignal = null) => {
    return Get({
        url: '/guide/trip',
        params,
        abortSignal,
    })
}

export const getATripDetail = (id) => {
    return Get({
        url: `/guide/trip/${id}`,
    })
}

export const deleteEntireTrip = (id) => {
    return Delete({
        url: `/guide/trip/${id}`,
    })
}

export const getTripBookingsList = (params) => {
    return Get({
        url: '/guide/trip/bookings/',
        params,
    })
}

export const getTripBookingsDetails = (id) => {
    return Get({
        url: '/guide/trip/booking/' + id,
    })
}

export const cancleATrip = (id, bodyData) => {
    return Put({
        url: `/guide/trip/${id}/booking/cancel`,
        body: bodyData,
    })
}

export const getGuideEarnings = (params) => {
    return Get({
        url: '/guide/analytics/earnings',
        params,
    })
}

export const forgotPassword = (payload) => {
    return Post({
        url: '/user/forget-password',
        body: payload,
        isAuthorized: false,
    })
        .then((res) => {
            return Promise.resolve(res)
        })
        .catch((err) => {
            return Promise.reject(err)
        })
}

export const forgotPasswordVerification = (payload) => {
    return Post({
        url: '/user/forget-password-verification',
        body: payload,
        isAuthorized: false,
    })
        .then((res) => {
            return Promise.resolve(res)
        })
        .catch((err) => {
            return Promise.reject(err)
        })
}

export const resetPassword = (payload) => {
    return Post({
        url: '/user/reset-password',
        body: payload,
        isAuthorized: false,
    })
        .then((res) => {
            return Promise.resolve(res)
        })
        .catch((err) => {
            return Promise.reject(err)
        })
}

//Fish Gear
export const getGearListing = (params = {}, abortSignal = null) => {
    return Get({
        url: '/gear',
        params,
        abortSignal,
    })
}

export const getGearById = (id) => {
    return Get({
        url: '/gear/' + id,
    })
}

export const deleteGear = (id) => {
    return Delete({
        url: '/gear/' + id,
    })
}

export const createGear = (payload) => {
    return Post({
        url: '/gear',
        body: payload,
    })
}

export const updateGear = (id, payload) => {
    return Put({
        url: '/gear/' + id,
        body: payload,
    })
}

//Fish Gear Category
export const getGearCategoryList = (params = {}, abortSignal = null) => {
    return Get({
        url: '/gear/category',
        params,
        abortSignal,
    })
}

export const createGearCategory = (body) => {
    return Post({
        url: '/gear/category',
        body,
    })
}

export const updateGearCategory = (id, body) => {
    return Put({
        url: '/gear/category/' + id,
        body,
    })
}

export const deleteGearCategory = (id) => {
    return Delete({
        url: '/gear/category/' + id,
    })
}

//Fish Spots Locations
export const getFishSpotListing = (params = {}, abortSignal = null) => {
    return Get({
        url: '/location',
        params,
        abortSignal,
    })
}
export const getReviewsList = (params = {}, abortSignal = null) => {
    return Get({
        url: '/review',
        params,
        abortSignal,
    })
}

export const getFishSpotDetails = (id) => {
    return Get({
        url: '/location/' + id,
    })
}

export const deleteFishSpot = (id) => {
    return Delete({
        url: '/location/' + id,
    })
}

export const getReviewById = (id) => {
    return Get({
        url: `/review/${id}`,
    })
}

export const getRatingsGraphPoints = (params = {}) => {
    return Get({
        url: `/review/graph`,
        params,
    })
}

export const getOrdersLisiting = (params = {}, abortSignal = null) => {
    return Get({
        url: `/transaction/history`,
        params,
        abortSignal,
    })
}

export const getOrderDetailsById = (id) => {
    return Get({
        url: `/transaction/${id}`,
    })
}

// Settings- Fish Species

export const getFishSpeciesListing = (params = {}, abortSignal = null) => {
    return Get({
        url: '/fish/specie',
        params: { Column: 'CreatedAt', Direction: SortOrder.DESC, ...params },
        abortSignal,
    })
}
export const getFishSpecieById = (id) => {
    return Get({
        url: `/fish/specie/${id}`,
    })
}
export const updateFishSpecieById = (id, bodyData) => {
    return Put({
        url: `/fish/specie/${id}`,
        body: bodyData,
    })
}

//Moderation
export const getModerationListing = (params = {}, abortSignal = null) => {
    return Get({
        url: '/report',
        params,
        abortSignal,
    })
}

export const getModerationDetail = (id) => {
    return Get({
        url: '/report/' + id,
    })
}

export const updateModeration = (id, bodyData) => {
    return Put({
        url: '/report/' + id,
        body: bodyData,
    })
}

export const deleteModeration = (id) => {
    return Delete({
        url: '/report/' + id,
    })
}

export const addFishSpecie = (bodyData) => {
    return Post({
        url: '/fish/specie',
        body: bodyData,
    })
}
export const deleteFishSpecie = (id) => {
    return Delete({
        url: `/fish/specie/${id}`,
    })
}
// Settings- Fishing Guide

export const getFishingGuideMeta = (params = {}, abortSignal = null) => {
    return Get({
        url: '/guide/trip_meta',
        params,
        params: {
            Column: 'CreatedAt',
            Direction: SortOrder.DESC,
            ...params,
        },
        abortSignal,
    })
}

export const addFishingGuideMeta = (type, bodyData) => {
    return Post({
        url: '/guide/trip_meta',
        params: { Type: type },
        body: bodyData,
    })
}

export const updateFishingGuideMeta = (id, bodyData) => {
    return Put({
        url: `/guide/trip_meta/${id}`,
        body: bodyData,
    })
}

export const deleteFishingGuideMeta = (id) => {
    return Delete({
        url: `/guide/trip_meta/${id}`,
    })
}

//Fish Spots
export const getSpot = (params = {}, abortSignal = null) => {
    return Get({
        url: '/fish/spot',
        params,
        abortSignal,
    })
}

export const deleteSpot = (id) => {
    return Delete({
        url: '/fish/spot/' + id,
    })
}

export const createSpot = (body) => {
    return Post({
        url: '/fish/spot/',
        body,
    })
}

export const updateSpot = (id, body) => {
    return Put({
        url: '/fish/spot/' + id,
        body,
    })
}

//Community Post
export const getCommunityPostList = (params = {}, abortSignal = null) => {
    return Get({
        url: '/community_post',
        params,
        abortSignal,
    })
}

export const getCommunityPostById = (id) => {
    return Get({
        url: '/community_post/' + id,
    })
}

export const createCommunityPost = (body) => {
    return Post({
        url: '/community_post/',
        body,
    })
}

export const updateCommunityPost = (id, body) => {
    return Put({
        url: '/community_post/' + id,
        body,
    })
}

export const deleteCommunityPost = (id) => {
    return Delete({
        url: '/community_post/' + id,
    })
}
//Monthly Submissions

// Dashboard Apis
// ANALYTICS
export const getRevenue = (params = {}) => {
    return Get({
        url: '/analytics/revenue',
        params,
    })
}
export const getUsers = (params = {}) => {
    return Get({
        url: '/analytics/new-users',
        params,
    })
}
export const getSubscribedUsers = (params = {}) => {
    return Get({
        url: '/analytics/new-subscribed-users',
        params,
    })
}
export const getTotalGuides = (params = {}) => {
    return Get({
        url: '/analytics/new-guides',
        params,
    })
}
export const getMonthlySubmissions = (params = {}, abortSignal = null) => {
    return Get({
        url: '/user_story',
        params,
        abortSignal,
    })
}

export const getUserStoryById = (id) => {
    return Get({
        url: `/user_story/${id}`,
    })
}

export const updateMonthlySubmission = (id, bodyData) => {
    return Put({
        url: `/user_story/${id}`,
        body: bodyData,
    })
}

export const getSelectedSubmissionsCount = (params = {}) => {
    return Get({
        url: '/user_story/count',
        params,
    })
}

// SUMMARY
export const getSummary = (params = {}) => {
    return Get({
        url: '/analytics/summary',
        params,
    })
}
// VERIFICATION REQUEST
export const getGuideVerfRequests = (params = {}) => {
    return Get({
        url: '/analytics/guide-verification-requests',
        params,
    })
}
// TRANSACTIONS CHART
export const getTransactionChartData = (params = {}) => {
    return Get({
        url: '/analytics/total-transactions',
        params,
    })
}
// PRODUCT LISTING
export const getProductsChartData = (params = {}) => {
    return Get({
        url: '/analytics/total-product-listing',
        params,
    })
}
export const getLikedUersProfile = (id, params = {}) => {
    return Get({
        url: `/user_story/like/${id}/users`,
        params,
    })
}

export const getConfigSettings = () => {
    return Get({
        url: `/config-setting`,
    })
}

export const setConfigSettings = (body) => {
    return Post({
        url: '/config-setting',
        body,
    })
}

export const getSosReports = (params = {}) => {
    return Get({
        url: '/user/sos-logs',
        params,
    })
}

export const deleteUserStory = (id) => {
    return Delete({
        url: '/user_story/' + id,
    })
}

// EMAIL
export const saveEmailTemplate = (body) => {
    return Post({
        url: '/email_template',
        body,
    })
}

export const sendEmail = (body) => {
    return Post({
        url: '/email_template/send',
        body,
    })
}

export const getEmailTemplates = (params = {}) => {
    return Get({
        url: '/email_template',
        params,
    })
}

export const getEmailTemplateById = (id) => {
    return Get({
        url: `/email_template/id/${id}`,
    })
}

export const editEmailTemplate = (id, body) => {
    return Put({
        url: `/email_template/${id}`,
        body,
    })
}

export const deleteEmailTemplate = (id) => {
    return Delete({
        url: `/email_template/${id}`,
    })
}

export const getEmailTemplatesHistory = (params = {}) => {
    return Get({
        url: '/email_template/history',
        params,
    })
}

export const getEmailTemplatesHistoryById = (id) => {
    return Get({
        url: `/email_template/history/${id}`,
    })
}

export const emailTemplateHistoryUsers = (id) => {
    return Get({
        url: `/email_template/history/users/${id}`,
    })
}

export const deleteEmailTemplateHistory = (id) => {
    return Delete({
        url: `/email_template/history/${id}`,
    })
}

// REFERRAL

export const getCreatedReferralsListing = (params = {}) => {
    return Get({
        url: `/user/referral-codes`,
        params,
    })
}

export const createReferralCode = (body) => {
    return Post({
        url: `/user/referral-code`,
        body,
    })
}

export const deleteReferralCode = (id) => {
    return Delete({
        url: `/user/referral-code/${id}`,
    })
}

export const usersWhoUsedReferralCode = (id) => {
    return Get({
        url: `/user/referral-code-users/${id}`,
    })
}

export const getRefferalUserReport = (id) => {
    // return Get({
    //     url: `/user/referral-code-users/xls/${id}`,
    // })

    const token = store.state.auth.user.Token
    const url = `${Config.env().API_URL}/user/referral-code-users/xls/${id}`
    return fetch(url, {
        headers: {
            authorization: token,
            'Content-Type': 'application/json',
        },
        method: 'GET',
    })
        .then((res) => Promise.resolve(res))
        .catch((err) => {
            Promise.reject(err)
        })
}

// Groups Management

export const getGroupsListing = (params = {}) => {
    return Get({
        url: '/group',
        params,
    })
}

export const getGroupDetails = (id) => {
    return Get({
        url: `/group/${id}`,
    })
}

export const deleteGroup = (id) => {
    return Delete({
        url: `/group/${id}`,
    })
}

export const getGroupMembers = (id) => {
    return Get({
        url: `/group/${id}/users`,
    })
}

// Group Posts

export const groupPostDetails = (id) => {
    return Get({
        url: `/group/post/${id}`,
    })
}

export const deleteGroupPostDetails = (id) => {
    return Delete({
        url: `/group/post/${id}`,
    })
}

// Subscription

export const markReferralUsersAsSubscribed = (id, body) => {
    return Post({
        url: `/user/referral-code/${id}/subscription`,
        body,
    })
}
// Tournament Management

export const getTournamentsListing = (params = {}) => {
    return Get({
        url: '/tournament',
        params,
    })
}

export const getTournamentDetails = (id) => {
    return Get({
        url: `/tournament/${id}`,
    })
}

export const deleteTournament = (id) => {
    return Delete({
        url: `/tournament/${id}`,
    })
}

export const getTournamentParticipants = (id) => {
    return Get({
        url: `/tournament/${id}/participants`,
    })
}
// Moderator

export const addModerator = (body) => {
    return Post({
        url: `/user/add-moderator`,
        body,
    })
}

export const deleteModerator = (body) => {
    return Post({
        url: '/user/delete-moderator',
        body,
    })
}

//Stores

export const getStoresListing = (params = {}) => {
    return Get({
        url: '/vendor',
        params,
    })
}

export const getStoreDetails = (id) => {
    return Get({
        url: `/vendor/${id}`,
    })
}

export const updateStoreStatus = (id, body) => {
    return Post({
        url: `/vendor/${id}/update-status`,
        body,
    })
}

export const updateStoreSubscription = (body) => {
    return Post({
        url: `/vendor/update-subscription`,
        body,
    })
}

// Products

export const getProducts = (params = {}) => {
    return Get({
        url: `/product`,
        params,
    })
}

export const productById = (id) => {
    return Get({
        url: `/product/${id}`,
    })
}

export const getSubscriptionProducts = (params = {}) => {
    return Get({
        url: `/vendor/subscription-products`,
        params,
    })
}

export const deleteProduct = (id) => {
    return Delete({
        url: `/product/${id}`,
    })
}

// Orders
export const getOrders = (params = {}) => {
    return Get({
        url: '/order',
        params,
    })
}

export const getOrderDetails = (id) => {
    return Get({
        url: `/order/${id}`,
    })
}

export const deleteOrder = (id) => {
    return Delete({
        url: `/order/${id}`,
    })
}

//Categories
export const getCategories = (params = {}) => {
    return Get({
        url: '/gear/category',
        params,
    })
}

//Promotions
export const getPromotions = (params = {}) => {
    return Get({
        url: '/promotion',
        params,
    })
}

export const createPromotion = (body) => {
    return Post({
        url: '/promotion',
        body,
    })
}

export const deletePromotion = (id) => {
    return Delete({
        url: `/promotion/${id}`,
    })
}

export const updatePromotion = (id, body) => {
    return Put({
        url: `/promotion/${id}`,
        body,
    })
}
