<template>
	<div class="table-container">
		<b-card body-class="card-body-style">
			<search-header searchInputClasses="w-50" :showStatusFilter="false" :onSearchChange="handleSearchChange"
				:searchText="searchText" :pageLimitValue="pageLimit"
				:onPageLimitChange="handlePageLimitChange"></search-header>
			<data-table :perPageLimit="pageLimit" entriesName="Shops" :showPagination="true" :config="config" :data="data"
				:selectedPageValue="page" :perPage="data.length" :onHandlePageChange="handlePageChange"
				:onSortClick="handleSortChange" :totalRecords="total" :isLoading="isFetching">
				<template #cell(Id)="data">
					<span class="font-weight-bolder">
						{{ data.value }}
					</span>
				</template>

				<template #cell(CompanyName)="data">
					<div>
						<div>
							<span class="font-weight-bolder" style="color:black">
								{{ data.value }}
							</span>
						</div>
						<div class="font-weight-bold">
							{{ data.item.Email }}
						</div>
					</div>
				</template>

				<template #cell(PhoneNumber)="data">
					<span class="font-weight-bold">
						{{ data.item.CountryCode + data.value }}
					</span>
				</template>

				<template #cell(Address)="data">
					<div class="font-weight-bold" style="width: 200px;">
						{{ data.value }}
					</div>
				</template>

				<template #cell(Status)="data">
					<div class="font-weight-bold">
						{{ getVerificationStatus(data.value) }}
					</div>
				</template>

				<template #cell(Suspended)="data">
					<div v-bind:class="getClass(data.value)">
						{{ getSuspendedStatus(data.value) }}
					</div>
				</template>

				<!--#SUBSCRIBED-->
				<template #cell(Subscribed)="data">
					<b-spinner v-if="subscribingID == data.item.Id" variant="primary" class="spinner" />
					<div v-else class="d-flex">
						<switch-button :isChecked="data.item.SubscriptionStatus === 1" :isDisable="subscriptionLoading || data.item.SubscriptionId !== null"
							:onChange="() => handleSubscription(data.item.Id)" />

						<span class="font-weight-bolder">
							{{ handleSubscribedText(data.item.SubscriptionStatus) }}
						</span>
					</div>
				</template>

				<template #cell(Actions)="data">
					<div class="text-nowrap">
						<feather-icon :id="`${data.item.Id}-detail-icon`" icon="EyeIcon" size="16"
							class="mx-1 detail-icon cursor-pointer" v-on:click="viewDetails(data.item.Id)" />
						<b-tooltip title="Detail" :target="`${data.item.Id}-detail-icon`" />


						<feather-icon v-if="data.item.Suspended" :id="`${data.item.Id}-check-circle`" icon="CheckCircleIcon"
							size="16" class="mx-1 check-circle cursor-pointer" style="color:#47c554"
							v-on:click="handleSuspendClick(data.item)" />
						<b-tooltip title="Suspend" :target="`${data.item.Id}`" />

						<feather-icon v-if="!data.item.Suspended" :id="`${data.item.Id}-activate-icon`" icon="SlashIcon"
							size="16" class="mx-1 activate-icon cursor-pointer" style="color:#d9871c"
							v-on:click="handleSuspendClick(data.item)" />
						<b-tooltip title="Activate" :target="`${data.item.Id}`" />

					</div>
				</template>
			</data-table>
		</b-card>

		<ConfirmModal id="suspend-store-modal" :isLoading="isProcessing" :isSuccess="suspendSuccess"
			successText="Suspension status updated successfully!" :onOkayPress="handleOkayPress"
			:onYesPress="handleYesPress" messageText="Are you sure you want to update suspension status?" />

	</div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip, BModal, BSpinner } from 'bootstrap-vue'
import SwitchButton from '../../components/SwitchButton.vue'
import { reactive, ref } from '@vue/composition-api'
import DataTable from '../../components/data-table/DataTable.vue'
import UserInfo from '../../components/UserInfo.vue'
import SearchHeader from '../../components/SearchHeader.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import {
	FishSpotLocationStatus,
	SortOrder,
} from '../../constants'
import { APP_ROUTES, getRoute } from '../../helpers/routeHelpers'
import { getStoresListing, updateStoreStatus, updateStoreSubscription } from '../../api/api.service'
import { getMediaPath } from '../../utils/mediaUtils'
import { useLocalPagination } from '../../hooks/useLocalPagination'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import DeleteIcon from '../../assets/images/icons/delete.svg'
import VERIFICATION_STATUS from '@/constants/VerificationStatus'
import SUSPENDED_STATUS from '@/constants/SuspendedStatus'
import STORE_SUBSCRIPTION_STATUS from '@/constants/StoreSubscriptionStatus'

const config = [
	{
		label: 'ID',
		key: 'Id',
	},
	{
		label: 'SHOP NAME',
		key: 'CompanyName',
	},
	{
		label: 'PHONE',
		key: 'PhoneNumber',
	},
	{
		label: 'ADDRESS',
		key: 'Address',
	},
	{
		label: 'SUBSCRIBED',
		key: 'Subscribed',
	},
	{
		label: 'VERIFICATION',
		key: 'Status',
		isSortable: true,
	},
	{
		label: 'STATUS',
		key: 'Suspended',
		isSortable: true,
	},
	{
		label: 'ACTIONS',
		key: 'Actions',
	},
].map((item) => {
	return { ...item, tdClass: 'table-row' }
})

export default {
	name: 'StoresListing',
	components: {
		DataTable,
		UserInfo,
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		BModal,
		SearchHeader,
		ConfirmModal,
		SwitchButton,
		BSpinner
	},
	setup(props, context) {
		const filters = reactive({ Status: null })
		const selectedId = ref(null)
		const isProcessing = ref(false)
		const deleteSuccess = ref(false)
		const suspendSuccess = ref(false)
		const selectedItem = ref(null)
		const subscribingID = ref(null)
		const subscriptionLoading = ref(false)

		const {
			data,
			isFetching,
			total,
			page,
			searchText,
			pageLimit,
			sortOrder,
			sortColumn,
			handleSearchChange,
			handlePageClick,
			handlePageLimit,
			handleSortChange,
		} = useLocalPagination(
			getStoresListing,
			() => ({
				Direction: SortOrder.DESC,
				Column: 'CreatedAt',
				...(filters.Status && { Status: filters.Status.value }),
				...(filters.Status && { Status: filters.Status.value })
			}),
			[filters],
			'Q',
			'Vendors',
			'TotalVendors'
		)

		const spotLocationStatus = Object.values(FishSpotLocationStatus).map(
			(val) => ({
				label: FishSpotLocationStatus.getDisplayTextKey(val),
				value: FishSpotLocationStatus.getDisplayTextKey(val),
				id: val,
			})
		)

		const handlePageChange = (pageNumber) => {
			handlePageClick(pageNumber)
		}

		const handlePageLimitChange = (val) => {
			page.value = 1
			handlePageLimit(val)
		}



		const handleSuspendClick = (item) => {
			selectedItem.value = item
			context.root.$bvModal.show('suspend-store-modal')
		}


		const updateStatus = (id, status) => {

		}

		const handleSubscribedText = (status) => {
			if (status === 1) {
				return 'Yes'
			}
			return 'No'
		}

		const handleSubscription = (id = '') => {
			if (!id) return

			subscribingID.value = id

			let reqObj = data.value.find(
				(item) => item.Id == subscribingID.value
			)

			let Status = reqObj?.SubscriptionStatus === STORE_SUBSCRIPTION_STATUS.Active ? STORE_SUBSCRIPTION_STATUS.Cancelled : STORE_SUBSCRIPTION_STATUS.Active


			subscriptionLoading.value = true

			updateStoreSubscription({ VendorId: id, SubscriptionStatus: Status })
				.then((res) => {
					data.value = data.value.map((item) => {
						if (item.Id == subscribingID.value) {
							item.SubscriptionStatus = Status
						}
						return item
					})

					showToast(context.root, {
						Title: Status
							? 'Subscribed !'
							: 'Unsubscribed',
						Icon: 'CheckCircleIcon',
					})
				})
				.catch((err) => {
					showToast(context.root, {
						Title: 'Error',
						Icon: 'XCircleIcon',
						Text: err.Message,
						Variant: 'danger',
					})
				})
				.finally(() => {
					subscriptionLoading.value = false
					subscribingID.value = ''
				})
		}

		const handleOkayPress = () => {
			isProcessing.value = false
			deleteSuccess.value = false
			selectedId.value = null
			selectedItem.value = null
			context.root.$bvModalhide('suspend-store-modal')
		}

		const handleYesPress = () => {
			isProcessing.value = true
			updateStoreStatus(selectedItem.value.Id, { Suspended: !selectedItem.value.Suspended })
				.then(() => {
					data.value = data.value.map((item) => {
						if (item.Id === selectedItem.value.Id) {
							item.Suspended = !selectedItem.value.Suspended
						}
						return item
					})
					isProcessing.value = false
				})
				.finally(() => {
					isProcessing.value = false
					deleteSuccess.value = false
					selectedId.value = null
					selectedItem.value = null
					context.root.$bvModal.hide('suspend-store-modal')
				})
		}


		const viewDetails = (id) => {
			context.root.$router.push(
				getRoute(APP_ROUTES.STORE_DETAILS, { storeId: id })
			)
		}

		function getClass(data) {
			let initialClasses = 'font-weight-bolder badge badge-primary status-badge'
			if (data) {
				return initialClasses + ' status-badge-suspended'
			}
			else {
				return initialClasses + ' status-badge-active'
			}
		}

		const getVerificationStatus = (data) => {
			let status = ''
			console.log(data, status, "sadaskdjasda")
			Object.keys(VERIFICATION_STATUS).find(key => {
				if (VERIFICATION_STATUS[key] === data) {
					status = key
				}
			})
			return status
		}

		const getSuspendedStatus = (data) => {
			let status = ''
			Object.keys(SUSPENDED_STATUS).find(key => {
				if (SUSPENDED_STATUS[key] === data) {
					status = key
				}
			})
			return status
		}

		return {
			deleteIcon: DeleteIcon,
			config,
			data,
			total,
			page,
			pageLimit,
			isFetching,
			sortOrder,
			sortColumn,
			searchText,
			FishSpotLocationStatus,
			spotLocationStatus,
			filters,
			isProcessing,
			deleteSuccess,
			suspendSuccess,
			subscribingID,
			subscriptionLoading,
			handleOkayPress,
			handleYesPress,
			handleSearchChange,
			handlePageChange,
			handlePageLimit,
			handleSortChange,
			getMediaPath,
			viewDetails,
			handlePageLimitChange,
			dateTimeFormat,
			convertDateTime,
			getClass,
			updateStatus,
			getVerificationStatus,
			getSuspendedStatus,
			handleSuspendClick,
			handleSubscribedText,
			handleSubscription
		}
	},
}
</script>

<style scoped>
::v-deep .active-icon {
	color: #28c76f;
}

::v-deep .inactive-icon {
	color: #d88416;
}

::v-deep .detail-icon {
	color: #888888;
}

.delete-icon {
	width: 14px;
	height: 14px;
	cursor: pointer;
}

.time {
	font-weight: 500;
}

.card-body-style {
	padding: 0 !important;
}

::v-deep .table-row {
	font-weight: bold;
}

::v-deep .address-cordinates {
	color: #888888;
	font-size: 12px;
}

.table-container {
	margin-top: -10px;
}
</style>
