import { fetchUtil } from './fetchUtil'
import store from '../store'
import { MediaServerTypes } from '../constants/'

export const MAX_FILE_SIZE_ALLOWED_IN_MB = 5

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })

export const initializeMediaUtil = (file, isPublic = false) => {
    const token = store.state.auth.user.Token

    return fetchUtil({
        url: isPublic ? '/media/upload/public/init' : '/media/upload/init',
        body: JSON.stringify({
            Name: file.name,
            MimeType: file.type,
            Size: file.size,
        }),
        method: 'POST',
        ...(!isPublic && { token }),
    })
        .then((res) => {
            return Promise.resolve(res)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

let isConfigUpdated = false

export const uploadOnS3 = async (stream, credentials, cb) => {
    if (!window.AWS) {
        return
    }
    if (!isConfigUpdated) {
        window.AWS.config.update({ region: credentials.Region })
        isConfigUpdated = true
    }
    let s3 = new window.AWS.S3({
        credentials: new window.AWS.Credentials({
            accessKeyId: credentials.AccessKeyId,
            secretAccessKey: credentials.SecretAccessKey,
            sessionToken: credentials.SessionToken,
            signatureVersion: 'v4',
            region: credentials.Region,
        }),
    })

    let uploadedItem = await s3
        .upload({
            Bucket: credentials.Bucket,
            Key: credentials.Path,
            ACL: 'public-read',
            Body: stream,
        })
        .on('httpUploadProgress', function (progress) {
            cb(getUploadedProgress(progress.loaded, progress.total))
        })
        .promise()
    return uploadedItem
}

export const getUploadedProgress = (uploadedSize, totalSize) => {
    let uploadedProgress = (uploadedSize / totalSize) * 100
    return Number(uploadedProgress.toFixed(0))
}

export const getMediaStream = async (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = (e) => {
            resolve(e.target.result)
        }
        reader.onerror = (err) => {
            reject(false)
        }
        reader.readAsArrayBuffer(file)
    })
}

export const finalizeMediaUtil = async (Id, isPublic = false) => {
    const token = store.state.auth.user.Token

    return fetchUtil({
        url: isPublic
            ? '/media/upload/public/finalize'
            : '/media/upload/finalize',
        body: JSON.stringify({
            Id,
        }),
        method: 'POST',
        ...(!isPublic && { token }),
    })
        .then((res) => {
            return Promise.resolve(res)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export const getImageFromMedia = (media) => {
    for (let i in media) {
        if (media[i].Type === MediaServerTypes.Image) {
            return media[i]
        }
    }
    return ''
}

export const getMediaPath = (media = null) => {
    if (media && media.BasePath && media.Path) {
        return `${media.BasePath}${media.Path}`
    }
    return ''
}
