<template>
  <div>
    <b-card body-class="card-body-style">
      <SearchHeader
        :showStatusFilter="false"
        :showSearchField="false"
        :pageLimitValue="pageLimit"
        :onPageLimitChange="handlePageLimitChange"
      >
        <template v-slot:left-header-content>
          <span class="search-label mr-2 search-text">Search</span>
          <b-form-input
            @input="handleSearchChange"
            :value="searchText"
            class="d-inline-block mr-3 search-field"
          />
        </template>
      </SearchHeader>

      <DataTable
        :perPageLimit="pageLimit"
        entriesName="Reports"
        :isLoading="isFetching"
        :config="config"
        :perPage="data.length"
        :data="data || []"
        :selectedPageValue="page"
        :onHandlePageChange="handlePageChange"
        :totalRecords="total"
        :onSortClick="handleSortChange"
        :currentSortOrder="sortOrder"
        :currentSortColumn="sortColumn"
      >
        <template #cell(Id)="data">
          <span class="font-weight-bolder"> {{ data.item.Id }}</span>
        </template>

        <template #cell(UserName)="data">
          <span class="text-nowrap">
            <user-info iconSize="md" :fullName="data.item.User.FullName" />
          </span>
        </template>

        <template #cell(Date)="data">
          <span class="text-nowrap">
            {{
              convertDateTime({
                date: data.item.CreatedAt,
                customFormat: dateTimeFormat.appDateFormat,
                dateOnly: true,
              }) || "-"
            }}
          </span>
        </template>

        <template #cell(CreatedAt)="data">
          <p class="text-nowrap fw-500 mb-0">
            {{
              `${convertTime({
                tz: "LOCAL_TIME",
                time: moment(data.item.CreatedAt).format('hh:mm"ss'),
                format: dateTimeFormat.appTimeFormat,
              })}`
            }}
          </p>
        </template>

        <template #cell(Location)="data">
          <span class="font-weight-bolder"> {{ data.item.Location }} </span>
        </template>

        <template #cell(Coordinates)="data">
          <span class="address-cordinates">{{ data.item.Latitude }} - </span>
          <span class="address-cordinates">{{ data.item.Longitude }}</span>
        </template>

        <template #cell(SentCount)="data">
          <span class="font-weight-bolder">
            {{ data.item.SentTo > 0 ? data.item.SentTo + " Persons" : "-" }}
          </span>
        </template>
      </DataTable>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BBadge,
  BLink,
  BTooltip,
  BFormInput,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import moment from "moment";

import DataTable from "@/components/data-table/DataTable.vue";
import UserInfo from "@/components/UserInfo.vue";
import SearchHeader from "@/components/SearchHeader.vue";
import {
  dateTimeFormat,
  convertDateTime,
  convertTime,
} from "@/utils/dateFormatUtils";
import { getMediaPath, getStatusOptions } from "@/utils/commonUtils";
import { useLocalPagination } from "@/hooks/useLocalPagination";
import { getSosReports } from "@/api/api.service";
import { SortOrder } from "@/constants";

const config = [
  {
    label: "NO",
    key: "Id",
  },
  {
    label: "USER NAME",
    key: "UserName",
  },
  {
    label: "DATE",
    key: "Date",
  },
  {
    label: "TIME",
    key: "CreatedAt",
  },
  {
    label: "LOCATION",
    key: "Location",
  },
  {
    label: "GPS COORDINATES",
    key: "Coordinates",
  },
  {
    label: "SENT TO",
    key: "SentCount",
  },
].map((item) => {
  return { ...item, tdClass: "table-row-medium-text" };
});

export default {
  name: "SOSReport",
  components: {
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BTooltip,
    BFormInput,
    DataTable,
    UserInfo,
    SearchHeader,
  },

  setup(props, context) {
    const isLoading = ref(false);
    const isSuccess = ref(false);
    const isError = ref(false);
    const perPageOptions = ref([5, 10, 25]);
    // const data = ref([]);

    const {
      data,
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      sortOrder,
      sortColumn,
      handleSearchChange,
      handlePageClick,
      handlePageLimit,
      handleSortChange,
    } = useLocalPagination(
      getSosReports,
      () => ({ Direction: SortOrder.DESC, Column: "CreatedAt" }),
      [],
      "Q",
      "SosLogs",
      "TotalSosLogs"
    );
    const handlePageChange = (pageNumber) => {
      handlePageClick(pageNumber);
    };

    const handlePageLimitChange = (val) => {
      page.value = 1;

      handlePageLimit(val);
    };
    return {
      perPageOptions,
      config,
      data,
      isLoading,
      isSuccess,
      config,
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      sortOrder,
      sortColumn,
      isError,
      dateTimeFormat,
      convertTime,
      handlePageChange,
      handlePageLimitChange,
      convertDateTime,
      getMediaPath,
      getStatusOptions,
      handleSearchChange,
      handleSortChange,
      moment,
    };
  },
};
</script>

<style scoped>
.card-body-style {
  padding: 0 !important;
}

::v-deep .table-row-medium-text {
  font-weight: 500;
  color: black;
}

::v-deep .month-selector {
  width: 190px !important;
}

::v-deep .search-field {
  width: 170px !important;
}

::v-deep .search-text {
  margin-left: 2px !important;
}

.amount-text {
  color: #003071;
  margin-left: 10px !important;
}

.amount-description {
  margin-left: 10px !important;
  margin-right: 4px !important;
}

.earning-card {
  padding: 6px 6px !important;
  border: 1px solid #3599cd !important;
  border-radius: 0.5rem !important;
  background-color: #f2fbff;
}
</style>