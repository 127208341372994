<template>
	<b-row>
		<b-col lg="12" md="12" sm="12">
			<b-card>
				<b-row class="mb-3">
					<b-col lg="5">
						<b-row lg="4">
							<b-col
								lg="4"
								class="align-self-center pt-1 avatar-padding"
							>
								<Avatar
									:src="getMediaPath(currentUser.Media)"
									size="100"
								/>
							</b-col>

							<b-col lg="7" class="align-self-center pt-2">
								<LabelText
									label="Full Name"
									:text="currentUser.FullName"
									labelClasses="wd-90 "
									textClasses="font-bold"
									containerClasses="mb-8"
								/>
								<LabelText
									label="Phone No"
									:text="
										currentUser.CountryCode +
										currentUser.PhoneNumber
									"
									labelClasses="wd-90"
									textClasses="font-bold"
									containerClasses="mb-8"
								/>
								<LabelText
									label="Address"
									:text="
										currentUser.UserAddress
											? currentUser.UserAddress.Address
											: '-'
									"
									labelClasses="wd-90"
									textClasses="font-bold"
									containerClasses="mb-8"
								/>
							</b-col>
						</b-row>
					</b-col>

					<b-col lg="4" sm="auto" class="align-self-center pt-2">
						<LabelText
							label="Email Address"
							:text="currentUser.Email"
							labelClasses="wd-120"
							textClasses="font-bold"
							containerClasses="mb-8"
						/>
						<div class="align-wrapper mb-8">
							<p class="label subscription-wd-120">
								Subscription: &nbsp;
							</p>
							<div class="icon-wrapper">
								<img
									v-if="
										currentUser.SubscriptionStatus == true
									"
									:src="crownIcon"
									class="crown"
								/>
								<p class="fw-500">
									{{
										currentUser.SubscriptionStatus == true
											? 'Subscribed'
											: 'Unsubscribed'
									}}
								</p>
							</div>
						</div>
						<LabelText
							label="Status"
							:text="
								userStatusCode.getDisplayTextKey(
									currentUser.Status
								)
							"
							labelClasses="wd-120"
							textClasses="font-bold"
							containerClasses="mb-8"
						/>
					</b-col>

					<b-col lg="3" sm="auto" class="btn-wrapper">
						<Button
							size="md"
							buttonVariant="primary"
							classes="wd-211"
							buttonText="Show Reviews"
							:clickHandler="showReviews"
						/>
						<b-overlay
							:show="updateUserLoading"
							rounded="sm"
							spinner-variant="primary"
							spinner-small
							class="d-inline-block"
						>
							<Button
								v-if="
									[
										userStatusCode.VERIFIED,
										userStatusCode.SUSPENDED,
									].includes(currentUser.Status)
								"
								size="md"
								buttonVariant="outline-secondary"
								classes="mt-1 wd-211"
								:buttonText="
									currentUser.Status ==
									userStatusCode.VERIFIED
										? 'Suspend'
										: 'Active'
								"
								:clickHandler="
									() =>
										$bvModal.show(
											`action-${currentUser.Id}-modal`
										)
								"
							/>
						</b-overlay>
					</b-col>
				</b-row>

				<hr />

				<b-col lg="12" md="12" sm="12" class="mt-30 mb-29"
					><Title text="Selling Status" />
				</b-col>

				<b-row col="12" class="pl-1">
					<b-col sm="auto" lg="4" md="auto">
						<LabelText
							label="No of Location Sold"
							:text="currentUser.LocationSold || 0"
							textClasses="font-bold"
							containerClasses="mb-8"
					/></b-col>

					<b-col sm="auto" lg="4" md="auto"
						><LabelText
							label="Rating as Location Seller"
							:text="currentUser.LocationRating || 0"
							textClasses="font-bold"
							containerClasses="mb-8"
						/>
					</b-col>

					<b-col sm="auto" lg="4" md="auto">
						<LabelText
							label="Rating as Guide"
							:text="currentUser.TripRating || 0"
							textClasses="font-bold"
							containerClasses="mb-8"
					/></b-col>
				</b-row>

				<hr />

				<b-col lg="12" md="12" sm="12" class="mt-30 mb-29"
					><Title text="Preferences" />
				</b-col>

				<b-row class="pl-1">
					<b-col sm="auto" lg="4" md="auto">
						<LabelText
							label="Fish Species"
							:text="currentUser.SpecieNames || '-'"
							textClasses="font-bold"
							containerClasses="mb-8"
							labelClasses="mr-1"
					/></b-col>

					<b-col sm="auto" lg="4" md="auto">
						<LabelText
							label="Fishing Gear"
							:text="currentUser.Gears || '-'"
							textClasses="font-bold"
							containerClasses="mb-8"
							labelClasses="mr-1 w-25"
					/></b-col>

					<b-col sm="auto" lg="4" md="auto">
						<LabelText
							label="Location"
							:text="currentUser.FishSpots || '-'"
							textClasses="font-bold"
							containerClasses="mb-8"
							labelClasses="mr-1"
					/></b-col>
				</b-row>
			</b-card>

			<Title text="Catalog" />

			<CatalogueTable />
		</b-col>
		<ConfirmModal
			:isLoading="isLoading"
			:isSuccess="isUserStatusChangedSuccess"
			:onOkayPress="handleOkayPress"
			successText="Success !"
			:onYesPress="updateUserStatus"
			:id="`action-${currentUser.Id}-modal`"
			:messageText="
				currentUser.Status == userStatusCode.SUSPENDED
					? 'Are you sure you want to activate this user?'
					: 'Are you sure you want to suspend this user?'
			"
			:messageIcon="require('@/assets/images/icons/restrict-icon.svg')"
		/>
	</b-row>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BTableLite,
	BCardText,
	BButton,
	BAlert,
	BLink,
	BBadge,
	BOverlay,
} from 'bootstrap-vue'
import {
	computed,
	onMounted,
	reactive,
	toRefs,
	ref,
} from '@vue/composition-api'

import ConfirmModal from '../../../components/ConfirmModal.vue'
import Avatar from '../../../components/Avatar.vue'
import LabelText from '../../../components/LabelText.vue'
import Button from '../../../components/Button.vue'
import Title from '../../../components/Title.vue'
import CatalogueTable from './CatalogueTable.vue'
import { getUserDetail } from '../../../api/api.service'
import { useStore } from '../../../store'
import { getMediaPath } from '../../../utils/commonUtils'
import { APP_ROUTES, getRoute } from '../../../helpers/routeHelpers'
import { UserStatusCode } from '../../../constants'

// const avatar = require("../../.FF./assets/images/avatars/12.png");
const crownIcon = require('../../../assets/images/icons/crown-icon.svg')

export default {
	name: 'UserDetails',
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BLink,
		BBadge,
		LabelText,
		Avatar,
		Button,
		Title,
		CatalogueTable,
		BOverlay,
		ConfirmModal,
	},
	setup: (props, context) => {
		const userId = Number(context.root.$route.params.userId)
		const store = useStore()
		const state = reactive({ currentUser: {}, updateUserLoading: false })

		const isLoading = ref(false)
		const isUserStatusChangedSuccess = ref(false)

		state.currentUser =
			computed(() => store.getters['user/getUserById'](userId)).value ||
			{}

		onMounted(() => {
			getUserDetail(userId).then((res) => {
				state.currentUser = res.User
			})
		})

		function showReviews() {
			context.root.$router.push(
				getRoute(APP_ROUTES.USER_REVIEWS, { userId })
			)
		}

		function updateUserStatus() {
			isLoading.value = true
			let updatedStatus =
				state.currentUser.Status === UserStatusCode.VERIFIED
					? UserStatusCode.SUSPENDED
					: UserStatusCode.VERIFIED
			state.updateUserLoading = true
			store
				.dispatch('user/updateUserStatus', {
					id: state.currentUser.Id,
					status: updatedStatus,
				})
				.then((res) => {
					state.currentUser.Status = updatedStatus
					isUserStatusChangedSuccess.value = true
				})
				.finally(() => {
					state.updateUserLoading = false
					isLoading.value = false
				})
		}

		function handleOkayPress() {
			isUserStatusChangedSuccess.value = false
		}

		return {
			...toRefs(state),
			// avatar,
			showReviews,
			crownIcon,
			getMediaPath,
			userStatusCode: UserStatusCode,
			updateUserStatus,
			handleOkayPress,
			isUserStatusChangedSuccess,
			isLoading,
		}
	},
}
</script>

<style scoped>
.text-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.label {
	size: 14px;
	color: #888888;
}

::v-deep .font-bold {
	font-weight: 500 !important;
}

.fw-500 {
	font-weight: 500;
	color: #000;
}

.crown {
	width: 14px;
	height: 14px;
	align-self: center;
}
.align-wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 11px;
}
.icon-wrapper {
	display: flex;
	align-items: center;
}
.icon-wrapper p {
	margin-bottom: 0px;
	padding-left: 10px;
}
.align-wrapper p {
	margin-bottom: 0px;
}
.align-wrapper p.label {
	width: 130px;
}

.ml-50 {
	margin-left: 50px !important;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.mb-29 {
	margin-bottom: 26px;
}

.mt-30 {
	margin-top: 30px;
}

.avatar-padding {
	padding-left: 25px;
}

.btn-wrapper {
	align-self: center;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

@media only screen and (max-width: 600px) {
	.btn-wrapper {
		align-items: flex-start;
	}
}

::v-deep .wd-120 {
	width: 120px;
}
.subscription-wd-120 {
	width: 120px !important;
}
.wd-120 {
	width: 120px;
}

::v-deep .w-130 {
	width: 130px;
}

::v-deep .wd-211 {
	width: 211px;
}

::v-deep .wd-90 {
	width: 90px;
}

.w-130 {
	width: 130px;
}
</style>
