<template>
	<b-card>
		<b-container fluid>
			<b-row>
				<b-col cols="12" md="6" lg="5" sm="12">
					<LabelText
						label="Reported By"
						showRightSection
						textClasses="font-weight-bolder"
					>
						<UserInfo
							iconSize="md"
							:fullName="currentReport.User.FullName || '-'"
							:avatarImage="
								getMediaPath(currentReport.User.Media) || '-'
							"
						/>
					</LabelText>

					<LabelText
						:label="
							'Reported ' +
							ReportEntityType.getDisplayTextKey(
								currentReport.EntityType
							)
						"
						showRightSection
						textClasses="font-weight-bolder"
						v-if="
							currentReport.EntityType === 22 ||
							currentReport.EntityType === 23
						"
					>
						<p class="font-weight-bolder">
							{{ getUnknownData(currentReport) }}
						</p>
					</LabelText>

					<LabelText
						label="Reported Person"
						showRightSection
						textClasses="font-weight-bolder"
						v-if="
							currentReport.EntityType !== 23 &&
							currentReport.EntityType !== 22
						"
					>
						<UserInfo
							iconSize="md"
							:fullName="
								getReportedPerson(currentReport, 'FullName')
							"
							:avatarImage="
								getReportedPerson(currentReport, 'Media')
							"
						/>
					</LabelText>

					<LabelText
						label="Date"
						:text="
							convertDateTime({
								date: currentReport.CreatedAt,
								customFormat:
									dateTimeFormat.dateWithYearMonthFormat,
								dateOnly: true,
							})
						"
						textClasses="font-weight-bolder"
					/>

					<div v-if="currentReport.ReportType == 6">
						<p class="review-title">Report:</p>
						<p class="font-weight-bolder report">
							{{ currentReport.Description || 'No Content' }}
						</p>
					</div>
				</b-col>
				<b-col cols="12" md="6" lg="4" sm="12">
					<!-- <LabelText
					:label="currentReport.Type"
					text="Dallas, US"
					textClasses="font-weight-bolder"
          /			> -->
					<div
						v-if="
							!currentReport.isHideLocation &&
							currentReport.EntityType !== 22 &&
							currentReport.EntityType !== 23 &&
							currentReport.EntityType !== 21 &&
							currentReport.EntityType !== 20 &&
							!currentReport.Tournament
						"
					>
						<LabelText
							label="Location"
							:text="
								currentReport.EntityType ==
								ReportEntityType.Location
									? currentReport.Location.Address
									: currentReport.Location || '-'
							"
							textClasses="font-weight-bolder"
						/>
					</div>
					<div v-if="currentReport.Tournament">
						<LabelText
							label="Location"
							:text="
								currentReport.Tournament.LocationAddress || '-'
							"
							textClasses="font-weight-bolder"
						/>
					</div>
					<div v-if="currentReport.EntityType === 22">
						<LabelText
							label="Location"
							:text="
								currentReport.Vendor
									? currentReport.Vendor.Address
									: '-'
							"
							textClasses="font-weight-bolder"
						/>
					</div>
					<div v-if="currentReport.isHideLocation">
						<LabelText
							label="Group Name"
							:text="currentReport.GroupName || '-'"
							textClasses="font-weight-bolder"
						/>
					</div>

					<LabelText
						label="Report Type"
						:text="
							reportType.getDisplayTextKey(
								currentReport.ReportType
							)
						"
						textClasses="font-weight-bolder"
					/>

					<LabelText
						label="Post Comment"
						v-if="currentReport.EntityType === 21"
						:text="getData(currentReport, 'Comment')"
						textClasses="font-weight-bolder"
					/>

					<LabelText
						label="Post"
						v-if="currentReport.EntityType === 20"
						:text="getData(currentReport, 'Description')"
						textClasses="font-weight-bolder"
					/>

					<LabelText
						v-if="
							currentReport.EntityType ==
							ReportEntityType.GroupPostComment
						"
						:label="currentReport.Type"
						:text="currentReport.GroupPostComment.Comment"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						:label="currentReport.Type"
						:text="comment"
						v-if="currentReport.EntityType == 15"
						textClasses="font-weight-bolder"
					/>

					<!-- <LabelText
            label="Price"
            text="$100"
            textClasses="font-weight-bolder"
          /> -->
				</b-col>
				<b-col cols="12" md="12" lg="3" sm="12">
					<div class="mb-2 text-right text-md-left">
						<Button
							buttonText="Remove From Listing"
							buttonVariant="danger"
							v-if="
								currentReport.Status === reportStatus.ToBeViewed
							"
							classes="mt-1 mb-1 w-100"
							v-b-modal="`action-${currentReport.Id}-modal`"
							:clickHandler="
								() =>
									setModalStatus(reportStatus.ListingRemoved)
							"
						/><Button
							buttonText="Ignore"
							buttonVariant="outline"
							v-if="
								currentReport.Status === reportStatus.ToBeViewed
							"
							classes="mt-1 mb-1 w-100 btn-outline-danger"
							v-b-modal="`action-${currentReport.Id}-modal`"
							:clickHandler="
								() =>
									setModalStatus(reportStatus.ReportRejected)
							"
						/>
					</div>
				</b-col>
			</b-row>
			<hr />
			<!-- Buttons Row -->
			<b-row>
				<b-col cols="12" md="12" lg="3" sm="12">
					<Button
						buttonText="View Reporter Profile"
						buttonVariant="outline"
						classes="mt-1 mb-1 w-100 btn-outline-grey"
						:clickHandler="
							() => viewUserDetails(currentReport.User.Id)
						"
					/>
				</b-col>
				<!-- need to add entity type for tournament -->
				<b-col
					cols="12"
					md="12"
					lg="3"
					sm="12"
					v-if="
						currentReport.EntityType !== 22 &&
						currentReport.EntityType !== 23
					"
				>
					<Button
						buttonText="Reported Person Profile"
						buttonVariant="outline"
						classes="mt-1 mb-1 w-100 btn-outline-grey"
						:clickHandler="
							() =>
								viewUserDetails(
									getReportedPerson(currentReport, 'Id')
								)
						"
					/>
				</b-col>
				<b-col
					cols="12"
					md="12"
					lg="3"
					sm="12"
					v-if="
						currentReport.EntityType === 22 ||
						currentReport.EntityType === 23
					"
				>
					<Button
						:buttonText="
							'Reported ' +
							ReportEntityType.getDisplayTextKey(
								currentReport.EntityType
							)
						"
						buttonVariant="outline"
						classes="mt-1 mb-1 w-100 btn-outline-grey"
						:clickHandler="() => viewEntityDetails(currentReport)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="12"
					lg="3"
					sm="12"
					v-if="
						currentReport.EntityType != 22 &&
						currentReport.EntityType != 23 &&
						currentReport.EntityType !== 20 &&
						currentReport.EntityType !== 21 &&
						!isHideViewDetailsBtn &&
						currentReport.Status != reportStatus.ListingRemoved
					"
				>
					<Button
						:buttonText="viewDetailsBtnText"
						buttonVariant="outline"
						classes="mt-1 mb-1 w-100 btn-outline-grey"
						:clickHandler="viewDetails"
					/>
				</b-col>
				<ConfirmModal
					:isLoading="isLoading"
					:isSuccess="isModerationStatusChangedSuccess"
					:onOkayPress="handleOkayPress"
					successText="Success !"
					:onYesPress="() => updateModerationStatus(status)"
					:id="`action-${currentReport.Id}-modal`"
					:messageText="`Are you sure you want to ${statusText} this ${updateMessage} ?`"
					:messageIcon="
						require('@/assets/images/icons/restrict-icon.svg')
					"
				/>

				<BasicModal
					v-if="currentReport.EntityType === 23"
					id="entity-details-modal"
					:modalTitle="
						ReportEntityType.getDisplayTextKey(
							currentReport.EntityType
						) + ' Details'
					"
				>
					<b-card class="card entity-card">
						<div class="entity-image-container">
							<img
								:src="getMediaPath(product.DefaultMedia)"
								@error="replaceByDefault"
								class="entity-image"
							/>
						</div>
						<div class="entity-details-container">
							<!-- <div class="entity-title">
								{{  }}
							</div> -->
							<LabelText
								label="Product Name"
								:text="product.Name"
								textClasses="font-weight-bolder"
								containerClasses="fixed-height"
							/>

							<LabelText
								label="Category"
								:text="product.GearCategory.Name"
								textClasses="font-weight-bolder"
								containerClasses="fixed-height"
							/>

							<LabelText
								label="Price"
								:text="
									product.PriceCurrency + ' ' + product.Price
								"
								textClasses="font-weight-bolder"
								containerClasses="fixed-height"
							/>

							<LabelText
								label="Shop Name"
								:text="product.Vendor.CompanyName"
								textClasses="font-weight-bolder"
								containerClasses="fixed-height"
							/>

							<LabelText
								label="Shop Description"
								showRightSection
								textClasses="font-weight-bolder"
								style="display: block"
								containerClasses="fixed-height mt-1"
							>
								<p>{{ product.Description }}</p>
							</LabelText>
						</div>
					</b-card>
				</BasicModal>
				<b-col
					cols="12"
					md="12"
					lg="3"
					sm="12"
					v-if="
						currentReport.EntityType != 15 &&
						currentReport.EntityType != 16 &&
						currentReport.EntityType != 12 &&
						currentReport.EntityType != 4 &&
						currentReport.EntityType != 17 &&
						currentReport.EntityType != 6 &&
						currentReport.EntityType != 5
					"
				>
					<div
						v-if="
							currentReport.Status !=
								reportStatus.ListingRemoved &&
							!currentReport.Tournament &&
							!currentReport.TournamentPost &&
							!currentReport.TournamentPostComment
						"
					>
						<Button
							:buttonText="viewDetailsBtnText"
							buttonVariant="outline"
							classes="mt-1 mb-1 w-100 btn-outline-grey"
							:clickHandler="viewDetails"
						/>
					</div>
				</b-col>
				<ConfirmModal
					:isLoading="isLoading"
					:isSuccess="isModerationStatusChangedSuccess"
					:onOkayPress="handleOkayPress"
					successText="Success !"
					:onYesPress="() => updateModerationStatus(status)"
					:id="`action-${currentReport.Id}-modal`"
					:messageText="`Are you sure you want to ${statusText} this ${updateMessage}?`"
					:messageIcon="
						require('@/assets/images/icons/restrict-icon.svg')
					"
				/>
			</b-row>
		</b-container>
	</b-card>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BTableLite,
	BCardText,
	BButton,
	BAlert,
	BLink,
	BAvatar,
} from 'bootstrap-vue'
import {
	computed,
	onMounted,
	reactive,
	toRefs,
	ref,
} from '@vue/composition-api'

import LabelText from '../../components/LabelText.vue'
import Ratings from '../../components/Ratings.vue'
import AvatarImage from '../../assets/images/avatars/8.png'
import Button from '../../components/Button.vue'
import Avatar from '../../components/Avatar.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import BasicModal from '@/components/BasicModal.vue'
import UserInfo from '@/components/UserInfo.vue'
import { getModerationDetail, productById } from '../../api/api.service'
import { useStore } from '../../store'
import { getMediaPath, showModal } from '../../utils/commonUtils'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import { APP_ROUTES, getRoute } from '../../helpers/routeHelpers'
import { ReportStatus, ReportType, ReportEntityType } from '../../constants'
import placeholderImage from '@/assets/images/gallery/placeholder-image.png'
import showToast from '@/hooks/useToast'

export default {
	name: 'ModerationDetails',
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BLink,
		BAvatar,
		LabelText,
		Ratings,
		Button,
		Avatar,
		ConfirmModal,
		UserInfo,
		BasicModal,
	},
	setup: (props, context) => {
		const reportId = Number(context.root.$route.params.moderationId)
		const store = useStore()
		const isHideViewDetailsBtn = ref(false)

		const state = reactive({
			viewDetailsBtnText: 'View Details',
			currentReport: {},
			isLoading: false,
			isModerationStatusChangedSuccess: false,
			status: null,
			updateMessage: '',
			statusText: '',
			comment: '',
			product: {},
		})

		const generateRouteConfig = () => {
			return {
				[ReportEntityType.User]: 'User',
				[ReportEntityType.FishSpecie]: 'Fish Species',
				[ReportEntityType.FishSpot]: 'Fish Spot',
				[ReportEntityType.CatchLog]: 'Catchlog',
				[ReportEntityType.Booking]: 'Booking',
				[ReportEntityType.Voucher]: 'Voucher',
				[ReportEntityType.CommunityPost]: 'Community Post',
				[ReportEntityType.ConfigSetting]: 'Config Setting',
				[ReportEntityType.UserStoryTag]: 'User Story Comment',

				[ReportEntityType.Gear]: {
					route: APP_ROUTES.FISH_GEAR_DETAILS,
					paramsObj: {
						gearId: state.currentReport.EntityId,
					},
				},

				[ReportEntityType.Location]: {
					route: APP_ROUTES.FISH_SPOT_DETAILS,
					paramsObj: {
						fishSpotId: state.currentReport.EntityId,
					},
				},

				[ReportEntityType.GuideTrip]: {
					route: APP_ROUTES.FISHING_GUIDE_DETAILS,
					paramsObj: {
						guideId: state.currentReport.EntityId,
					},
				},

				[ReportEntityType.UserStory]: {
					route: APP_ROUTES.USER_STORY_DETAILS,
					paramsObj: {
						storyId: state.currentReport.EntityId,
					},
				},

				[ReportEntityType.Group]: {
					route: APP_ROUTES.GROUP_DETAILS,
					paramsObj: {
						groupId: state.currentReport.EntityId,
					},
				},
				[ReportEntityType.GroupPost]: {
					route: APP_ROUTES.GROUP_POST_DETAILS,
					paramsObj: {
						postId: state?.currentReport?.EntityId,
					},
				},
				[ReportEntityType.GroupPostComment]: {
					route: APP_ROUTES.GROUP_POST_DETAILS,
					paramsObj: {
						postId: state?.currentReport?.GroupPostComment
							?.GroupPostId,
					},
				},
			}
		}
		let routesConfig = generateRouteConfig()

		function extractReportDetail(report) {
			if (!Object.keys(report).length) {
				return {}
			}

			let Title = ''
			let GroupName = ''
			let ReportedPerson = {}
			let Type = ''
			let isHideLocation = false

			if (report.GuideTrip) {
				Title = report.GuideTrip.Title
				ReportedPerson = report.GuideTrip.User
				Type = 'Fishing Trip'
				state.viewDetailsBtnText = 'View Trip Details'
				state.updateMessage = 'Trip'
			} else if (report.Location) {
				Title = report.Location.Title || report.Location.Address
				ReportedPerson = report.Location.User
				Type = 'Fishing Spot'
				state.viewDetailsBtnText = 'View Location Details'
				state.updateMessage = 'Location'
			} else if (report.UserStory) {
				Title = report.UserStory.Content
				ReportedPerson = report.UserStory.User
				Type = 'User Story'
				state.viewDetailsBtnText = 'View Story'
				state.updateMessage = 'User Story'
			} else if (report.UserStoryTag) {
				Title = '-'
				ReportedPerson = report.UserStoryTag.User
				Type = 'User Story Comment'
				state.viewDetailsBtnText = 'View Story'
				state.updateMessage = 'User Story'
			} else if (report.Group) {
				GroupName = report.Group.Name
				Title = report.Group.Name
				ReportedPerson = report.Group.User
				Type = 'Group'
				state.viewDetailsBtnText = 'View Group Details'
				isHideLocation = true
				state.updateMessage = 'Group'
			} else if (report.GroupPost) {
				GroupName = report.GroupPost.Group.Name
				// Title = '-'
				ReportedPerson = report.GroupPost.User
				Type = 'Group Post'
				isHideLocation = true
				state.updateMessage = 'Group Post'
			} else if (report.GroupPostComment) {
				GroupName = report.GroupPostComment.GroupPost.Group.Name || '-'
				Title = report.GroupPostComment.Comment
				ReportedPerson = report.GroupPostComment.User
				Type = 'Group Post Comment'
				state.viewDetailsBtnText = 'View Post'
				isHideLocation = true
				state.updateMessage = 'Comment'
				isHideViewDetailsBtn.value = true
			} else if (report.Gear) {
				Title = report.Gear.Name
				ReportedPerson = report.Gear.User
				Type = 'Fishing Gear'
				state.viewDetailsBtnText = 'View Product Details'
				state.updateMessage = 'Gear'
			}

			return {
				...report,
				Title,
				GroupName,
				Type,
				ReportedPerson: ReportedPerson || {},
				isHideLocation,
			}
		}

		// state.currentReport = extractReportDetail(
		// 	computed(() =>
		// 		store.getters['moderation/getModerationById'](reportId)
		// 	).value || {}
		// )

		generateUserStoryTagComment()

		onMounted(() => {
			getModerationDetail(reportId).then((res) => {
				state.currentReport = { ...extractReportDetail(res.Report) }
				routesConfig = generateRouteConfig()
				generateUserStoryTagComment()

				if (state.currentReport.EntityType === 23) {
					getProductDetails(state.currentReport.Product.Id)
				}
			})
		})

		function viewUserDetails(id) {
			context.root.$router.push(
				getRoute(APP_ROUTES.USER_DETAILS, { userId: id })
			)
		}

		function generateUserStoryTagComment() {
			let userObj = {}
			if (
				state.currentReport.EntityType == 15 &&
				state.currentReport.UserStoryTag
			) {
				state.comment = state.currentReport.UserStoryTag.Comment
				if (state.currentReport.UserStoryTag.TagUser) {
					userObj[state.currentReport.UserStoryTag.TagUserId] =
						state.currentReport.UserStoryTag.TagUser.FullName
				}
				if (state.currentReport.UserStoryTag.RefTags) {
					state.currentReport.UserStoryTag.RefTags.forEach((item) => {
						userObj[item.TagUserId] = item.TagUser.FullName
					})
				}

				Object.keys(userObj).forEach((key) => {
					state.comment = state.comment.replaceAll(
						`$#{${key}}#$`,
						userObj[key]
					)
				})
			}
		}

		function setModalStatus(status) {
			state.status = status
			if (status === ReportStatus.ListingRemoved) {
				state.statusText = 'remove'
			} else {
				state.statusText = 'ignore'
			}

			showModal(`action-${state.currentReport.Id}-modal`, context)
		}

		const viewDetails = () => {
			if (
				state.currentReport.Tournament ||
				state.currentReport.TournamentPost
			) {
				console.log('tournament module detected')
				let route = APP_ROUTES.TOURNAMENT_DETAILS
				let key = 'tournamentId'
				context.root.$router.push(
					getRoute(route, {
						[key]:
							state.currentReport.Tournament?.Id ||
							state.currentReport.TournamentPost?.Tournament?.Id,
					})
				)
			} else if (state.currentReport.EntityType === 23) {
				console.log('product module detected')
				let route = APP_ROUTES.STORE_DETAILS
				let key = 'storeId'
				context.root.$router.push(
					getRoute(route, {
						[key]: state.currentReport.Product?.Vendor?.Id,
					})
				)
			} else if (state?.currentReport?.EntityType === 15) {
				console.log('user story comment module detected')
				let route = APP_ROUTES.USER_STORY_DETAILS
				let key = 'storyId'
				context.root.$router.push(
					getRoute(route, {
						[key]: state.currentReport.UserStoryTag?.UserStoryId,
					})
				)
			} else {
				let desiredRouteDetails =
					routesConfig[state?.currentReport?.EntityType]
				console.log(
					'other module detected,',
					desiredRouteDetails?.route
				)
				context.root.$router.push(
					getRoute(desiredRouteDetails?.route, {
						...desiredRouteDetails?.paramsObj,
					})
				)
			}
		}

		function updateModerationStatus(status) {
			state.isLoading = true
			store
				.dispatch('moderation/updateModerationStatus', {
					id: reportId,
					status,
				})
				.then((res) => {
					console.log(res, 'resresresres')
					if (res.Status === 404) {
						showToast(this, {
							Title: 'Error',
							Variant: 'danger',
							Icon: 'XCircleIcon',
							Text: res.Message,
						})
						context.root.$bvModal.hide(
							`action-${state.currentReport.Id}-modal`
						)
						state.isModerationStatusChangedSuccess = false
						return
					} else {
						state.currentReport = {
							...state.currentReport,
							Status: status,
						}
						state.isModerationStatusChangedSuccess = true
					}
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					state.isLoading = false
				})
		}

		function handleOkayPress() {
			state.isModerationStatusChangedSuccess = false
		}

		const getUnknownData = (data) => {
			return data.Product ? data.Product?.Name : data.Vendor?.CompanyName
		}

		const viewEntityDetails = (report) => {
			if (report.EntityType === 22) {
				context.root.$router.push(
					getRoute(APP_ROUTES.STORE_DETAILS, {
						storeId: report.Vendor.Id,
					})
				)
			} else {
				context.root.$bvModal.show(`entity-details-modal`)
			}
		}
		const replaceByDefault = (event) => {
			event.target.src = placeholderImage
		}

		const getProductDetails = async (id) => {
			try {
				state.product = await productById(id)
			} catch (err) {}
		}

		function getReportedPerson(data, key) {
			if (data?.TournamentPost?.User[key]) {
				return data?.TournamentPost?.User[key]
			}
			if (data?.TournamentPostComment?.User[key]) {
				return data?.TournamentPostComment?.User[key]
			}
			if (data?.Tournament?.User[key]) {
				return data?.Tournament?.User[key]
			} else {
				let value =
					data.ReportedPerson[key] ||
					data.TournamentPost?.User[key] ||
					'-'
				return value
			}
		}

		function getData(data, key = 'Comment') {
			if (key === 'Comment') {
				let value =
					(data.TournamentPostComment &&
						data.TournamentPostComment?.[key]) ||
					'-'
				return value
			}
			if (key === 'Description') {
				let value =
					(data.TournamentPost && data.TournamentPost?.[key]) || '-'
				return value
			}
		}

		return {
			...toRefs(state),
			AvatarImage,
			dateTimeFormat,
			reportType: ReportType,
			reportStatus: ReportStatus,
			ReportEntityType,
			getMediaPath,
			convertDateTime,
			viewUserDetails,
			viewDetails,
			updateModerationStatus,
			handleOkayPress,
			setModalStatus,
			showModal,
			isHideViewDetailsBtn,
			getUnknownData,
			viewEntityDetails,
			replaceByDefault,
			getReportedPerson,
			getData,
		}
	},
}
</script>

<style lang="scss">
@import '../~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style scoped>
.report {
	line-height: 25px;
	color: black;
}

.review-title {
	color: #888888;
}

.card-body {
	padding: 14px !important;
}

.entity-image-container {
	height: 210px;
}

.entity-title {
	color: #000;
	font-size: 14px;
	font-weight: bold !important;
}

.entity-image {
	width: 100%;
	height: 100%;
	border-radius: 6px;
	object-fit: cover;
	object-position: center;
}

.entity-details-container {
	padding: 10px 5px 10px;
}

.entity-card {
	background-color: #fff;
}

.fixed-height {
	margin-bottom: 0 !important;
}
</style>