<template>
	<div>
		<b-card-title title-tag="h4" class="font-weight-bolder mb-1">
			Sign-in To Your Account
		</b-card-title>

		<!-- form -->
		<validation-observer ref="loginValidation">
			<b-form class="auth-login-form mt-2" @submit.prevent>
				<!-- email -->
				<b-form-group>
					<label for="login-email" class="font-weight-bolder"
						>Email</label
					>
					<validation-provider
						#default="{ errors }"
						name="Email"
						rules="required|email"
					>
						<b-form-input
							id="login-email"
							v-model="userEmail"
							:state="errors.length > 0 ? false : null"
							name="login-email"
							placeholder="john@example.com"
						/>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-form-group>

				<!-- forgot password -->
				<b-form-group>
					<div class="d-flex justify-content-between">
						<label for="login-password" class="font-weight-bolder"
							>Password</label
						>

						<router-link :to="APP_ROUTES.FORGOT_PASSWORD">
							Forgot Password?
						</router-link>
					</div>
					<validation-provider
						#default="{ errors }"
						name="Password"
						rules="required"
					>
						<b-input-group
							class="input-group-merge"
							:class="errors.length > 0 ? 'is-invalid' : null"
						>
							<b-form-input
								id="login-password"
								v-model="password"
								:state="errors.length > 0 ? false : null"
								class="form-control-merge"
								:type="passwordFieldType"
								name="login-password"
								placeholder="············"
							/>
							<b-input-group-append is-text>
								<feather-icon
									class="cursor-pointer"
									:icon="passwordToggleIcon"
									@click="togglePasswordVisibility"
								/>
							</b-input-group-append>
						</b-input-group>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-form-group>

				<!-- checkbox -->
				<b-form-group>
					<b-form-checkbox
						id="remember-me"
						v-model="status"
						name="checkbox-1"
						class="font-weight-bolder"
					>
						Remember Me
					</b-form-checkbox>
				</b-form-group>

				<!-- submit buttons -->
				<Button
					type="submit"
					buttonVariant="primary"
					blockButton
					:clickHandler="handleLogin"
					buttonText="Sign in"
					:isLoading="isLoading"
				/>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BRow,
	BCol,
	BLink,
	BFormGroup,
	BFormInput,
	BInputGroupAppend,
	BInputGroup,
	BFormCheckbox,
	BCardText,
	BCardTitle,
	BImg,
	BForm,
	BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions } from 'vuex'

import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Button from '../../../components/Button.vue'
import { APP_ROUTES } from '../../../helpers/routeHelpers'
import { LOGIN } from '../../../store/auth'

export default {
	name: 'AuthLogin',
	components: {
		BRow,
		BCol,
		BLink,
		BFormGroup,
		BFormInput,
		BInputGroupAppend,
		BInputGroup,
		BFormCheckbox,
		BCardText,
		BCardTitle,
		BImg,
		BForm,
		BButton,
		ValidationProvider,
		ValidationObserver,
		Button,
	},
	mixins: [togglePasswordVisibility],
	data() {
		return {
			status: '',
			password: '',
			userEmail: '',
			// validation rulesimport store from '@/store/index'
			required,
			email,
			isLoading: false,
			APP_ROUTES,
		}
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password'
				? 'EyeIcon'
				: 'EyeOffIcon'
		},
	},
	methods: {
		...mapActions([LOGIN]),
		navigateToDashboard() {
			this.$router.push(APP_ROUTES.DASHBOARD)
		},
		handleLogin() {
			this.$refs.loginValidation.validate().then((success) => {
				if (!success) {
					return
				}

				this.isLoading = true

				let payload = {
					Email: this.userEmail,
					Password: this.password,
				}

				this.login(payload)
					.then((res) => {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Logged in Successfully',
								icon: 'CheckCircleIcon',
								variant: 'success',
							},
						})
					})
					.catch((err) => {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: err.Message,
								icon: 'AlertCircleIcon',
								variant: 'danger',
							},
						})
					})
					.finally(() => {
						this.isLoading = false
					})
			})
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
.middle-image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
}
</style>
