<template>
	<b-card class="w-100">
		<div class="container-fluid w-100">
			<b-row no-gutters>
				<b-col cols="12" sm="12" md="12" lg="5" xl="5">
					<b-card-title class="text-secondary font-weight-bolder"
						>{{ title }}
					</b-card-title>
				</b-col>
				<b-col cols="12" sm="12" md="12" lg="7" xl="7">
					<div
						class="
							d-flex
							flex-wrap
							align-items-center
							w-100
							justify-content-between
							justify-content-lg-end
							justify-content-md-end
							justify-content-xl-end
						"
					>
						<div class="d-flex date-text">
							<feather-icon
								icon="CalendarIcon"
								class="calender"
							/>

							<datepicker
								@selected="onChange"
								:minimumView="'year'"
								:maximumView="'year'"
								:format="customFormatter"
								placeholder="Select Year"
								input-class="datepicker"
								:disabled-dates="disabledDates"
								clear-button-icon
								calendar-class="open-right"
							/>
						</div>
						<div>
							<!-- <DropdownButton
                buttonText="Week"
                variant="outline-primary"
                styleClasses="w-100"
              >
                <b-dropdown-item v-for="index in 3" :key="index">
                  Week {{ index }}
                </b-dropdown-item>
              </DropdownButton> -->

							<InputSelect
								placeholder="Weeks"
								:options="weekData"
								classes="filter-select w-50"
								:value="weekValue"
								:onChange="onWeekChange"
								:clearable="false"
							/>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>

		<!-- chart -->
		<b-card-body>
			<LineChart
				:height="400"
				:chartData="graphData.data"
				:options="graphData.options"
			/>
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BCardBody,
	BCardSubTitle,
	BCardTitle,
	BDropdownItem,
	BRow,
	BCol,
} from 'bootstrap-vue'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import moment from 'moment'

import LineChart from './LineChart.vue'
import DropdownButton from '../DropdownButton.vue'
import InputSelect from '../InputSelect.vue'

import weekData from './optionsData'

export default {
	name: 'ChartBox',
	components: {
		BCard,
		BCardHeader,
		BCardBody,
		BCardSubTitle,
		BCardTitle,
		BRow,
		BCol,
		BDropdownItem,
		InputSelect,
		Datepicker,
		LineChart,
		DropdownButton,
	},

	props: {
		title: {
			type: String,
			default: 'Title',
		},
		graphData: {
			type: Object,
			default: null,
		},
		onChange: Function,
		dateValue: String,
		onWeekChange: Function,
		weekValue: String,
	},

	methods: {
		customFormatter(date) {
			return moment(date).format('YYYY')
		},
	},

	data() {
		return {
			date: '',
			weekData,
			plugins: [],

			disabledDates: {
				from: new Date(),
			},
		}
	},
}
</script>
<style scoped>
.text-color {
	color: #003071;
}
.selected-date {
	font-size: 12px;
	margin-left: 5px;
}
.date-text {
	margin-top: 10px;
	cursor: pointer;
}
.calender {
	margin-top: 4px;
}
::v-deep .filter-select {
	min-width: 100px;
	border: 1px solid green !important;
}
</style>
<style>
.open-right {
	right: 0;
}
.datepicker {
	cursor: pointer;
	border: 0 !important;
	background-color: inherit;
	font-size: 12px !important;
	width: 70px;
	margin-bottom: 10px;
	font-weight: 400;
}
</style>

