var Config = {
  LIMIT: "10",
  PERSIST_SECRET_KEY: "!!fishfin-admin-$$",
  ENVIRONMENT: "PRODUCTION",
  ENVIRONMENTS: {
    LOCAL: {
      API_URL: "https://api.fishfinapp.com",
    },
    DEVELOPMENT: {
      API_URL: "https://api.fishfinapp.com",
    },
    STAGING: {
      API_URL: "https://api.fishfinapp.com",
    },
    PRODUCTION: {
      API_URL: "https://api.fishfinapp.com",
    },
  },
};

Config.env = () => {
  return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;