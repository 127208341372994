<template>
	<div class="table-container">
		<b-card body-class="card-body-style">
			<search-header
				searchInputClasses="w-50"
				:showStatusFilter="false"
				:onSearchChange="handleSearchChange"
				:searchText="searchText"
				:pageLimitValue="pageLimit"
				:onPageLimitChange="handlePageLimitChange"
			></search-header>
			<data-table
				:perPageLimit="pageLimit"
				entriesName="Order History"
				:showPagination="true"
				:config="config"
				:data="data"
				:selectedPageValue="page"
				:perPage="data.length"
				:onHandlePageChange="handlePageChange"
				:onSortClick="handleSortChange"
				:totalRecords="total"
				:isLoading="isFetching"
			>
				<template #cell(Id)="data">
					<span class="font-weight-bolder">
						{{ data.value || '-' }}
					</span>
				</template>

				<template #cell(User.FullName)="data">
					<div>
						<div>
							<span
								class="font-weight-bolder"
								style="color: black"
							>
								{{ data.value }}
							</span>
						</div>
						<div class="font-weight-bold">
							{{ data.item.User.Email }}
						</div>
					</div>
				</template>

				<template #cell(OrderTime)="data">
					<span class="font-weight-bold">
						{{
							convertDateTime({
								date: data.value,
								customFormat:
									dateTimeFormat.dateWithYearMonthFormat,
								dateOnly: true,
							}) || '-'
						}}
					</span>
				</template>

				<template #cell(NetAmount)="data">
					<div class="font-weight-bolder" style="width: 200px">
						${{ data.value.toFixed(2) }}
					</div>
				</template>

				<template #cell(StoresName)="data">
					<div class="font-weight-bolder" style="width: 200px">
						{{ getStoreNames(data.item) }}
					</div>
				</template>

				<template #cell(Status)="data">
					<div v-bind:class="getClass(data.value)">
						{{ getStatus(data.value) }}
					</div>
				</template>

				<template #cell(Actions)="data">
					<div class="text-nowrap">
						<feather-icon
							:id="`fish-spot-row-${data.item.Id}-detail-icon`"
							icon="EyeIcon"
							size="16"
							class="mx-1 detail-icon cursor-pointer"
							v-on:click="viewDetails(data.item.Id)"
						/>
						<b-tooltip
							title="Detail"
							:target="`fish-spot-row-${data.item.Id}-detail-icon`"
						/>

						<!-- <img v-b-modal="'fishing-spot-modal'" @click="handleDeleteClick(data.item.Id)"
							:id="`fish-spot-row-${data.item.Id}-action-icon`" class="delete-icon" :src="deleteIcon" />

						<b-tooltip title="Delete" class="cursor-pointer"
							:target="`fish-spot-row-${data.item.Id}-action-icon`" /> -->
					</div>
				</template>
			</data-table>
		</b-card>
		<ConfirmModal
			id="delete-order-modal"
			:isLoading="isProcessing"
			:isSuccess="deleteSuccess"
			successText="Order deleted successfully!"
			:onOkayPress="handleOkayPress"
			:onYesPress="handleYesPress"
			messageText="Are you sure you want to delete this order?"
		/>
	</div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from 'bootstrap-vue'
import { reactive, ref } from '@vue/composition-api'
import moment from 'moment'
import DataTable from '../../components/data-table/DataTable.vue'
import UserInfo from '../../components/UserInfo.vue'
import SearchHeader from '../../components/SearchHeader.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import { FishSpotLocationStatus, SortOrder } from '../../constants'
import { APP_ROUTES, getRoute } from '../../helpers/routeHelpers'
import { deleteOrder, getOrders } from '../../api/api.service'
import { getMediaPath } from '../../utils/mediaUtils'
import { useLocalPagination } from '../../hooks/useLocalPagination'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import DeleteIcon from '../../assets/images/icons/delete.svg'
import ORDER_STATUS from '@/constants/OrderStatus'
import VERIFICATION_STATUS from '@/constants/VerificationStatus'

const config = [
	{
		label: 'ORDER ID',
		key: 'OrderNumber',
	},
	{
		label: 'ORDER BY',
		key: 'User.FullName',
	},
	{
		label: 'ORDER DATE',
		key: 'OrderTime',
		isSortable: true,
	},
	{
		label: 'SHOPS NAMES',
		key: 'StoresName',
	},
	{
		label: 'TOTAL PAYMENT',
		key: 'NetAmount',
		isSortable: true,
	},
	{
		label: 'STATUS',
		key: 'Status',
		isSortable: true,
	},
	{
		label: 'ACTIONS',
		key: 'Actions',
	},
].map((item) => {
	return { ...item, tdClass: 'table-row' }
})

export default {
	name: 'StoresListing',
	components: {
		DataTable,
		UserInfo,
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		SearchHeader,
		ConfirmModal,
	},
	setup(props, context) {
		const filters = reactive({ Status: null })
		const selectedId = ref(null)
		const isProcessing = ref(false)
		const deleteSuccess = ref(false)

		const {
			data,
			isFetching,
			total,
			page,
			searchText,
			pageLimit,
			sortOrder,
			sortColumn,
			handleSearchChange,
			handlePageClick,
			handlePageLimit,
			handleSortChange,
		} = useLocalPagination(
			getOrders,
			() => ({
				Direction: SortOrder.DESC,
				Column: 'OrderTime',
				...(filters.Status && { Status: filters.Status.value }),
				...(filters.VerifyStatus && {
					VerifyStatus: filters.VerifyStatus.value,
				}),
			}),
			[filters],
			'Q',
			'Orders',
			'TotalOrders'
		)

		const spotLocationStatus = Object.values(FishSpotLocationStatus).map(
			(val) => ({
				label: FishSpotLocationStatus.getDisplayTextKey(val),
				value: FishSpotLocationStatus.getDisplayTextKey(val),
				id: val,
			})
		)

		const handlePageChange = (pageNumber) => {
			handlePageClick(pageNumber)
		}

		const handlePageLimitChange = (val) => {
			page.value = 1

			handlePageLimit(val)
		}

		const handleStatusChange = (item) => {
			page.value = 1
			if (!item?.id) {
				filters.Status = null
				return
			}
			filters.Status = item.id
		}

		const handleYesPress = () => {
			isProcessing.value = true

			deleteOrder(selectedId.value)
				.then(() => {
					data.value = data.value.filter(
						(item) => item.Id !== selectedId.value
					)
					deleteSuccess.value = true
				})
				.finally(() => {
					isProcessing.value = false
				})
		}

		const handleOkayPress = () => {
			deleteSuccess.value = false
			selectedId.value = null
		}

		const handleDeleteClick = (id) => {
			selectedId.value = id
			context.root.$bvModal.show('delete-order-modal')
		}

		const viewDetails = (id) => {
			context.root.$router.push(
				getRoute(APP_ROUTES.STORE_ORDER_HISTORY_DETAILS, {
					orderId: id,
				})
			)
		}

		function getValue(data = {}, key = '') {
			if (
				data?.item[findByReportEntity.value[data?.item.EntityType]]
					?.User
			) {
				return (
					data?.item[findByReportEntity.value[data?.item.EntityType]]
						?.User[key] || '-'
				)
			}
		}

		function getClass(data) {
			let classes = 'font-weight-bolder badge badge-primary status-badge'
			Object.keys(ORDER_STATUS).find((key) => {
				if (ORDER_STATUS[key] === data) {
					classes =
						'font-weight-bolder badge badge-' +
						key +
						' status-badge'
				}
			})
			return classes
		}

		const getStoreNames = (data) => {
			return data.Packages.map((item) => item.Vendor.CompanyName).join(
				', '
			)
		}

		const getStatus = (data) => {
			let status = ''
			Object.keys(ORDER_STATUS).find((key) => {
				if (ORDER_STATUS[key] === data) {
					status = key
				}
			})
			return status
		}
		return {
			deleteIcon: DeleteIcon,
			config,
			data,
			total,
			page,
			pageLimit,
			isFetching,
			sortOrder,
			sortColumn,
			searchText,
			FishSpotLocationStatus,
			spotLocationStatus,
			filters,
			isProcessing,
			deleteSuccess,
			handleOkayPress,
			handleDeleteClick,
			handleYesPress,
			handleSearchChange,
			handlePageChange,
			handlePageLimit,
			handleSortChange,
			handleStatusChange,
			getMediaPath,
			viewDetails,
			handlePageLimitChange,
			dateTimeFormat,
			convertDateTime,
			getClass,
			moment,
			ORDER_STATUS,
			VERIFICATION_STATUS,
			getStoreNames,
			getStatus,
		}
	},
}
</script>

<style scoped>
::v-deep .active-icon {
	color: #28c76f;
}

::v-deep .inactive-icon {
	color: #d88416;
}

::v-deep .detail-icon {
	color: #888888;
}

.delete-icon {
	width: 14px;
	height: 14px;
	cursor: pointer;
}

.time {
	font-weight: 500;
}

.card-body-style {
	padding: 0 !important;
}

::v-deep .table-row {
	font-weight: bold;
}

::v-deep .address-cordinates {
	color: #888888;
	font-size: 12px;
}

.table-container {
	margin-top: -10px;
}

.badge-CART {
	background-color: #f8f4e5 !important;
}

.badge-PENDING {
	background-color: #f8f4e5 !important;
}

.badge-PROCESSING {
	background-color: #2897c71f !important;
}

.badge-READY {
	background-color: #51c0281f !important;
}

.badge-COMPLETED {
	background-color: #28c76f1f !important;
}

.badge-CANCELLED {
	background-color: #ffdcdc !important;
}

.badge-REFUNDED {
	background-color: #eddcff !important;
}

.badge-PAYMENT_PROCESSING {
	background-color: #283dc71f !important;
}

.badge-PAYMENT_FAILED {
	background-color: #ffeedc !important;
}
</style>
