const ORDER_STATUS = {
    CART: 1,
    PENDING: 2,
    PROCESSING: 3,
    READY: 4,
    COMPLETED: 5,
    CANCELLED: 6,
    REFUNDED: 7,
    PAYMENT_PROCESSING: 10,
    PAYMENT_FAILED: 11,
}

export default ORDER_STATUS