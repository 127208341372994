import { injectBaseConstantMethods } from './BaseConstants'

const TournamentType = {
    Adults: 1,
    Teenagers: 2,
}

const displayTextKeys = {
    [TournamentType.Adults]: 'Adults',
    [TournamentType.Teenagers]: 'Teenagers',
}

const labelClass = {
    [TournamentType.Adults]: 'tournament-adults',
    [TournamentType.Teenagers]: 'tournament-teenagers',
}

export default injectBaseConstantMethods(
    TournamentType,
    displayTextKeys,
    labelClass
)
