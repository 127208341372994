<template>
	<div class="blog-detail-wrapper">
		<b-row>
			<b-col cols="12">
				<!-- Loader -->
				<template v-if="fetching">
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle"></b-spinner>
					</div>
				</template>

				<b-card class="container-card" v-if="!fetching">
					<!-- Cover Image -->
					<div class="cover-image-container">
						<img
							:src="
								getMediaPath(tournamentData.CoverPhoto) ||
								require('../../assets/images/gallery/no-cover-image-placeholder.png')
							"
							class="cover-image"
						/>
					</div>

					<div class="p-2">
						<div>
							<b-card-title class="card-title">{{
								tournamentData.Name || '-'
							}}</b-card-title>
							<b-row>
								<b-col sm="auto" lg="4" md="auto">
									<div
										class="d-flex align-items-center justify-content-start tournament-location"
									>
										<feather-icon
											icon="MapPinIcon"
											size="50"
											class="ml-0 mr-1 cursor-pointer"
										/>
										<span class="font-weight-bolder">
											{{
												tournamentData.LocationAddress ||
												'-'
											}}
										</span>
									</div>
								</b-col>
								<b-col sm="auto" lg="4" md="auto">
									<div>
										<LabelText
											label="Tournament Type"
											:text="`${TournamentType.getDisplayTextKey(
												tournamentData.Type
											)}`"
											textClasses="font-weight-bolder"
											containerClasses="mb-8"
										/>
									</div>
								</b-col>
								<b-col sm="auto" lg="4" md="auto">
									<div>
										<LabelText
											label="Entry Fees"
											:text="
												tournamentData.Type ==
												TournamentType.Teenagers
													? 'No Fee'
													: `${tournamentData.EntityFeeCurrency} ${tournamentData.EntryFee}`
											"
											textClasses="font-weight-bolder"
											containerClasses="mb-8"
										/>
									</div>
								</b-col>
							</b-row>
						</div>

						<!-- Section 01 -->
						<b-row col="12" class="pt-4 pb-4">
							<b-col sm="auto" lg="4" md="auto">
								<LabelText
									label="Creator"
									showRightSection
									textClasses="font-weight-bolder"
								>
									<UserInfo
										iconSize="md"
										:fullName="`${
											tournamentData.User.FullName || '-'
										}`"
										containerClasses="mb-8"
										nameClasses="marginT"
									/>
								</LabelText>
							</b-col>

							<b-col sm="auto" lg="4" md="auto">
								<LabelText
									label="Date Created"
									:text="
										convertDateTime({
											date: tournamentData.CreatedAt,
											customFormat:
												dateTimeFormat.appDateFormat,
											dateOnly: true,
										})
									"
									textClasses="font-weight-bolder"
									containerClasses="mb-8"
								/>
							</b-col>
							<b-col sm="auto" lg="4" md="auto">
								<LabelText
									label="Status"
									:text="
										tournamentData.IsCanceled
											? 'Canceled'
											: TournamentStatusLocal.getDisplayTextKey(
													tournamentData.TournamentStatus
											  ) || '-'
									"
									:textClasses="`detail-status-common ${
										tournamentData.IsCanceled
											? 'tournament-canceled '
											: TournamentStatusLocal.getLabelClass(
													tournamentData.TournamentStatus
											  )
									}`"
								/>
							</b-col>
							<b-col sm="auto" lg="4" md="auto">
								<LabelText
									label="Participants"
									textClasses="font-weight-bolder"
									containerClasses="mb-8"
									showRightSection
								>
									<div v-if="totalTournamentParticipants > 0">
										<GroupedAvatar
											id="modal"
											:likedByProfiles="
												tournamentParticipants || null
											"
											:onClick="handleParticipantsModal"
											:onViewClickText="`${totalTournamentParticipants} Participants`"
										/>
									</div>
									<div v-else>
										<p class="font-weight-bolder">
											No Participants
										</p>
									</div>
								</LabelText>
							</b-col>
							<b-col sm="auto" lg="4" md="auto">
								<LabelText
									label="Starting From"
									:text="handleGetTournamentDuration()"
									textClasses="font-weight-bolder"
									containerClasses="mb-8"
								/>
							</b-col>
							<b-col sm="auto" lg="4" md="auto">
								<LabelText
									label="Total Posts"
									:text="tournamentData.PostsCount"
									textClasses="font-weight-bolder"
									containerClasses="mb-8"
								/>
							</b-col>
						</b-row>
						<hr />

						<!-- Section 02-->

						<b-row col="12" class="mt-5 mb-5">
							<b-col lg="12" md="12" sm="12">
								<Title
									:text="`${
										tournamentData.TournamentStatus ==
										TournamentStatusLocal.Completed
											? 'Team Winners'
											: 'Winning Prizes'
									}`"
								/>
							</b-col>
							<b-col col="12">
								<Winning :winnerConfig="winningData" />
							</b-col>
						</b-row>
						<hr />
						<!-- Section 03-->

						<b-row col="12">
							<b-col lg="12" md="12" sm="12">
								<Title text="Description/Rules" />
							</b-col>
							<b-col col="12">
								<div
									v-html="
										handleConvertTextLinksToTag(
											parsedDescription
										)
									"
								></div>
							</b-col>
						</b-row>

						<!-- Delete Action-->

						<b-row class="mt-4">
							<b-col lg="12" md="12" sm="12">
								<div style="width: 200px">
									<Button
										containerClasses="w-100"
										buttonText="Delete"
										buttonVariant="danger"
										classes="mt-1 mb-1 w-100"
										:clickHandler="handleDeleteClick"
									/>
								</div>
							</b-col>
						</b-row>
					</div>

					<ConfirmModal
						id="delete-tournament-modal"
						:isLoading="isProcessing"
						:isSuccess="deleteSuccess"
						successText="Tournament deleted successfully!"
						:onOkayPress="handleOkayPress"
						:onYesPress="handleYesPress"
						messageText="Are you sure you want to delete this tournament?"
					/>
					<BasicModal
						id="group-members-modal"
						modalTitle="Participants"
					>
						<template #showAllViewsUsers>
							<TournamentsParticipantsModal
								:usersData="tournamentParticipants"
							/>
						</template>
					</BasicModal>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {
	BFormInput,
	BMedia,
	BAvatar,
	BMediaAside,
	BMediaBody,
	BImg,
	BLink,
	BFormGroup,
	BInputGroup,
	BInputGroupAppend,
	BCard,
	BCardText,
	BRow,
	BCol,
	BBadge,
	BDropdown,
	BDropdownItem,
	BForm,
	BFormTextarea,
	BFormCheckbox,
	BButton,
	BCardImg,
	BSpinner,
	BCarousel,
	BCarouselSlide,
} from 'bootstrap-vue'

import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import { textUrlToHtmlLink } from 'text-url-to-html-link'
import moment from 'moment'

import {
	getTournamentDetails,
	getTournamentParticipants,
	deleteTournament,
} from '../../api/api.service'
import { getMediaPath } from '../../utils/commonUtils'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import Button from '../../components/Button.vue'
import { TournamentStatuses, TournamentType } from '../../constants'
import Title from '../../components/Title.vue'
import LabelText from '../../components/LabelText.vue'
import UserInfo from '../../components/UserInfo.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import BasicModal from '@/components/BasicModal.vue'
import { APP_ROUTES } from '@/helpers/routeHelpers'
import GroupedAvatar from '@/components/GroupedAvatar.vue'
import TournamentsParticipantsModal from './TournamentsParticipantsModal.vue'
import Winning from './Winnings.vue'

export default {
	name: 'TournamentDetails',
	components: {
		BFormInput,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BLink,
		BCard,
		BRow,
		BCol,
		BFormGroup,
		BInputGroup,
		BInputGroupAppend,
		BImg,
		BBadge,
		BCardText,
		BDropdown,
		BForm,
		BDropdownItem,
		BFormTextarea,
		BFormCheckbox,
		BButton,
		BCardImg,
		BSpinner,
		BCarousel,
		BCarouselSlide,
		Button,
		Title,
		LabelText,
		UserInfo,
		ConfirmModal,
		GroupedAvatar,
		BasicModal,
		TournamentsParticipantsModal,
		Winning,
	},

	data() {
		return {
			TournamentType,
			parsedDescription: '',
			isFetching: false,
			isFetchingParticipants: false,
			tournamentData: {},
			winningData: [],
			tournamentParticipants: {},
			totalTournamentParticipants: 0,
			isProcessing: false,
			deleteSuccess: false,
			TournamentStatusLocal: TournamentStatuses,
			dateTimeFormat,
		}
	},
	methods: {
		handleWinnigs() {
			let winningKeys = {
				[TournamentStatuses.NotStarted]: 'WinningPrizes',
				[TournamentStatuses.OnGoing]: 'WinningPrizes',
				[TournamentStatuses.WaitingForResult]: 'WinningPrizes',
				[TournamentStatuses.Completed]: 'Winners',
			}

			if (
				this.tournamentData[
					winningKeys[this.tournamentData.TournamentStatus]
				]?.length
			) {
				let requiredData =
					this.tournamentData[
						winningKeys[this.tournamentData.TournamentStatus]
					]

				let obj = requiredData
					?.map((item) => ({
						winningCurrency:
							this.tournamentData.WinningPrizeCurrency,
						winningPosition: item?.Position,
						winningPrizeAmount: item?.Prize,
						winningUser: item?.User || null,
						isFirstPosition: Boolean(
							item.Position > 0 && item.Position < 2
						),
					}))
					?.sort((x, y) => x.winningPosition - y.winningPosition)

				this.winningData = [...obj]
			}
		},

		handleGetTournamentDuration() {
			let getFormatedDate = (date) => moment(date).format('DD-MMM-YYYY')
			let getFormatedTime = (time) =>
				moment(time, 'HH:mm:ss').format('hh:mm A')

			let formatedDuaraiton = `${getFormatedDate(
				this.tournamentData.StartDate
			)}-${
				getFormatedTime(this.tournamentData.StartTime) || '-'
			} to ${getFormatedDate(this.tournamentData.EndDate)}-${
				getFormatedTime(this.tournamentData.EndTime) || '-'
			}`

			return formatedDuaraiton
		},
		handleConvertTextLinksToTag(data = '') {
			if (!data) {
				return 'No description to show!'
			}
			let text = textUrlToHtmlLink(data)
			return text
		},
		async handleGetTournamentDetails(id) {
			let htmlFormat,
				defaultValue = {}

			try {
				this.isFetching = true
				let res = await getTournamentDetails(id)
				let converter = new QuillDeltaToHtmlConverter(
					JSON.parse(res?.Description),
					defaultValue
				)
				htmlFormat = converter.convert()
				this.parsedDescription = htmlFormat
				this.tournamentData = { ...res }
				this.handleWinnigs()
			} catch (err) {
				// TODO: Need to visit
				if (err.Message == 'Group not found') {
					this.$router.push(APP_ROUTES.TOURNAMENTS_LISTING)
				}
			} finally {
				this.isFetching = false
			}
		},

		async handleGetTournamentParticipantsList(id) {
			try {
				this.isFetchingParticipants = true
				let res = await getTournamentParticipants(id)
				this.tournamentParticipants = res?.Participants
				this.totalTournamentParticipants = res?.TotalParticipants
			} catch (err) {
			} finally {
				this.isFetchingParticipants = false
			}
		},
		handleYesPress() {
			this.isProcessing = true

			deleteTournament(this.$route.params.tournamentId)
				.then((res) => {
					this.deleteSuccess = true
				})
				.finally(() => {
					this.isProcessing = false
				})
		},

		handleDeleteClick() {
			this.$bvModal.show('delete-tournament-modal')
		},

		handleOkayPress() {
			this.deleteSuccess = false
			this.$router.replace(APP_ROUTES.TOURNAMENTS_LISTING)
		},
		handleParticipantsModal() {
			this.$bvModal.show('group-members-modal')
		},
		getMediaPath,
		convertDateTime,
	},

	created() {
		this.handleGetTournamentDetails(this.$route.params.tournamentId)
		this.handleGetTournamentParticipantsList(
			this.$route.params.tournamentId
		)
	},
}
</script>



<style scoped>
.card-title {
	color: #003071;
	font-size: 18px;
	font-weight: bold !important;
}

.detail-img {
	margin-bottom: 50px;
}

.card-sub-title {
	color: #888888;
	font-size: 14px;
	margin-bottom: 10px;
}

.card-hash {
	color: #888888;
	font-size: 14px;
}

.card-bottom-text {
	font-size: 14px;
	color: black;
}

.edit-button {
	position: absolute;
	top: 50px;
	right: 45px;
	z-index: 1;
}

.img-slide-item {
	height: 394px;
}

.cover-image-container {
	height: 400px !important;
	width: 100% !important;
}

.cover-image {
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.card-body {
	padding: 0 !important;
}

.tournament-location {
	color: black;
	padding-top: 0.2rem;
}
</style> 

<style>
.detail-status-common {
	color: black;

	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
	padding: 0.4rem;
	font-weight: 400;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 0.9rem;
}
.tournament-completed {
	background: #28c76f1f;
}
.tournament-canceled {
	background: #ffdcdc;
}
.tournament-not-started {
	background: rgba(30, 194, 255, 0.12);
}
.tournament-waiting-for-result {
	background: #ffeedc !important;
}
.tournament-on-going {
	background: #2897c71f;
}
.tournament-waiting-for-prize-money {
	background: #283dc71f;
}
</style>



