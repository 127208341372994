<template>
	<div class="blog-detail-wrapper">
		<div>
			<div class="d-flex flex-wrap tab-options-container">
				<button
					class="btn tab-button"
					@click="handleSwitchTab(1)"
					:style="[
						activeTab === 2
							? {
									'background-color': 'transparent',
									color: '#000',
							  }
							: {
									background: '#3599CD',
									color: '#fff',
									'box-shadow':
										'6px 5px 58px -5px rgba(53,153,205,0.75)',
							  },
					]"
				>
					Shop Details
				</button>
				<button
					@click="handleSwitchTab(2)"
					class="btn tab-button"
					:style="[
						activeTab === 1
							? {
									'background-color': 'transparent',
									color: '#000',
							  }
							: {
									background: '#3599CD',
									color: '#fff',
									'box-shadow':
										'6px 5px 58px -5px rgba(53,153,205,0.75)',
							  },
					]"
				>
					Products
				</button>
			</div>

			<b-card no-body>
				<b-tabs pills card>
					<b-tab
						:style="[
							activeTab === 2
								? { display: 'inherit' }
								: { display: 'none' },
						]"
					>
						<div class="d-flex scrollable-container">
							<button
								v-for="(category, cat_index) in storeCategories"
								:key="cat_index"
								class="btn btn-category"
								@click="handleSwitchCategory(category)"
								:style="[
									selectedCategory.Name === category.Name && {
										'border-bottom': '3px solid #3599CD',
										'border-radius': '0px',
									},
								]"
							>
								{{ category.Name }}
							</button>
						</div>
						<b-row>
							<b-col cols="12">
								<template v-if="isFetching">
									<div class="text-center text-primary my-2">
										<b-spinner
											class="align-middle"
										></b-spinner>
									</div>
								</template>

								<b-card
									class="container-card"
									v-if="!isFetching"
								>
									<div class="p-2">
										<b-row cols="1" v-if="!isFetching">
											<b-col
												xl="2"
												lg="3"
												md="3"
												sm="auto"
												v-for="(
													product, product_index
												) in storeProducts"
												:key="product_index"
											>
												<div class="mb-2">
													<b-card
														class="card product-card"
													>
														<div
															class="product-image-container"
														>
															<div
																class="info-icon-container"
																:id="`info-${product.Id}`"
																:key="index"
															>
																<feather-icon
																	icon="AlertCircleIcon"
																	size="21"
																	color="#3599CD"
																/>
																<!-- <b-tooltip triggers="hover" :target="`info-${product.Id}`"
																	:title="product.Description"
																	:delay="{ show: 0, hide: 50 }" /> -->
																<b-popover
																	:target="`info-${product.Id}`"
																	triggers="hover"
																	placement="bottom"
																>
																	<div>
																		<LabelText
																			label="Area"
																			showRightSection
																			labelClasses="font-weight-bolder"
																			v-if="
																				product.Width ||
																				product.Height ||
																				product.Length
																			"
																			:text="`${
																				product.Width
																			} ${
																				ProductDimensionUnit[
																					product
																						.DimensionUnit
																				]
																			} x ${
																				product.Height
																			} ${
																				ProductDimensionUnit[
																					product
																						.DimensionUnit
																				]
																			} x ${
																				product.Length
																			} ${
																				ProductDimensionUnit[
																					product
																						.DimensionUnit
																				]
																			}`"
																			containerClasses="fixed-height"
																		/>

																		<LabelText
																			label="Weight"
																			showRightSection
																			labelClasses="font-weight-bolder"
																			v-if="
																				product.Weight
																			"
																			:text="`${
																				product.Weight
																			} ${
																				WeightUnit[
																					product
																						.WeightUnit
																				]
																			}`"
																			containerClasses="fixed-height"
																		/>

																		<LabelText
																			label="Product Description"
																			showRightSection
																			labelClasses="font-weight-bolder"
																			style="
																				display: block;
																			"
																			v-if="
																				product.Description
																			"
																			containerClasses="fixed-height"
																		>
																			<p>
																				{{
																					product.Description
																				}}
																			</p>
																		</LabelText>
																	</div>
																</b-popover>
															</div>
															<img
																:src="
																	product
																		.DefaultMedia
																		.BasePath +
																	product
																		.DefaultMedia
																		.Path
																"
																class="product-image"
															/>
														</div>

														<b-tooltip
															target="user-story-action-icon"
															triggers="hover"
														>
															<span>Delete</span>
														</b-tooltip>
														<div
															class="product-details-container"
														>
															<div
																class="product-title"
															>
																{{
																	product.Name
																}}
															</div>
															<div>
																<span
																	class="category-title"
																	>Category:</span
																>
																<span>{{
																	product
																		.GearCategory
																		.Name
																}}</span>
															</div>
															<div
																class="d-flex"
																style="
																	justify-content: space-between;
																	align-items: center;
																"
															>
																<div
																	class="product-price"
																>
																	{{
																		`$${product.Price.toFixed(
																			2
																		)}`
																	}}
																</div>
																<img
																	v-b-modal="
																		'user-story-modal'
																	"
																	id="user-story-action-icon"
																	@click="
																		handleDeleteClick(
																			product.Id
																		)
																	"
																	class="delete-icon"
																	:src="
																		deleteIcon
																	"
																/>
															</div>
														</div>
													</b-card>
												</div>
											</b-col>
										</b-row>

										<div
											v-if="!storeProducts.length"
											class="py-5"
										>
											<div
												class="d-flex justify-content-center"
											>
												<img
													:src="emptyImage"
													class="w-25 h-25 text-center"
												/>
											</div>
										</div>
									</div>
								</b-card>
							</b-col>
						</b-row>
					</b-tab>

					<b-tab
						:style="[
							activeTab === 1
								? { display: 'inherit' }
								: { display: 'none' },
						]"
					>
						<b-row>
							<b-col cols="12">
								<template v-if="isFetching">
									<div class="text-center text-primary my-2">
										<b-spinner
											class="align-middle"
										></b-spinner>
									</div>
								</template>

								<b-card
									class="container-card"
									v-if="!isFetching"
								>
									<div class="store-cover-image-container">
										<img
											:src="
												getMediaPath(
													storeData.CoverPhoto
												) ||
												require('../../assets/images/gallery/no-cover-image-placeholder.png')
											"
											class="cover-image"
										/>
									</div>

									<div class="p-2">
										<b-row col="12" gutter="2">
											<b-col sm="auto" lg="4" md="auto">
												<LabelText
													label="Shop Name"
													showRightSection
													textClasses="font-weight-bold"
													:text="
														storeData.CompanyName
													"
													containerClasses="fixed-height"
												/>

												<LabelText
													label="Phone"
													showRightSection
													textClasses="font-weight-bold"
													:text="
														storeData.CountryCode +
														storeData.PhoneNumber
													"
													containerClasses="fixed-height"
												/>
											</b-col>

											<b-col sm="auto" lg="4" md="auto">
												<LabelText
													label="Address"
													showRightSection
													textClasses="font-weight-bold ml-1"
													:text="storeData.Address"
													containerClasses="fixed-height"
												/>

												<LabelText
													label="Zip Code"
													showRightSection
													textClasses="font-weight-bold"
													v-if="storeData.ZipCode"
													:text="storeData.ZipCode"
													containerClasses="fixed-height"
												/>
											</b-col>
											<b-col sm="auto" lg="4" md="auto">
												<LabelText
													label="All Time Earnings"
													showRightSection
													textClasses="font-weight-bold"
													:text="`$${storeData.TotalEarnings}`"
													containerClasses="fixed-height"
												/>

												<LabelText
													label="Sales Tax"
													showRightSection
													textClasses="font-weight-bold"
													:text="`${
														storeData.SalesTax * 100
													}%`"
													containerClasses="fixed-height"
												/>
											</b-col>

											<b-col sm="auto" lg="4" md="auto">
												<LabelText
													label="Email Address"
													showRightSection
													textClasses="font-weight-bold"
													:text="storeData.Email"
													containerClasses="fixed-height"
												/>
											</b-col>

											<b-col sm="auto" lg="6" md="auto">
												<LabelText
													label="Status"
													showRightSection
													textClasses="font-weight-bold"
													:text="
														getVerificationStatus(
															storeData.Status
														)
													"
													containerClasses="fixed-height"
												/>
											</b-col>

											<b-col sm="auto" lg="12" md="auto">
												<LabelText
													label="Shop Description"
													showRightSection
													textClasses="font-weight-bolder"
													style="display: block"
													containerClasses="fixed-height mt-2 "
												>
													<p>
														{{
															storeData.Description
														}}
													</p>
												</LabelText>
											</b-col>
										</b-row>

										<hr />
										<b-row col="12">
											<b-col
												lg="12"
												md="12"
												sm="12"
												v-if="
													storeData.Medias.length > 0
												"
											>
												<Title text="Documents" />
											</b-col>
										</b-row>

										<div
											class="d-flex"
											style="
												justify-content: space-between;
											"
										>
											<div
												class="d-flex justify-content-md-center justify-content-lg-start justify-content-sm-center align-items-center flex-wrap"
												v-if="!isFetching"
											>
												<FileCard
													showClickableImage
													v-for="item in storeData.Medias"
													:thumbnailImage="
														getMediaPath(item.Media)
													"
													:key="item.id"
													:item="item"
												>
													{{ item.Id }}
												</FileCard>
											</div>

											<div
												class="d-flex"
												style="
													flex-direction: column;
													justify-content: end;
												"
											>
												<Button
													:clickHandler="
														() =>
															handleVerifyClick()
													"
													size="md"
													:buttonVariant="`${ShopStatus.getLabelClass(
														storeData.Status
													)}`"
													classes="btn-width mb-1"
													:buttonText="`${ShopStatus.getDisplayTextKey(
														storeData.Status
													)}`"
												/>

												<button
													class="btn btn-outline-secondary action-suspend-btn"
													@click="
														handleSuspendClick()
													"
												>
													{{
														storeData.Suspended
															? 'Unsuspend Shop'
															: 'Suspend Shop'
													}}
												</button>
											</div>
										</div>
									</div>
								</b-card>
							</b-col>
						</b-row>
					</b-tab>
				</b-tabs>

				<ConfirmModal
					id="verify-store-modal"
					:isLoading="isProcessing"
					:isSuccess="verifySuccess"
					successText="Verification status updated successfully!"
					:onOkayPress="handleOkayPress"
					:onYesPress="hannleConfirmVerify"
					messageText="Are you sure you want to update verification status?"
				/>

				<ConfirmModal
					id="suspend-store-modal"
					:isLoading="isProcessing"
					:isSuccess="suspendSuccess"
					successText="Suspension status updated successfully!"
					:onOkayPress="handleOkayPress"
					:onYesPress="handleConfirmSuspend"
					messageText="Are you sure you want to update suspension status?"
				/>

				<ConfirmModal
					id="delete-product-modal"
					:isLoading="isProcessing"
					:isSuccess="deleteSuccess"
					successText="Product deleted successfully!"
					:onOkayPress="handleOkayPress"
					:onYesPress="handleYesPress"
					messageText="Are you sure you want to delete this product?"
				/>
			</b-card>
		</div>
	</div>
</template>

<script>
import {
	BFormInput,
	BMedia,
	BAvatar,
	BMediaAside,
	BMediaBody,
	BImg,
	BLink,
	BFormGroup,
	BInputGroup,
	BInputGroupAppend,
	BCard,
	BCardText,
	BRow,
	BCol,
	BBadge,
	BDropdown,
	BDropdownItem,
	BForm,
	BFormTextarea,
	BFormCheckbox,
	BButton,
	BCardImg,
	BSpinner,
	BCarousel,
	BCarouselSlide,
	BTooltip,
	BPopover,
} from 'bootstrap-vue'
import { textUrlToHtmlLink } from 'text-url-to-html-link'
import FileCard from '@/components/FileCard.vue'
import DeleteIcon from '../../assets/images/icons/delete.svg'
import {
	deleteProduct,
	getStoreDetails,
	getProducts,
	getCategories,
	updateStoreStatus,
} from '../../api/api.service'
import { getMediaPath } from '../../utils/commonUtils'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import Button from '../../components/Button.vue'
import Title from '../../components/Title.vue'
import LabelText from '../../components/LabelText.vue'
import UserInfo from '../../components/UserInfo.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import BasicModal from '@/components/BasicModal.vue'
import { APP_ROUTES } from '@/helpers/routeHelpers'
import VERIFICATION_STATUS from '@/constants/VerificationStatus'
import SUSPENDED_STATUS from '@/constants/SuspendedStatus'
import { ShopStatus } from '@/constants'
import WeightUnit from '@/constants/WeightUnit'
import ProductDimensionUnit from '@/constants/ProductDimensionUnit'

export default {
	name: 'StoreDetails',
	components: {
		BFormInput,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BLink,
		BCard,
		BRow,
		BCol,
		BFormGroup,
		BInputGroup,
		BInputGroupAppend,
		BImg,
		BBadge,
		BCardText,
		BDropdown,
		BForm,
		BDropdownItem,
		BFormTextarea,
		BFormCheckbox,
		BButton,
		BCardImg,
		BSpinner,
		BCarousel,
		BCarouselSlide,
		Button,
		Title,
		LabelText,
		UserInfo,
		ConfirmModal,
		BasicModal,
		FileCard,
		BTooltip,
		BPopover,
	},

	data() {
		return {
			ShopStatus,
			dateTimeFormat,
			isFetching: false,
			isFetchingMembers: false,
			storeData: {},
			isProcessing: false,
			deleteSuccess: false,

			suspendSuccess: false,
			verifySuccess: false,

			selectedId: null,
			storeProducts: [],
			deleteIcon: DeleteIcon,
			activeTab: 1,
			emptyImage: require('@/assets/images/gallery/no-data.png'),
			storeCategories: [{ Name: 'All' }],
			selectedCategory: { Name: 'All' },
			WeightUnit,
			ProductDimensionUnit,
		}
	},
	methods: {
		handleConvertTextLinksToTag(data = '') {
			if (!data) {
				return 'No description to show!'
			}
			let text = textUrlToHtmlLink(data)
			return text
		},
		async getStoreDetails(id) {
			try {
				this.isFetching = true
				let res = await getStoreDetails(id)
				this.storeData = { ...res }
			} catch (err) {
			} finally {
				this.isFetching = false
			}
		},

		handleSwitchTab(tab) {
			this.activeTab = tab
		},
		handleSwitchCategory(category) {
			this.selectedCategory = category
			this.getStoreProducts(this.$route.params.storeId, category.Id)
		},
		async getStoreProducts(id, category) {
			try {
				this.isFetching = true
				let params = { VendorId: id }
				if (category) {
					params.GearCategoryId = category
				}
				let res = await getProducts(params)
				this.storeProducts = [...res.Products]
			} catch (err) {
			} finally {
				this.isFetching = false
			}
		},
		async getStoreCategories(id) {
			try {
				this.isFetching = true
				let res = await getCategories({ VendorId: id })
				this.storeCategories = [{ Name: 'All' }, ...res.GearCategories]
			} catch (err) {
			} finally {
				this.isFetching = false
			}
		},

		handleYesPress() {
			this.isProcessing = true

			deleteProduct(this.$route.params.storeId)
				.then((res) => {
					this.deleteSuccess = true
				})
				.finally(() => {
					this.resetState()
				})
		},
		handleVerifyStore(status) {
			this.isProcessing = true
			updateStoreStatus(this.$route.params.storeId, {
				VerifyStatus: status,
			})
				.then((res) => {
					this.storeData.Status = status
					this.verifySuccess = true
				})
				.finally(() => {
					this.resetState()
				})
		},
		handleSuspendStore(status) {
			this.isProcessing = true
			updateStoreStatus(this.$route.params.storeId, { Suspended: status })
				.then((res) => {
					this.storeData.Suspended = status
					this.suspendSuccess = true
				})
				.finally(() => {
					this.resetState()
				})
		},
		handleDeleteClick(id) {
			this.selectedId = id
			this.$bvModal.show('delete-product-modal')
		},
		handleVerifyClick() {
			this.$bvModal.show('verify-store-modal')
		},
		handleSuspendClick() {
			this.$bvModal.show('suspend-store-modal')
		},
		handleConfirmSuspend: function () {
			this.handleSuspendStore(!this.storeData.Suspended)
		},
		hannleConfirmVerify: function () {
			this.handleVerifyStore(this.storeData.Status == 1 ? 2 : 1)
		},
		handleOkayPress() {
			this.resetState()
		},
		getMediaPath,
		getVerificationStatus(data) {
			let status = ''
			console.log(data, status, 'sadaskdjasda')
			Object.keys(VERIFICATION_STATUS).find((key) => {
				if (VERIFICATION_STATUS[key] === data) {
					status = key
				}
			})
			return status
		},

		getSuspendedStatus(data) {
			let status = ''
			Object.keys(SUSPENDED_STATUS).find((key) => {
				if (SUSPENDED_STATUS[key] === data) {
					status = key
				}
			})
			return status
		},
		resetState() {
			this.isProcessing = false
			this.selectedId = null
			this.$bvModal.hide('delete-product-modal')
			this.$bvModal.hide('suspend-store-modal')
			this.$bvModal.hide('verify-store-modal')
			setTimeout(() => {
				this.verifySuccess = false
				this.suspendSuccess = false
				this.deleteSuccess = false
			}, 500)
		},
		convertDateTime,
	},

	created() {
		this.getStoreDetails(this.$route.params.storeId)
		this.getStoreProducts(this.$route.params.storeId)
		this.getStoreCategories(this.$route.params.storeId)
	},
}
</script>



<style scoped>
.card-title {
	color: #003071;
	font-size: 18px;
	font-weight: bold !important;
}

.card-sub-title {
	color: #888888;
	font-size: 14px;
	margin-bottom: 10px;
}

.card-bottom-text {
	font-size: 14px;
	color: black;
}

.edit-button {
	position: absolute;
	top: 50px;
	right: 45px;
	z-index: 1;
}

.img-slide-item {
	height: 394px;
}

.store-cover-image-container {
	height: 380px !important;
	width: 100% !important;
}

.cover-image {
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.card-body {
	padding: 10px !important;
}

.store-status {
	color: black;
	padding-top: 0.2rem;
}

.product-image-container {
	height: 134px;
}

.product-image {
	width: 100%;
	height: 100%;
	border-radius: 6px;
	object-fit: cover;
	object-position: center;
}

.product-details-container {
	padding: 5px;
}

.product-title {
	color: #000;
	font-size: 14px;
	font-weight: bold !important;
}

.product-price {
	color: #3599cd;
	font-size: 14px;
	font-weight: bold !important;
}

.product-card {
	background-color: #f7f7f7;
}

.delete-button {
	padding: 0 !important;
	color: #ff6060;
}

.tab-options-container {
	padding: 0px 0 20px;
}

.tab-button:nth-child(2) {
	margin-left: 20px;
}

.scrollable-container {
	padding: 16px 16px 1px;
	border-bottom: 1px solid #e5e5e5;
	overflow-x: scroll;
}

.scrollable-container::-webkit-scrollbar {
	width: 2px;
	height: 6px;
}

.scrollable-container::-webkit-scrollbar-thumb {
	background-color: rgb(190, 190, 190);
	border-radius: 5px;
}

.action-verify-btn {
	min-width: 210px;
	background-color: #2fbe3d !important;
	border-color: #2fbe3d !important;
}

.action-suspend-btn {
	min-width: 210px;
	border: 1px solid #6c757d !important;
	color: #82868b;
}

.info-icon-container {
	position: absolute;
	right: 18px;
	top: 18px;
	background: white;
	padding: 3px 3px;
	border-radius: 5px;
}

.fixed-height {
	margin-bottom: 0 !important;
}

.justify-end {
	justify-content: end;
	padding: 0 20px;
}

.category-title {
	font-weight: bolder;
	color: #000;
}
::v-deep .btn-width {
	min-width: 210px;
}
</style> 

