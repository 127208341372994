var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"5","sm":"12"}},[_c('LabelText',{attrs:{"label":"Reported By","showRightSection":"","textClasses":"font-weight-bolder"}},[_c('UserInfo',{attrs:{"iconSize":"md","fullName":_vm.currentReport.User.FullName || '-',"avatarImage":_vm.getMediaPath(_vm.currentReport.User.Media) || '-'}})],1),(
							_vm.currentReport.EntityType === 22 ||
							_vm.currentReport.EntityType === 23
						)?_c('LabelText',{attrs:{"label":'Reported ' +
							_vm.ReportEntityType.getDisplayTextKey(
								_vm.currentReport.EntityType
							),"showRightSection":"","textClasses":"font-weight-bolder"}},[_c('p',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.getUnknownData(_vm.currentReport))+" ")])]):_vm._e(),(
							_vm.currentReport.EntityType !== 23 &&
							_vm.currentReport.EntityType !== 22
						)?_c('LabelText',{attrs:{"label":"Reported Person","showRightSection":"","textClasses":"font-weight-bolder"}},[_c('UserInfo',{attrs:{"iconSize":"md","fullName":_vm.getReportedPerson(_vm.currentReport, 'FullName'),"avatarImage":_vm.getReportedPerson(_vm.currentReport, 'Media')}})],1):_vm._e(),_c('LabelText',{attrs:{"label":"Date","text":_vm.convertDateTime({
								date: _vm.currentReport.CreatedAt,
								customFormat:
									_vm.dateTimeFormat.dateWithYearMonthFormat,
								dateOnly: true,
							}),"textClasses":"font-weight-bolder"}}),(_vm.currentReport.ReportType == 6)?_c('div',[_c('p',{staticClass:"review-title"},[_vm._v("Report:")]),_c('p',{staticClass:"font-weight-bolder report"},[_vm._v(" "+_vm._s(_vm.currentReport.Description || 'No Content')+" ")])]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4","sm":"12"}},[(
							!_vm.currentReport.isHideLocation &&
							_vm.currentReport.EntityType !== 22 &&
							_vm.currentReport.EntityType !== 23 &&
							_vm.currentReport.EntityType !== 21 &&
							_vm.currentReport.EntityType !== 20 &&
							!_vm.currentReport.Tournament
						)?_c('div',[_c('LabelText',{attrs:{"label":"Location","text":_vm.currentReport.EntityType ==
								_vm.ReportEntityType.Location
									? _vm.currentReport.Location.Address
									: _vm.currentReport.Location || '-',"textClasses":"font-weight-bolder"}})],1):_vm._e(),(_vm.currentReport.Tournament)?_c('div',[_c('LabelText',{attrs:{"label":"Location","text":_vm.currentReport.Tournament.LocationAddress || '-',"textClasses":"font-weight-bolder"}})],1):_vm._e(),(_vm.currentReport.EntityType === 22)?_c('div',[_c('LabelText',{attrs:{"label":"Location","text":_vm.currentReport.Vendor
									? _vm.currentReport.Vendor.Address
									: '-',"textClasses":"font-weight-bolder"}})],1):_vm._e(),(_vm.currentReport.isHideLocation)?_c('div',[_c('LabelText',{attrs:{"label":"Group Name","text":_vm.currentReport.GroupName || '-',"textClasses":"font-weight-bolder"}})],1):_vm._e(),_c('LabelText',{attrs:{"label":"Report Type","text":_vm.reportType.getDisplayTextKey(
								_vm.currentReport.ReportType
							),"textClasses":"font-weight-bolder"}}),(_vm.currentReport.EntityType === 21)?_c('LabelText',{attrs:{"label":"Post Comment","text":_vm.getData(_vm.currentReport, 'Comment'),"textClasses":"font-weight-bolder"}}):_vm._e(),(_vm.currentReport.EntityType === 20)?_c('LabelText',{attrs:{"label":"Post","text":_vm.getData(_vm.currentReport, 'Description'),"textClasses":"font-weight-bolder"}}):_vm._e(),(
							_vm.currentReport.EntityType ==
							_vm.ReportEntityType.GroupPostComment
						)?_c('LabelText',{attrs:{"label":_vm.currentReport.Type,"text":_vm.currentReport.GroupPostComment.Comment,"textClasses":"font-weight-bolder"}}):_vm._e(),(_vm.currentReport.EntityType == 15)?_c('LabelText',{attrs:{"label":_vm.currentReport.Type,"text":_vm.comment,"textClasses":"font-weight-bolder"}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"3","sm":"12"}},[_c('div',{staticClass:"mb-2 text-right text-md-left"},[(
								_vm.currentReport.Status === _vm.reportStatus.ToBeViewed
							)?_c('Button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("action-" + (_vm.currentReport.Id) + "-modal")),expression:"`action-${currentReport.Id}-modal`"}],attrs:{"buttonText":"Remove From Listing","buttonVariant":"danger","classes":"mt-1 mb-1 w-100","clickHandler":function () { return _vm.setModalStatus(_vm.reportStatus.ListingRemoved); }}}):_vm._e(),(
								_vm.currentReport.Status === _vm.reportStatus.ToBeViewed
							)?_c('Button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("action-" + (_vm.currentReport.Id) + "-modal")),expression:"`action-${currentReport.Id}-modal`"}],attrs:{"buttonText":"Ignore","buttonVariant":"outline","classes":"mt-1 mb-1 w-100 btn-outline-danger","clickHandler":function () { return _vm.setModalStatus(_vm.reportStatus.ReportRejected); }}}):_vm._e()],1)])],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"3","sm":"12"}},[_c('Button',{attrs:{"buttonText":"View Reporter Profile","buttonVariant":"outline","classes":"mt-1 mb-1 w-100 btn-outline-grey","clickHandler":function () { return _vm.viewUserDetails(_vm.currentReport.User.Id); }}})],1),(
						_vm.currentReport.EntityType !== 22 &&
						_vm.currentReport.EntityType !== 23
					)?_c('b-col',{attrs:{"cols":"12","md":"12","lg":"3","sm":"12"}},[_c('Button',{attrs:{"buttonText":"Reported Person Profile","buttonVariant":"outline","classes":"mt-1 mb-1 w-100 btn-outline-grey","clickHandler":function () { return _vm.viewUserDetails(
									_vm.getReportedPerson(_vm.currentReport, 'Id')
								); }}})],1):_vm._e(),(
						_vm.currentReport.EntityType === 22 ||
						_vm.currentReport.EntityType === 23
					)?_c('b-col',{attrs:{"cols":"12","md":"12","lg":"3","sm":"12"}},[_c('Button',{attrs:{"buttonText":'Reported ' +
							_vm.ReportEntityType.getDisplayTextKey(
								_vm.currentReport.EntityType
							),"buttonVariant":"outline","classes":"mt-1 mb-1 w-100 btn-outline-grey","clickHandler":function () { return _vm.viewEntityDetails(_vm.currentReport); }}})],1):_vm._e(),(
						_vm.currentReport.EntityType != 22 &&
						_vm.currentReport.EntityType != 23 &&
						_vm.currentReport.EntityType !== 20 &&
						_vm.currentReport.EntityType !== 21 &&
						!_vm.isHideViewDetailsBtn &&
						_vm.currentReport.Status != _vm.reportStatus.ListingRemoved
					)?_c('b-col',{attrs:{"cols":"12","md":"12","lg":"3","sm":"12"}},[_c('Button',{attrs:{"buttonText":_vm.viewDetailsBtnText,"buttonVariant":"outline","classes":"mt-1 mb-1 w-100 btn-outline-grey","clickHandler":_vm.viewDetails}})],1):_vm._e(),_c('ConfirmModal',{attrs:{"isLoading":_vm.isLoading,"isSuccess":_vm.isModerationStatusChangedSuccess,"onOkayPress":_vm.handleOkayPress,"successText":"Success !","onYesPress":function () { return _vm.updateModerationStatus(_vm.status); },"id":("action-" + (_vm.currentReport.Id) + "-modal"),"messageText":("Are you sure you want to " + _vm.statusText + " this " + _vm.updateMessage + " ?"),"messageIcon":require('@/assets/images/icons/restrict-icon.svg')}}),(_vm.currentReport.EntityType === 23)?_c('BasicModal',{attrs:{"id":"entity-details-modal","modalTitle":_vm.ReportEntityType.getDisplayTextKey(
							_vm.currentReport.EntityType
						) + ' Details'}},[_c('b-card',{staticClass:"card entity-card"},[_c('div',{staticClass:"entity-image-container"},[_c('img',{staticClass:"entity-image",attrs:{"src":_vm.getMediaPath(_vm.product.DefaultMedia)},on:{"error":_vm.replaceByDefault}})]),_c('div',{staticClass:"entity-details-container"},[_c('LabelText',{attrs:{"label":"Product Name","text":_vm.product.Name,"textClasses":"font-weight-bolder","containerClasses":"fixed-height"}}),_c('LabelText',{attrs:{"label":"Category","text":_vm.product.GearCategory.Name,"textClasses":"font-weight-bolder","containerClasses":"fixed-height"}}),_c('LabelText',{attrs:{"label":"Price","text":_vm.product.PriceCurrency + ' ' + _vm.product.Price,"textClasses":"font-weight-bolder","containerClasses":"fixed-height"}}),_c('LabelText',{attrs:{"label":"Shop Name","text":_vm.product.Vendor.CompanyName,"textClasses":"font-weight-bolder","containerClasses":"fixed-height"}}),_c('LabelText',{staticStyle:{"display":"block"},attrs:{"label":"Shop Description","showRightSection":"","textClasses":"font-weight-bolder","containerClasses":"fixed-height mt-1"}},[_c('p',[_vm._v(_vm._s(_vm.product.Description))])])],1)])],1):_vm._e(),(
						_vm.currentReport.EntityType != 15 &&
						_vm.currentReport.EntityType != 16 &&
						_vm.currentReport.EntityType != 12 &&
						_vm.currentReport.EntityType != 4 &&
						_vm.currentReport.EntityType != 17 &&
						_vm.currentReport.EntityType != 6 &&
						_vm.currentReport.EntityType != 5
					)?_c('b-col',{attrs:{"cols":"12","md":"12","lg":"3","sm":"12"}},[(
							_vm.currentReport.Status !=
								_vm.reportStatus.ListingRemoved &&
							!_vm.currentReport.Tournament &&
							!_vm.currentReport.TournamentPost &&
							!_vm.currentReport.TournamentPostComment
						)?_c('div',[_c('Button',{attrs:{"buttonText":_vm.viewDetailsBtnText,"buttonVariant":"outline","classes":"mt-1 mb-1 w-100 btn-outline-grey","clickHandler":_vm.viewDetails}})],1):_vm._e()]):_vm._e(),_c('ConfirmModal',{attrs:{"isLoading":_vm.isLoading,"isSuccess":_vm.isModerationStatusChangedSuccess,"onOkayPress":_vm.handleOkayPress,"successText":"Success !","onYesPress":function () { return _vm.updateModerationStatus(_vm.status); },"id":("action-" + (_vm.currentReport.Id) + "-modal"),"messageText":("Are you sure you want to " + _vm.statusText + " this " + _vm.updateMessage + "?"),"messageIcon":require('@/assets/images/icons/restrict-icon.svg')}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }