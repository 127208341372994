<template>
	<div class="table-container">
		<b-card body-class="card-body-style">
			<search-header
				searchInputClasses="w-25"
				:searchText="searchText"
				:onSearchChange="handleSearchChange"
				:pageLimitValue="pageLimit"
				:onPageLimitChange="handlePageLimitChange"
				:showStatusFilter="false"
			>
				<template #right-header-content>
					<Button
						buttonText="+ Create New Code"
						buttonVariant="primary"
						size="md"
						classes="btn-outline-grey ml-2"
						:clickHandler="
							() => $bvModal.show('create-referral-modal')
						"
					/>
				</template>
			</search-header>
			<data-table
				:perPageLimit="pageLimit"
				entriesName="Referrals"
				:perPage="data.length"
				:isLoading="isFetching"
				:showPagination="true"
				:config="config"
				:data="data"
				:selectedPageValue="page"
				:onHandlePageChange="handlePageClick"
				:onSortClick="handleSortChange"
				:totalRecords="total"
			>
				<!--#ID-->
				<template #cell(No)="data">
					<span class="font-weight-bolder">
						{{ data.index + 1 }}
					</span>
				</template>
				<!--#USER NAME-->
				<template #cell(FullName)="data">
					<span class="text-nowrap">
						<UserInfo
							iconSize="md"
							:fullName="data.item.User.FullName || '-'"
							:subText="data.item.User.Email || '-'"
							:avatarImage="
								getMediaPath(data.item.User.Media) || '-'
							"
						/>
					</span>
				</template>

				<template #cell(Code)="data">
					<span class="font-weight-bolder"> {{ data.value }} </span>
				</template>

				<!--#DATE CREATED-->
				<template #cell(CreatedAt)="data">
					<span class="text-nowrap">
						{{
							convertDateTime({
								date: data.value,
								customFormat: dateTimeFormat.creationDateFormat,
								dateOnly: true,
							})
						}}
					</span>
				</template>

				<!--#USAGE-->
				<template #cell(TimesUsed)="data">
					<template v-if="data.item.TimesUsed > 0">
						<b-badge
							v-b-modal="'user-used-code-modal'"
							pill
							class="usage-text font-weight-normal"
							@click="
								() => hanldeModalOfUserWhoUseCode(data.item.Id)
							"
						>
							Used {{ data.item.TimesUsed }} times</b-badge
						>
					</template>
					<div v-else>-</div>
				</template>

				<!--#SUBSCRIBED-->
				<template #cell(Subscribed)="data">
					<b-spinner
						v-if="subscribingID == data.item.Id"
						variant="primary"
						class="spinner"
					/>
					<div v-else class="d-flex">
						<switch-button
							:isChecked="data.item.Subscription"
							:isDisable="subscriptionLoading"
							:onChange="() => handleSubscription(data.item.Id)"
						/>

						<span class="font-weight-bolder">
							{{ handleSubscribedText(data.item.Subscription) }}
						</span>
					</div>
				</template>

				<!--#ACTIONS-->
				<template #cell(Actions)="data">
					<feather-icon
						v-b-modal="'delete-referral-modal'"
						:icon="`Trash2Icon`"
						class="delete-icon cursor-pointer mr-1"
						size="16"
						@click="handleDeleteClick(data.item.Id)"
						v-b-tooltip.hover.top="'Delete'"
					/>
					<feather-icon
						v-if="downloadingReportId == data.item.Id"
						:icon="`LoaderIcon`"
						size="16"
					/>

					<feather-icon
						v-else
						:icon="`DownloadIcon`"
						class="detail-icon cursor-pointer"
						size="16"
						@click="handleDownloadReport(data.item.Id)"
						v-b-tooltip.hover.top="'Download Report'"
					/>
				</template>
			</data-table>
		</b-card>
		<CreateReferralModal
			:handleUsersSearch="handleUsersSearch"
			:handleSetReferralCode="handleSetReferralCode"
			:handleSetSelectedMember="handleSetSelectedMember"
			:handleCreateNewCodeClick="handleCreateNewCodeClick"
			:referralCode="referralCode"
			:membersList="membersList"
			:memberSelected="memberSelected"
			:onSubmit="submitForm"
			:createReferralCodeLoading="createReferralCodeLoading"
			:handleModalClose="handleModalClose"
			:isReferraCodeInvalid="isReferraCodeInvalid"
		/>
		<ConfirmModal
			id="delete-referral-modal"
			:isLoading="isDeleting"
			:isSuccess="deleteSuccess"
			successText="Referral Code Deleted Successfully !"
			:onOkayPress="handleOkayPress"
			:onYesPress="handleDelete"
			messageText="Are you sure you want to delete this referral code?"
		/>

		<!-- Modal for userd who use code -->
		<BasicModal id="user-used-code-modal" modalTitle="Used By" size="sm">
			<template #showAllViewsUsers>
				<UserWhoUsedCodeModal :referralId="referralCodeId" />
			</template>
		</BasicModal>
	</div>
</template>

<script>
import {
	BCard,
	BCardBody,
	BBadge,
	BLink,
	BTooltip,
	BFormInput,
	BFormGroup,
	VBTooltip,
	BForm,
	BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

import CreateReferralModal from './CreateReferralModal.vue'
import UserWhoUsedCodeModal from './UserWhoUsedCodeModal.vue'
import DataTable from '../../components/data-table/DataTable.vue'
import UserInfo from '../../components/UserInfo.vue'
import SearchHeader from '../../components/SearchHeader.vue'
import Button from '../../components/Button.vue'
import SwitchButton from '../../components/SwitchButton.vue'
import BasicModal from '../../components/BasicModal.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import showToast from '../../hooks/useToast'
import { SortOrder } from '../../constants'
import { useLocalPagination } from '../../hooks/useLocalPagination'
import { dateTimeFormat, convertDateTime } from '@/utils/dateFormatUtils'
import { getMediaPath } from '../../utils/commonUtils'
import { downloadXlsData } from '../../utils/downloadUtils'
import {
	getCreatedReferralsListing,
	deleteReferralCode,
	createReferralCode,
	getUserList,
	markReferralUsersAsSubscribed,
	getRefferalUserReport,
} from '../../api/api.service'

let config = [
	{
		label: 'No',
		key: 'No',
	},
	{
		label: 'USER NAME',
		key: 'FullName',
	},
	{
		label: 'REFERRAL CODE',
		key: 'Code',
	},
	{
		label: 'DATE CREATED',
		key: 'CreatedAt',
		// isSortable: true,
	},

	{
		label: 'USAGE',
		key: 'TimesUsed',
		// isSortable: true,
	},

	{
		label: 'SUBSCRIBED',
		key: 'Subscribed',
		// isSortable: true,
	},

	{
		label: 'ACTIONS',
		key: 'Actions',
	},
]

export default {
	name: 'ReferralListing',
	components: {
		UserInfo,
		DataTable,
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		SearchHeader,
		BFormInput,
		Button,
		BFormGroup,
		BasicModal,
		VBTooltip,
		BForm,
		ConfirmModal,
		CreateReferralModal,
		UserWhoUsedCodeModal,
		SwitchButton,
		BSpinner,
	},

	setup(props, context) {
		const referralCode = ref('')
		const memberSelected = ref({})
		const membersList = ref([])
		const createReferralCodeLoading = ref(false)
		const referralCodeId = ref(null)
		const isDeleting = ref(false)
		const deleteSuccess = ref(false)
		const isReferraCodeInvalid = ref(null)
		const subscriptionLoading = ref(false)
		const subscribingID = ref('')
		const downloadingReportId = ref(false)

		const {
			data,
			isFetching,
			total,
			page,
			searchText,
			pageLimit,
			sortOrder,
			sortColumn,
			handleSortChange,
			handleSearchChange,
			handlePageClick,
			handlePageLimit,
			request,
		} = useLocalPagination(
			getCreatedReferralsListing,
			() => ({
				Direction: SortOrder.DESC,
				Column: 'CreatedAt',
			}),
			[],
			'Q',
			'ReferralCodes',
			'TotalReferralCodes'
		)

		const hanldeModalOfUserWhoUseCode = (id) => {
			referralCodeId.value = id
		}

		const handleUsersSearch = async (search = '') => {
			try {
				let result = await getUserList({
					...(search && { Q: search }),
					Page: 1,
					Limit: 10,
					Direction: 'DESC',
					Column: 'CreatedAt',
				})
				if (result.Users.length) {
					let optionForSelect = result.Users.map((item) => ({
						title: item?.FullName,
						value: item?.Id,
					}))
					membersList.value = [...optionForSelect]
				}
			} catch (error) {
				console.log('Error', error)
			} finally {
			}
		}

		const handleModalClose = () => {
			referralCode.value = ''
			memberSelected.value = {}
			isReferraCodeInvalid.value = null
		}

		const handleSetReferralCode = (value) => {
			console.log(value)
			let regex = new RegExp('^[a-zA-Z0-9]+$')

			if (regex.test(value) && value?.length >= 5 && value.length <= 10) {
				isReferraCodeInvalid.value = true
				referralCode.value = value
			} else {
				isReferraCodeInvalid.value = false
				referralCode.value = value
			}
		}

		const handleSetSelectedMember = (obj) => {
			memberSelected.value = obj
		}

		function handleCreateNewCodeClick() {
			referralCode.value = ''
			this.$bvModal.show('add-referral-code-modal')
		}

		const handleDeleteClick = (id) => {
			referralCodeId.value = id
		}

		const handlePageLimitChange = (val) => {
			page.value = 1

			handlePageLimit(val)
		}

		const handlePageChange = (pageNumber) => {
			handlePageClick(pageNumber)
		}

		const handleDelete = async () => {
			try {
				isDeleting.value = true
				let res = await deleteReferralCode(referralCodeId.value)
				data.value = data.value.filter(
					(item) => item.Id !== referralCodeId.value
				)

				total.value = total.value - 1

				if (total.value && !Boolean(data.value.length)) {
					page.value = page.value - 1
				}
				deleteSuccess.value = true

				showToast(context.root, {
					Title: 'Deleted Successfully',
					Icon: 'CheckCircleIcon',
					Text: res.Message,
					Variant: 'success',
				})
			} catch (err) {
				showToast(context.root, {
					Title: 'Error',
					Variant: 'danger',
					Icon: 'XCircleIcon',
					Text: err.Message,
				})
			} finally {
				isDeleting.value = false
			}
		}

		const handleOkayPress = () => {
			deleteSuccess.value = false
			referralCodeId.value = null
		}

		async function submitForm() {
			console.log('isReferraCodeInvalid', isReferraCodeInvalid.value)
			if (
				!memberSelected.value.value ||
				!referralCode.value ||
				!isReferraCodeInvalid.value
			)
				return
			createReferralCodeLoading.value = true

			let payload = {
				UserId: memberSelected.value.value,
				Code: referralCode.value,
				Subscription: false,
			}

			createReferralCode(payload)
				.then((res) => {
					showToast(context.root, {
						Title: 'Created Successfully',
						Icon: 'CheckCircleIcon',
					})
					context.root.$bvModal.hide('create-referral-modal')
					request()
				})
				.catch((err) => {
					showToast(context.root, {
						Title: 'Error',
						Variant: 'danger',
						Icon: 'XCircleIcon',
						Text: err.Message,
					})
				})
				.finally(() => {
					createReferralCodeLoading.value = false
				})
		}

		const handleSubscription = (id = '') => {
			if (!id) return

			subscribingID.value = id
			let payload = {}

			let reqObj = data.value.find(
				(item) => item.Id == subscribingID.value
			)

			let hasSubscribed = reqObj?.Subscription

			if (hasSubscribed) {
				payload = {
					Subscription: false,
				}
			} else {
				payload = {
					Subscription: true,
				}
			}

			subscriptionLoading.value = true

			markReferralUsersAsSubscribed(id, payload)
				.then((res) => {
					data.value = data.value.map((item) => {
						if (item.Id == subscribingID.value) {
							item.Subscription = !item.Subscription
						}
						return item
					})

					showToast(context.root, {
						Title: payload.Subscription
							? 'Subscribed !'
							: 'Unsubscribed',
						Icon: 'CheckCircleIcon',
					})
				})
				.catch((err) => {
					showToast(context.root, {
						Title: 'Error',
						Icon: 'XCircleIcon',
						Text: err.Message,
						Variant: 'danger',
					})
				})
				.finally(() => {
					subscriptionLoading.value = false
					subscribingID.value = ''
				})
		}

		const handleSubscribedText = (isSubscribed = false) => {
			let text = ''
			if (isSubscribed) {
				text = 'Yes'
			} else {
				text = 'No'
			}
			return text
		}

		const handleDownloadReport = (referralId = '') => {
			downloadingReportId.value = referralId.toString()
			getRefferalUserReport(referralId)
				.then((res) => {
					downloadXlsData(res)
				})
				.catch((err) => {
					showToast(context.root, {
						Title: 'Error',
						Icon: 'XCircleIcon',
						Text: 'Error occcured while downloading the file',
						Variant: 'danger',
					})
				})
				.finally(() => {
					downloadingReportId.value = false
				})
		}
		return {
			downloadingReportId,
			referralCodeId,
			getMediaPath,
			dateTimeFormat,
			convertDateTime,
			config,
			submitForm,
			data,
			isFetching,
			total,
			page,
			searchText,
			pageLimit,
			isDeleting,
			sortOrder,
			sortColumn,
			deleteSuccess,
			handleSortChange,
			handleSearchChange,
			handlePageChange,
			handlePageLimitChange,
			handleCreateNewCodeClick,
			handleDeleteClick,
			handleOkayPress,
			handleDelete,
			referralCode,
			memberSelected,
			membersList,
			handleUsersSearch,
			handleSetReferralCode,
			handleSetSelectedMember,
			handleCreateNewCodeClick,
			createReferralCodeLoading,
			handleModalClose,
			handlePageClick,
			hanldeModalOfUserWhoUseCode,
			isReferraCodeInvalid,
			handleSubscription,
			subscribingID,
			subscriptionLoading,
			handleSubscribedText,
			handleDownloadReport,
		}
	},

	directives: {
		'b-tooltip': VBTooltip,
		Ripple,
	},
}
</script>
<style scoped>
::v-deep .detail-icon {
	color: #888888;
}
.delete-icon {
	color: red;
}

.usage-text {
	background: #28c76f1f;
	color: black;
	width: 192px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-body-style {
	padding: 0 !important;
}
.table-container {
	margin-top: -10px;
}
.modal-content {
	display: none !important;
}
.modal-body {
	height: 300px !important;
}
</style>

