import { injectBaseConstantMethods } from './BaseConstants'
export const ShopStatus = {
    VERIFY_SHOP: 1,
    UNVERIFY_SHOP: 2,
}

const displayTextKeys = {
    [ShopStatus.VERIFY_SHOP]: 'Unverify Shop',
    [ShopStatus.UNVERIFY_SHOP]: 'Verify Shop',
}

const labelClass = {
    [ShopStatus.VERIFY_SHOP]: 'danger',
    [ShopStatus.UNVERIFY_SHOP]: 'success',
}

export default injectBaseConstantMethods(
    ShopStatus,
    displayTextKeys,
    labelClass
)
