<template>
	<div>
		<b-container fluid class="mb-2">
			<b-row class="align-items-center">
				<b-col cols="12" sm="12" md="12" lg="12" class="pl-0">
					<Nav :menuItems="menuData"
				/></b-col>
			</b-row>
		</b-container>
		<router-view />
	</div>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue'

import Nav from '../../components/Nav.vue'
import { APP_ROUTES } from '../../helpers/routeHelpers'

export default {
	name: 'Moderations',
	components: {
		BContainer,
		BRow,
		BCol,
		Nav,
	},

	mounted() {
		console.log(this.$router)
	},

	data() {
		return {
			menuData: [
				{
					menuName: 'User Stories',
					menuRoute: APP_ROUTES.MODERATION_USER_STORIES,
				},
				{
					menuName: 'Marketplace',
					menuRoute: APP_ROUTES.MODERATION_MARKETPLACE,
				},
				{
					menuName: 'Group Posts',
					menuRoute: APP_ROUTES.MODERATION_GROUP_POSTS,
				},
				{
					menuName: 'Shops',
					menuRoute: APP_ROUTES.MODERATION_STORES,
				},
				{
					menuName: 'Tournaments',
					menuRoute: APP_ROUTES.MODERATION_TOURNAMENT,
				},
				{
					menuName: 'Chats',
					menuRoute: APP_ROUTES.MODERATION_CHATS,
				},
			],
		}
	},
}
</script>


