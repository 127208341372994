import { MediaServerTypes } from '@/constants'
import moment from 'moment'

export const objectContainsKey = (object, key) => {
    return typeof object === 'object' && object && object[key] !== undefined
}

export const getMediaPath = (media = null) => {
    if (media && media.BasePath && media.Path) {
        if (media?.Type && media.Type == MediaServerTypes.Video) {
            return `${media.BasePath}${media.ThumbPath}`
        } else {
            return `${media.BasePath}${media.Path || media.ThumbPath}`
        }
    }
    return ''
}

export const getStatusOptions = (statusCode) => {
    return Object.keys(statusCode).map((item) => {
        return {
            label: statusCode.getDisplayTextKey(statusCode[item]),
            value: statusCode[item],
        }
    })
}

export const validateValue = (value = null) => {
    if (value) {
        return value
    } else {
        return '-'
    }
}

export const ifDocument = (fileUrl) => {
    let fileExtension = fileUrl.split('.').pop()
    switch (fileExtension) {
        case 'pdf':
            return true
        case 'docx':
            return true
        case 'doc':
            return true
        default:
            return false
    }
}

export const getMonthDates = (month) => {
    return {
        StartDate: moment(month, 'MMMM').startOf('month').format('YYYY-MM'),
        EndDate: moment(month, 'MMMM')
            .add('month', 1)
            .startOf('month')
            .format('YYYY-MM'),
    }
}

export function showModal(id, context, cb = () => {}) {
    if (context) {
        context.root.$bvModal.show(id)
    } else {
        this.$bvModal.show(id)
    }
    cb()
}

export function hideModal(id, context, cb = () => {}) {
    if (context) {
        context.root.$bvModal.hide(id)
    } else {
        this.$bvModal.hide(id)
    }
    cb()
}

export const emptyFields = (keys = []) => {
    if (keys) {
        keys.forEach((item) => (item.value = null))
    }
}

export const truncateString = (content, limit = 30) => {
    return content.slice(0, limit)
}

export const getFileSizeInMB = (size) => {
    return size / 1024 / 1024
}

export const getRandomBetweenTwoNum = (min, max) => {
    if (!min || !max) return
    let minNum = Math.ceil(min)
    let maxNum = Math.floor(max)
    return Math.floor(Math.random() * (maxNum - minNum) + minNum)
}

export const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
}

export const swapObjectKeyValue = (obj = {}) => {
    if (!obj) return

    let objData = { ...obj }
    let swapedObj = {}

    for (const key in objData) {
        swapedObj[objData[key]] = key
    }

    return swapedObj
}

export const trimLargeText = (text, limit = 30) => {
    if (text.length > limit) {
        return text.slice(0, limit) + '...'
    }
    return text
}

export const convertHtmlToPlainText = (html = '') => {
    if (!html) return false
    return html.replace(/<[^>]+>/g, '')
}

export const convertStringLinks = (data = '') => {
    const linksRegex = /(http(s)?:\/\/[^\s]+)/g

    const matches = data?.match(linksRegex)

    const links = matches?.map((url) => ({
        url,
        text: url, // Set the text of the link to be the URL by default
    }))

    const formattedData = data.replace(linksRegex, '{LINK}')

    const parts = formattedData.split('{LINK}')

    const combined = []

    parts.forEach((part) => {
        if (part) {
            // If the part is text, add it to the combined array
            combined.push({ type: 'text', value: part })
        }
        if (links?.length) {
            // If there are links left, add the next one to the combined array
            combined.push(links.shift())
        }
    })
    return null
    //    <>
    //       {/* Loop through the combined array and render links as clickable hyperlinks */}
    //       {combined.map((item, index) => {
    //          if (item.type === "text") {
    //             // If the item is text, render it using regular HTML or JSX
    //             return <div key={index}>{item.value}</div>
    //          } else {
    //             // If the item is a link, render it as a clickable hyperlink using the Link component
    //             return (
    //                <div key={index}>
    //                   <Link href={item.url}>
    //                      <a>{item.text}</a>
    //                   </Link>
    //                </div>
    //             )
    //          }
    //       })}
    //    </>
}
