<template>
	<div class="table-container">
		<b-card body-class="card-body-style">
			<search-header
				:searchText="searchText"
				:onSearchChange="handleSearchChange"
				:statusOptions="getStatusOptions(userStatusCode)"
				:filterValue="filters.Status"
				:onFilterChange="handleFilterChange"
				:pageLimitValue="pageLimit"
				:onPageLimitChange="handlePageLimit"
				filterClasses="filter-styles"
				searchInputClasses="search-styles"
			>
				<template #right-header-content>
					<Button
						buttonText="+ Add New Moderator"
						buttonVariant="outline-primary"
						size="md"
						classes="ml-2 button-styles"
						:clickHandler="handleAddModeratorClick"
					/>
				</template>
			</search-header>
			<data-table
				:perPageLimit="pageLimit"
				entriesName="Users"
				:perPage="data.length"
				:isLoading="fetching"
				:showPagination="true"
				:config="config"
				:data="data"
				:selectedPageValue="page"
				:onHandlePageChange="handlePageClick"
				:onSortClick="handleSortChange"
				:totalRecords="totalUsers"
			>
				<!-- Column: Id -->
				<template #cell(Id)="data">
					<span class="font-weight-bolder"> #{{ data.value }} </span>
				</template>
				<!-- Column: Name -->
				<template #cell(FullName)="data">
					<span class="text-nowrap">
						<span class="font-weight-bolder">
							{{ data.value }}
						</span>
					</span>
				</template>

				<!-- Column: Added Date -->
				<template #cell(CreatedAt)="data">
					<span class="text-nowrap font-weight-bold">
						{{
							convertDateTime({
								date: data.value,
								customFormat: dateTimeFormat.appDateFormat,
								dateOnly: true,
							})
						}}
					</span>
				</template>

				<!-- Column: Status -->
				<template #cell(Status)="data">
					<template>
						<b-badge
							pill
							:variant="userStatusCode.getLabelClass(data.value)"
						>
							{{ userStatusCode.getDisplayTextKey(data.value) }}
						</b-badge>
					</template>
				</template>

				<!-- Column: Actions -->
				<template #cell(Actions)="data">
					<div class="text-nowrap">
						<feather-icon
							v-b-modal="`action-${data.item.Id}-modal`"
							v-if="
								[
									userStatusCode.VERIFIED,
									userStatusCode.SUSPENDED,
								].includes(data.item.Status)
							"
							:id="`user-list-row-${data.item.Id}-action-icon`"
							:icon="`${
								data.item.Status == userStatusCode.VERIFIED
									? 'SlashIcon'
									: 'CheckCircleIcon'
							}`"
							:class="`cursor-pointer ${
								data.item.Status == userStatusCode.VERIFIED
									? 'inactive-icon'
									: 'active-icon'
							}`"
							size="16"
						/>
						<b-tooltip
							:title="`${
								data.item.Status == userStatusCode.VERIFIED
									? 'Suspend'
									: 'Active'
							}`"
							class="cursor-pointer"
							:target="`user-list-row-${data.item.Id}-action-icon`"
						/>
						<img
							v-b-modal="'delete-moderator-modal'"
							@click="handleDeleteClick(data.item.Id)"
							:id="`moderator-row-${data.item.Id}-action-icon`"
							class="delete-icon mx-1"
							:src="deleteIcon"
						/>

						<b-tooltip
							title="Delete"
							class="cursor-pointer"
							:target="`moderator-row-${data.item.Id}-action-icon`"
						/>
					</div>
					<ConfirmModal
						:isLoading="isLoading"
						:isSuccess="isUserStatusChangedSuccess"
						:onOkayPress="handleOkayPress"
						successText="Success !"
						:onYesPress="
							() => {
								updateUserStatus(data.item.Id, data.item.Status)
							}
						"
						:id="`action-${data.item.Id}-modal`"
						:messageText="
							data.item.Status == userStatusCode.SUSPENDED
								? 'Are you sure you want to activate this user?'
								: 'Are you sure you want to suspend this user?'
						"
						:messageIcon="
							require('@/assets/images/icons/restrict-icon.svg')
						"
					/>
				</template>
			</data-table>
		</b-card>
		<AddModerator
			:onValidSubmit="handleAddModeratorSubmit"
			:isLoading="addingModerator"
		/>
		<ConfirmModal
			id="delete-moderator-modal"
			:isLoading="isProcessing"
			:isSuccess="deleteSuccess"
			successText="Moderator has been deleted successfully !"
			:onOkayPress="handleOkayPress"
			:onYesPress="handleYesPress"
			messageText="Are you sure you want to delete this moderator?"
		/>
	</div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from 'bootstrap-vue'
import { computed, reactive, ref } from '@vue/composition-api'

import ConfirmModal from '@/components/ConfirmModal.vue'
import DataTable from '@/components/data-table/DataTable.vue'
import UserInfo from '@/components/UserInfo.vue'
import SearchHeader from '@/components/SearchHeader.vue'
import Button from '@/components/Button.vue'
import AddModerator from './AddModerator.vue'
import CrownIcon from '@/assets/images/icons/crown-icon.svg'
import { dateTimeFormat, convertDateTime } from '@/utils/dateFormatUtils'
import { getMediaPath, getStatusOptions } from '@/utils/commonUtils'
import { useStore } from '@/store'
import { usePaginatedRequest } from '@/hooks/usePaginatedRequest'
import { UserStatusCode, SortOrder } from '../../../constants'
import { addModerator, deleteModerator } from '@/api/api.service'
import showToast from '@/hooks/useToast'
import DeleteIcon from '@/assets/images/icons/delete.svg'

export default {
	name: 'ModeratorListing',
	components: {
		DataTable,
		UserInfo,
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		SearchHeader,
		ConfirmModal,
		Button,
		AddModerator,
	},
	setup(props, context) {
		const store = useStore()
		let crownIcon = CrownIcon
		let addingModerator = ref(false)
		const filters = reactive({
			Status: null,
		})

		const isLoading = ref(false)
		const isUserStatusChangedSuccess = ref(false)
		const selectedId = ref(null)
		const isProcessing = ref(false)
		const deleteSuccess = ref(false)

		let config = [
			{
				label: 'ID',
				key: 'Id',
			},
			{
				label: 'NAME',
				key: 'FullName',
			},
			{
				label: 'EMAIL ADDRESS',
				key: 'Email',
			},
			{
				label: 'DATE ADDED',
				key: 'CreatedAt',
				isSortable: true,
			},

			{
				label: 'STATUS',
				key: 'Status',
				isSortable: true,
			},
			{
				label: 'ACTIONS',
				key: 'Actions',
			},
		]
		let fetching = computed(() => store.state.user.fetching)
		let data = computed(() => store.state.user.list)
		let totalUsers = computed(() => store.state.user.totalUsers)

		const {
			page,
			searchText,
			pageLimit,
			sortOrder,
			sortColumn,
			request,
			handleSearchChange,
			handlePageLimit,
			handlePageClick,
			handleSortChange,
		} = usePaginatedRequest(
			'user/getUserListAction',
			() => ({
				Direction: SortOrder.DESC,
				Column: 'CreatedAt',
				OnlyModerators: true,
				...(filters.Status && { Status: filters.Status.value }),
			}),
			[filters]
		)

		function handleFilterChange(val) {
			handlePageClick(1)
			filters.Status = val
		}

		function updateUserStatus(id, status) {
			isLoading.value = true
			let updatedStatus =
				status === UserStatusCode.VERIFIED
					? UserStatusCode.SUSPENDED
					: UserStatusCode.VERIFIED
			store
				.dispatch('user/updateUserStatus', {
					id: id,
					status: updatedStatus,
				})
				.then((res) => {
					isUserStatusChangedSuccess.value = true
				})
				.finally(() => {
					isLoading.value = false
				})
		}

		function handleOkayPress() {
			isUserStatusChangedSuccess.value = false
			selectedId.value = null
			deleteSuccess.value = false
		}

		function handleAddModeratorClick() {
			this.$bvModal.show('add-moderator-modal')
		}

		async function handleAddModeratorSubmit(data) {
			let payload = { ...data }

			try {
				addingModerator.value = true
				let response = await addModerator(payload)
				this.$bvModal.hide('add-moderator-modal')
				showToast(this, {
					Title: `Moderator has been added!`,
					Icon: 'CheckCircleIcon',
					Position: 'top-right',
					Text: response.Message,
				})
				request()
			} catch (err) {
				showToast(this, {
					Title: 'Error',
					Icon: 'XCircleIcon',
					Text: err.Message,
					Variant: 'danger',
					Position: 'top-right',
				})
			} finally {
				addingModerator.value = false
			}
		}

		const handleDeleteClick = (id) => {
			selectedId.value = id
		}

		const handleYesPress = () => {
			isProcessing.value = true
			let id = Number(selectedId.value)

			let payload = {
				UserId: id,
			}

			deleteModerator(payload)
				.then(() => {
					request()
					deleteSuccess.value = true
				})
				.catch(() => {
					deleteSuccess.value = false
					isProcessing.value = false
					context.root.$bvModal.hide('delete-moderator-modal')
				})

				.finally(() => {
					isProcessing.value = false
				})
		}

		return {
			deleteIcon: DeleteIcon,
			config,
			dateTimeFormat,
			userStatusCode: UserStatusCode,
			convertDateTime,
			data,
			fetching,
			totalUsers,
			searchText,
			page,
			pageLimit,
			crownIcon,
			filters,
			getMediaPath,
			getStatusOptions,
			handleSearchChange,
			handlePageClick,
			handleFilterChange,
			handlePageLimit,
			updateUserStatus,
			handleOkayPress,
			isUserStatusChangedSuccess,
			isLoading,
			handleSortChange,
			sortOrder,
			sortColumn,
			handleAddModeratorClick,
			handleAddModeratorSubmit,
			addingModerator,
			handleDeleteClick,
			isProcessing,
			deleteSuccess,
			handleYesPress,
		}
	},
}
</script>

<style scoped>
.delete-icon {
	width: 14px;
	height: 14px;
	cursor: pointer;
}
::v-deep .active-icon {
	color: #28c76f;
}

::v-deep .inactive-icon {
	color: #d88416;
}

::v-deep .detail-icon {
	color: #888888;
}
.subscription-icon {
	width: 14px;
	height: 14px;
	margin-right: 10px;
}
.card-body-style {
	padding: 0 !important;
}
.table-container {
	margin-top: -10px;
}
::v-deep .filter-styles {
	width: 200px;
}
::v-deep .search-styles {
	width: 150px !important;
}
::v-deep .button-styles {
	width: 200px;
}
</style>
