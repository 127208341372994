import { injectBaseConstantMethods } from "./BaseConstants";

export const StatusCodes = {
    VERIFIED: 1,
    SUSPENDED: 2,
};

const displayTextKeys = {
    [StatusCodes.VERIFIED]: "Active",
    [StatusCodes.SUSPENDED]: "Suspended",
};

const labelClass = {
    [StatusCodes.VERIFIED]: "light-success",
    [StatusCodes.SUSPENDED]: "light-danger",
};

export default injectBaseConstantMethods(
    StatusCodes,
    displayTextKeys,
    labelClass
);
