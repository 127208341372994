<template>
	<div class="blog-detail-wrapper">
		<div>
			<b-card no-body>
				<b-row>
					<b-col cols="12">
						<template v-if="isFetching">
							<div class="text-center text-primary my-2">
								<b-spinner class="align-middle"></b-spinner>
							</div>
						</template>

						<b-card class="container-card" v-if="!isFetching">
							<div class="p-2">
								<b-row col="12">
									<b-col lg="12" md="12" sm="12">
										<Title text="Customer Details" />
									</b-col>
								</b-row>

								<b-row sm="auto" lg="5" md="5">
									<b-col
										sm="auto"
										lg="5"
										md="5"
										class="d-flex flex-column justify-content-between"
									>
										<div
											class="mb-1"
											style="
												display: flex;
												align-items: center;
											"
										>
											<p
												class="mb-0"
												style="color: #888888"
											>
												Customer:
											</p>
											<div
												class="d-flex ml-1"
												style="align-items: center"
											>
												<b-avatar
													style="
														color: white;
														margin-left: 5px;
													"
													size="45"
													:src="
														orderData.User
															.ProfilePicture
													"
												>
													<feather-icon
														size="21"
														:icon="icon"
													/>
												</b-avatar>
												<p
													class="mb-0 ml-1 font-weight-bolder"
													style="color: #000"
												>
													{{
														orderData.User.FullName
													}}
												</p>
											</div>
										</div>
										<LabelText
											label="Phone"
											showRightSection
											textClasses="font-weight-bolder mb-0"
											:text="
												orderData.User.CountryCode +
												orderData.User.PhoneNumber
											"
										/>
										<LabelText
											label="Order Date"
											showRightSection
											textClasses="font-weight-bolder mb-0"
											:text="
												convertDateTime({
													date: orderData.OrderTime,
													customFormat:
														dateTimeFormat.dateWithYearMonthFormat,
													dateOnly: true,
												})
											"
										/>
									</b-col>
									<b-col
										sm="auto"
										lg="5"
										md="5"
										class="d-flex flex-column justify-content-between"
									>
										<div class="mb-1" style="display: flex">
											<p
												class="mb-0"
												style="color: #888888"
											>
												Address:
											</p>
											<div
												class="d-flex ml-1"
												style="align-items: center"
											>
												<p
													class="mb-0 ml-0 font-weight-bolder"
													style="color: #000"
												>
													{{
														orderData.DeliveryLocation
													}}
												</p>
											</div>
										</div>
										<div class="mb-0" style="display: flex">
											<p
												class="mb-0"
												style="color: #888888"
											>
												Price:
											</p>
											<div
												class="d-flex ml-1"
												style="align-items: center"
											>
												<p
													class="mb-0 ml-0"
													style="color: #000"
												>
													<span
														class="font-weight-bolder"
														>{{ orderData.Currency
														}}{{
															orderData.TotalAmount
														}}</span
													>
													+
													<span
														class="font-weight-bolder"
														>{{ orderData.Currency
														}}{{
															orderData.PaymentProcessingFee
														}}</span
													>
													(Stripe Fee) +
													<span
														class="font-weight-bolder"
														>{{ orderData.Currency
														}}{{
															calculateTotalSalesTax()
														}}</span
													>
													(Sales Tax) +
													<span
														class="font-weight-bolder"
														>{{ orderData.Currency
														}}{{
															getShippingFee()
														}}</span
													>
													(Shipping Fee + Transaction
													Fee)
												</p>
											</div>
										</div>
										<LabelText
											label="Total Cost"
											showRightSection
											textClasses="font-weight-bolder mb-0"
											:text="getTotalCost()"
										/>
									</b-col>
									<b-col
										sm="auto"
										lg="2"
										md="2"
										class="d-flex flex-column justify-content-start"
									>
										<div class="order-status-badge">
											{{
												Object.keys(orderStatus).find(
													(key) =>
														orderStatus[key] ===
														orderData.Status
												)
											}}
										</div>
									</b-col>
								</b-row>

								<hr />

								<b-row col="12">
									<b-col lg="12" md="12" sm="12">
										<Title text="Order Details" />
									</b-col>
								</b-row>

								<b-row col="12" gutter="2">
									<b-col sm="auto" lg="6" md="auto">
										<LabelText
											label="Total Items"
											showRightSection
											textClasses="font-weight-bolder mb-0"
											:text="`${orderData.OrderItemsCount} Items`"
										/>
									</b-col>
								</b-row>

								<b-row
									col="12"
									gutter="2"
									v-for="(item, index) in orderData.Packages"
									:key="index"
								>
									<b-col sm="auto" lg="12" md="auto">
										<h3
											style="
												font-size: 16px;
												color: #3599cd;
											"
											class="font-weight-bolder"
										>
											{{ item.Vendor.CompanyName }}
										</h3>
										<h3
											style="font-size: 12px; color: #000"
											class="font-weight-bolder"
										>
											Package
											{{
												orderData.Packages.indexOf(
													item
												) + 1
											}}
										</h3>

										<b-row
											class="mt-2"
											v-for="(
												packageItem, index
											) in item.PackageItems"
											:key="index"
										>
											<b-col sm="auto" lg="4" md="auto">
												<div
													class="mb-2"
													style="display: flex"
												>
													<img
														:src="
															getMediaPath(
																packageItem
																	.Product
																	.DefaultMedia
															)
														"
														alt=""
														style="
															width: 100px;
															height: 100px;
															object-fit: cover;
															border-radius: 5px;
														"
													/>
													<div
														class="d-flex ml-1"
														style="
															flex-direction: column;
														"
													>
														<p
															class="mb-0 ml-1 font-weight-bolder"
															style="
																color: #000;
																font-size: 16px;
															"
														>
															{{
																packageItem
																	.Product
																	.Name
															}}
														</p>
														<p
															class="mb-0 ml-1 font-weight-bolder"
															style="
																color: #888888;
																font-size: 14px;
															"
														>
															{{
																orderData.Currency
															}}{{
																packageItem.Product.Price.toFixed(
																	2
																)
															}}
															x
															{{
																packageItem.Quantity
															}}
														</p>
														<p
															class="mb-0 ml-1 mt-1 font-weight-bolder"
															style="
																color: #3599cd;
																font-size: 16px;
															"
														>
															{{
																orderData.Currency
															}}{{
																(
																	packageItem
																		.Product
																		.Price *
																	packageItem.Quantity
																).toFixed(2)
															}}
														</p>
													</div>
												</div>
											</b-col>
										</b-row>
										<b-row>
											<b-col sm="auto" lg="3" md="auto">
												<LabelText
													label="Products Cost"
													showRightSection
													textClasses="font-weight-bolder mb-0"
													:text="`${
														orderData.Currency
													}${item.TotalAmount.toFixed(
														2
													)}`"
												/>
											</b-col>
											<b-col sm="auto" lg="3" md="auto">
												<LabelText
													label="Sales Tax"
													showRightSection
													textClasses="font-weight-bolder mb-0"
													:text="`${
														orderData.Currency
													}${item.TotalSalesTax.toFixed(
														2
													)}`"
												/>
											</b-col>
											<b-col sm="auto" lg="3" md="auto">
												<LabelText
													label="Shipping Fee"
													showRightSection
													textClasses="font-weight-bolder mb-0"
													:text="`${
														orderData.Currency
													}${item.DeliveryFee.toFixed(
														2
													)}`"
												/>
											</b-col>
										</b-row>
									</b-col>
									<b-col sm="auto" lg="4" md="auto"> </b-col>
								</b-row>
							</div>
						</b-card>
					</b-col>
				</b-row>
			</b-card>
		</div>
	</div>
</template>

<script>
import {
	BFormInput,
	BMedia,
	BAvatar,
	BMediaAside,
	BMediaBody,
	BImg,
	BLink,
	BFormGroup,
	BInputGroup,
	BInputGroupAppend,
	BCard,
	BCardText,
	BRow,
	BCol,
	BBadge,
	BDropdown,
	BDropdownItem,
	BForm,
	BFormTextarea,
	BFormCheckbox,
	BButton,
	BCardImg,
	BSpinner,
	BCarousel,
	BCarouselSlide,
} from 'bootstrap-vue'
import moment from 'moment'
import { textUrlToHtmlLink } from 'text-url-to-html-link'
import FileCard from '@/components/FileCard.vue'
import DeleteIcon from '../../assets/images/icons/delete.svg'
import {
	deleteProduct,
	getOrderDetails,
	getProducts,
	getCategories,
	updateStoreStatus,
} from '../../api/api.service'
import { getMediaPath } from '../../utils/commonUtils'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import Button from '../../components/Button.vue'
import Title from '../../components/Title.vue'
import LabelText from '../../components/LabelText.vue'
import UserInfo from '../../components/UserInfo.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import BasicModal from '@/components/BasicModal.vue'
import { APP_ROUTES } from '@/helpers/routeHelpers'
import ORDER_STATUS from '@/constants/OrderStatus'

export default {
	name: 'StoreOrderHistoryDetails',
	components: {
		BFormInput,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BLink,
		BCard,
		BRow,
		BCol,
		BFormGroup,
		BInputGroup,
		BInputGroupAppend,
		BImg,
		BBadge,
		BCardText,
		BDropdown,
		BForm,
		BDropdownItem,
		BFormTextarea,
		BFormCheckbox,
		BButton,
		BCardImg,
		BSpinner,
		BCarousel,
		BCarouselSlide,
		Button,
		Title,
		LabelText,
		UserInfo,
		ConfirmModal,
		BasicModal,
		FileCard,
	},

	data() {
		return {
			dateTimeFormat,
			isFetching: false,
			isFetchingMembers: false,
			orderData: {},
			isProcessing: false,
			storeProducts: [],
			deleteIcon: DeleteIcon,
			activeTab: 1,
			emptyImage: require('@/assets/images/gallery/no-data.png'),
			storeCategories: [{ Name: 'All' }],
			selectedCategory: { Name: 'All' },
			orderStatus: ORDER_STATUS,
		}
	},
	methods: {
		moment,
		replaceByDefault(event) {
			event.target.src = this.emptyImage
		},
		handleConvertTextLinksToTag(data = '') {
			if (!data) {
				return 'No description to show!'
			}
			let text = textUrlToHtmlLink(data)
			return text
		},
		async getOrderDetails(id) {
			try {
				this.isFetching = true
				let res = await getOrderDetails(id)
				this.orderData = { ...res }
			} catch (err) {
			} finally {
				this.isFetching = false
			}
		},

		async getStoreProducts(id, category) {
			try {
				this.isFetching = true
				let params = { VendorId: id }
				if (category) {
					params.GearCategoryId = category
				}
				let res = await getProducts(params)
				this.storeProducts = [...res.Products]
			} catch (err) {
			} finally {
				this.isFetching = false
			}
		},
		async getStoreCategories(id) {
			try {
				this.isFetching = true
				let res = await getCategories({ VendorId: id })
				this.storeCategories = [{ Name: 'All' }, ...res.GearCategories]
			} catch (err) {
			} finally {
				this.isFetching = true
			}
		},
		handleVerifyStore(status) {
			this.isProcessing = true
			updateStoreStatus(this.$route.params.storeId, {
				VerifyStatus: status,
			})
				.then((res) => {
					this.orderData.Status = status
				})
				.finally(() => {
					this.isProcessing = false
				})
		},
		handleSuspendStore(status) {
			this.isProcessing = true
			updateStoreStatus(this.$route.params.storeId, { Suspended: status })
				.then((res) => {
					this.orderData.Status = status
				})
				.finally(() => {
					this.isProcessing = false
				})
		},
		getMediaPath,
		convertDateTime,
		getShippingFee() {
			let fee = this.orderData.Packages.reduce(function (a, b) {
				return a + b.DeliveryFee
			}, 0)
			fee += this.orderData.TransactionFee
			fee += this.orderData.PaymentProcessingFee
			return fee.toFixed(2)
		},

		async getStoreProducts(id, category) {
			try {
				this.isFetching = true
				let params = { VendorId: id }
				if (category) {
					params.GearCategoryId = category
				}
				let res = await getProducts(params)
				this.storeProducts = [...res.Products]
			} catch (err) {
			} finally {
				this.isFetching = false
			}
		},
		async getStoreCategories(id) {
			try {
				this.isFetching = true
				let res = await getCategories({ VendorId: id })
				this.storeCategories = [{ Name: 'All' }, ...res.GearCategories]
			} catch (err) {
			} finally {
				this.isFetching = true
			}
		},
		handleVerifyStore(status) {
			this.isProcessing = true
			updateStoreStatus(this.$route.params.storeId, {
				VerifyStatus: status,
			})
				.then((res) => {
					this.orderData.Status = status
				})
				.finally(() => {
					this.isProcessing = false
				})
		},
		handleSuspendStore(status) {
			this.isProcessing = true
			updateStoreStatus(this.$route.params.storeId, { Suspended: status })
				.then((res) => {
					this.orderData.Status = status
				})
				.finally(() => {
					this.isProcessing = false
				})
		},
		getMediaPath,
		convertDateTime,

		getShippingFee() {
			let finalAmount = 0
			let fee = this.orderData.Packages.reduce(function (a, b) {
				return a + b.DeliveryFee
			}, 0)

			finalAmount = fee + this.orderData.TransactionFee
			//  +this.orderData.PaymentProcessingFee
			finalAmount.toFixed(2)

			return finalAmount
		},
		calculateTotalSalesTax() {
			let tax = this.orderData.Packages.reduce(function (a, b) {
				return a + b.TotalSalesTax
			}, 0)
			return tax.toFixed(2)
		},
		getTotalCost() {
			let currencySymbol = this.orderData.Currency
			let totalAmount = Number(this.orderData.TotalAmount)
			let stripeFee = Number(this.orderData.PaymentProcessingFee)
			// let transactionFee = Number(this.orderData.TransactionFee)
			let salesTax = Number(this.calculateTotalSalesTax())
			let shippingFee = Number(this.getShippingFee())
			let resultantAmount =
				currencySymbol +
				(totalAmount + stripeFee + salesTax + shippingFee).toFixed(2)

			return resultantAmount
		},
	},

	created() {
		this.getOrderDetails(this.$route.params.orderId)
	},
}
</script>

<style scoped>
.card-title {
	color: #003071;
	font-size: 18px;
	font-weight: bold !important;
}

.detail-img {
	margin-bottom: 50px;
}

.card-sub-title {
	color: #888888;
	font-size: 14px;
	margin-bottom: 10px;
}

.card-hash {
	color: #888888;
	font-size: 14px;
}

.card-bottom-text {
	font-size: 14px;
	color: black;
}

.edit-button {
	position: absolute;
	top: 50px;
	right: 45px;
	z-index: 1;
}

.img-slide-item {
	height: 394px;
}

.store-cover-image-container {
	height: 170px !important;
	width: 100% !important;
}

.cover-image {
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.card-body {
	padding: 10px !important;
}

.store-status {
	color: black;
	padding-top: 0.2rem;
}

.product-image-container {
	height: 134px;
}

.product-image {
	width: 100%;
	height: 100%;
	border-radius: 6px;
	object-fit: cover;
	object-position: center;
}

.product-details-container {
	padding: 5px;
}

.product-title {
	color: #000;
	font-size: 14px;
	font-weight: bold !important;
}

.product-price {
	color: #3599cd;
	font-size: 14px;
	font-weight: bold !important;
}

.product-card {
	background-color: #f7f7f7;
}

.delete-button {
	padding: 0 !important;
	color: #ff6060;
}

.tab-options-container {
	padding: 0px 0 20px;
}

.tab-button:nth-child(2) {
	margin-left: 20px;
}

.scrollable-container {
	padding: 16px 16px 1px;
	border-bottom: 1px solid #e5e5e5;
	overflow-x: scroll;
}

.scrollable-container::-webkit-scrollbar {
	width: 2px;
	height: 6px;
}

.scrollable-container::-webkit-scrollbar-thumb {
	background-color: rgb(190, 190, 190);
	border-radius: 5px;
}

.action-verify-btn {
	min-width: 210px;
	background-color: #2fbe3d !important;
	border-color: #2fbe3d !important;
}

.action-suspend-btn {
	min-width: 210px;
	border: 1px solid #6c757d !important;
	color: #82868b;
}

.order-status-badge {
	background-color: rgb(229, 248, 237);
	padding: 8px;
	text-align: center;
	font-weight: bolder;
	border-radius: 7px;
}

.fixed-height {
	margin-bottom: 0 !important;
}
</style>
